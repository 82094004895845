import React, { useEffect, useState, useRef } from 'react'
import Loading from '../../../../components/alertLoading'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'
import form from '../../../../styles/Formulario.module.css'
import { Form } from '@unform/web'
import api from '../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { FiEye } from 'react-icons/fi'
import { MdArrowBack, MdOutlineCreate, MdDelete } from 'react-icons/md'
import List from '../../../../components/List'
import Select from '../../../../components/form/Select'
import Swal from 'sweetalert2'
import { showErrors } from '../../../../services/functions'
import PreviewAudit from '../../../../components/preview-audit'
import { getSectorName, sortIdentifiers } from '../../../../services/auditoria'
import saveAudit from '../../../../services/auditoria/saveAudit'
import PainelEditAudit from '../../../../components/painel-edit-audit'
import PainelComment from '../../../../components/painelComment'

function viewAudit() {
  const formRef = useRef(null)
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState('')
  const [stores, setStores] = useState([])
  const [store, setStore] = useState('')
  const [sectors, setSectors] = useState([])
  const [sector, setSector] = useState('')
  const atualDate = new Date()
  const atualyear = atualDate.getFullYear()
  const [audits, setAudits] = useState([])
  const [questionsanswered, setQuestionsAnswered] = useState([])
  const [isViewAudit, setIsViewAudit] = useState(false)
  const [currentAudit, setCurrentAudit] = useState()
  const [consolidated, setConsolidated] = useState(false)
  const [finished, setFinished] = useState(true)
  const [semester, setSemester] = useState('1')

  const [editing, setEditing] = useState(false)
  const [currentEditingQuestion, setCurrentEditingQuestion] = useState()

  const [comment, setComment] = useState(false)
  const handleOpen = () => setComment(true)
  const handleClose = () => setComment(false)
  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      setLoading(false)
    }
  }

  async function consolidateAudit(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/consolidate/${auditId}`)
      setLoading(false)
      Swal.fire('Auditoria Consolidada!', '', 'success').then(() => {
        setIsViewAudit(false)
        init()
        setAudits([])
      })
    } catch (error) {
      setLoading(false)
    }
  }

  async function anableAudit(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/${auditId}`)
      setLoading(false)
      Swal.fire(
        'A auditoria foi habilitada para a edição!',
        '',
        'success'
      ).then(() => {
        setIsViewAudit(false)
        init()
        setAudits([])
      })
    } catch (error) {
      setLoading(false)
    }
  }
  const [comments, setComments] = useState([])
  const configureName = async ({
    name,
    representatives,
    chief_auditor,
    target
  }) => {
    try {
      let params = {}
      if (name) {
        params = { ...params, name: name }
      }
      if (representatives) {
        params = { ...params, representatives }
      }
      if (chief_auditor) {
        params = { ...params, chief_auditor }
      }

      setLoading(true)
      const response = await api.put(`/v1/audits/${currentAudit.id}`, params)
      if (response.status === 200) {
        const resp = await api.get(`/v1/audits/${currentAudit.id}`)
        setLoading(false)
        if (resp.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `${target} da auditoria alterado com sucesso!!!`,
            showConfirmButton: false,
            timer: 1500
          })
          setCurrentAudit(resp.data)
        }
      }
    } catch (err) {
      setLoading(false)
    }
  }
  const parentTest = () => {
    setEditing(!editing)
    getAuditResult(currentAudit.id, currentAudit.form.id)
  }

  const handleComments = async newComment => {
    try {
      setLoading(true)
      await api.post(`/v1/audits/comment/${currentAudit.id}`, newComment)
      setLoading(false)
      setComments(await getComments())
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  function ModalCad() {
    return (
      <>
        <div className={form.modal}>
          <div
            className={form.bg_modal}
            onClick={() => {
              // setIsViewAudit(false)
            }}
          ></div>
          <PainelComment
            comments={comments}
            addComment={true}
            removeComment={true}
            open={comment}
            close={handleClose}
            handleComment={event => handleComments(event)}
            onSetComment={async () => setComments(await getComments())}
          />
          <div
            style={{ marginRight: comment ? '30%' : 'auto' }}
            className={form.containerCadAuditoria}
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{ marginBottom: '0' }} className={form.titleForm}>
                    Dados da Auditoria:
                  </h1>
                  <h2 style={{ marginLeft: '0.75rem', height: 'max-content' }}>
                    {currentAudit.name === null ? '' : currentAudit.name}
                  </h2>
                </div>

                <button
                  style={{ width: 'max-content', height: 'max-content' }}
                  className={form.btn}
                  onClick={() => {
                    Swal.fire({
                      title: 'Editar nome da auditoria',
                      input: 'text',
                      inputPlaceholder: 'Nome da Auditoria',
                      inputAttributes: {
                        autocapitalize: 'off'
                      },
                      showCancelButton: true,
                      confirmButtonText: 'Confirmar',
                      showLoaderOnConfirm: true,
                      preConfirm: name => {
                        if (name.length > 15) {
                          Swal.fire({
                            icon: 'error',
                            title: 'Limite de caracteres ultrapassado!!!',
                            showConfirmButton: false,
                            timer: 1500
                          })
                        } else {
                          configureName({ name: name, target: 'Nome' })
                        }
                      }
                    })
                  }}
                >
                  Editar Nome
                </button>
              </div>
              <div className={styles.flex_row_bet}>
                <div className={(styles.flex_column_bet, form.input_w100)}>
                  {!editing && (
                    <div className={form.btn_cadastro_view_audit}>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={() => {
                          Swal.fire({
                            title: 'Editar Auditor Líder',
                            input: 'text',
                            inputPlaceholder: 'Nome do Auditor Líder',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirmar',
                            showLoaderOnConfirm: true,
                            preConfirm: text => {
                              if (text.length > 255) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Limite de caracteres ultrapassado!!!',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              } else {
                                configureName({
                                  chief_auditor: text,
                                  target: 'Auditor Líder'
                                })
                              }
                            }
                          })
                        }}
                      >
                        Editar Auditor Lider
                      </button>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={() => {
                          Swal.fire({
                            title: 'Editar Representante',
                            input: 'text',
                            inputPlaceholder: 'Representante da auditoria',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirmar',
                            showLoaderOnConfirm: true,
                            preConfirm: text => {
                              if (text.length > 255) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Limite de caracteres ultrapassado!!!',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              } else {
                                configureName({
                                  representatives: text,
                                  target: 'Representante'
                                })
                              }
                            }
                          })
                        }}
                      >
                        Editar Representante
                      </button>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={handleOpen}
                      >
                        Abrir comentários
                      </button>
                    </div>
                  )}

                  {abstract.map((item, index) => {
                    return (
                      <div key={index}>
                        {!editing && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <PreviewAudit
                              identifier={item.identifier}
                              number={item.number}
                              note={item.answer}
                              excerpt={item.enunciation}
                              weight={questions[index]?.value}
                              comment={item.note || ''}
                            />
                            <p
                              style={editStyle}
                              onClick={() => {
                                setEditing(true)
                                setCurrentEditingQuestion(item)
                              }}
                            >
                              <MdOutlineCreate
                                style={{
                                  fill: '#ba0000',
                                  width: 28,
                                  height: 28
                                }}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  })}
                  {editing && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PainelEditAudit
                        questionId={currentEditingQuestion.id}
                        auditId={currentAudit.id}
                        parentCallback={parentTest}
                        identifier={currentEditingQuestion.identifier}
                        number={currentEditingQuestion.number}
                        note={currentEditingQuestion.answer}
                        excerpt={currentEditingQuestion.enunciation}
                        weight={questions[currentEditingQuestion]?.value}
                        comment={currentEditingQuestion.note || ''}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!editing && (
                <div className={form.btn_cadastro_view_audit}>
                  {!consolidated && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        Swal.fire({
                          title:
                            'Deseja habilitar esta auditoria para a edição?',
                          showDenyButton: false,
                          showCancelButton: true,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Sim',
                          denyButtonText: `Don't save`
                        }).then(result => {
                          if (result.isConfirmed) {
                            anableAudit(currentAudit.id)
                          } else if (result.isDenied) {
                            setIsViewAudit(false)
                          }
                        })
                      }}
                    >
                      Habilitar edição
                    </button>
                  )}

                  {!consolidated && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        Swal.fire({
                          title: 'Deseja consolidar esta auditoria?',
                          showDenyButton: false,
                          showCancelButton: true,
                          html: `<input class="${form.campo_input}" >`,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Sim',
                          denyButtonText: `Don't save`
                        }).then(result => {
                          if (result.isConfirmed) {
                            consolidateAudit(currentAudit.id)
                          } else if (result.isDenied) {
                            setIsViewAudit(false)
                          }
                        })
                      }}
                    >
                      Consolidar
                    </button>
                  )}
                  {!finished && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        saveAudit(
                          currentAudit,
                          getSectorName(
                            formRef.current.getFieldValue('sector'),
                            sectors
                          )
                        )
                      }}
                    >
                      Continuar auditoria
                    </button>
                  )}
                  <button
                    className={form.btn}
                    onClick={() => {
                      setIsViewAudit(false)
                    }}
                  >
                    Voltar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  async function updateStores(b) {
    try {
      setLoading(true)
      const aux = brands.find(e => e.id === b).name
      setBrand(aux)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setLoading(false)
      setStores(response?.data)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors(s) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setSectors(response?.data)
      const aux = stores.find(e => e.id === s).name
      setStore(aux)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  // async function removeAudit(auditId) {
  //   try {
  //     const response = await api.delete(`/v1/audits/${auditId}`)
  //     Swal.fire('Auditoria removida com sucesso!', '', 'success').then(init())
  //   } catch (err) {
  //     toast.error(err?.response)
  //   }
  // }

  async function unconsolidated(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/unconsolidated/${auditId}`)
      setLoading(false)
      Swal.fire('Auditoria Desconsolidada!', '', 'success').then(() => {
        setIsViewAudit(false)
        init()
        setAudits([])
      })
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const getComments = async auditId => {
    try {
      setLoading(true)
      const resp = await api.get(
        `/v1/audits/comment/${auditId || currentAudit.id}`
      )
      setLoading(false)
      return resp.data.reverse()
    } catch (e) {
      setLoading(false)
      console.error(e)
      return []
    }
  }

  function TableCompanies() {
    return audits.map((item, index) => {
      const created_date = new Date(item.created_at)
      const month = created_date.getMonth()
      const day = String(created_date.getDate()).padStart(2, '0')
      const year = created_date.getFullYear()
      const output = `${day} / ${month < 9 ? '0' : ''}${month + 1} / ${year}`

      return (
        <tr key={index}>
          <td className="grow1">{item.code}</td>
          <td className="grow2">{output}</td>
          <td className="grow2">{brand}</td>
          <td className="grow2">{store}</td>
          <td className="grow2">{sector}</td>
          <td className="grow2">{semester}</td>
          <td className="grow2">{year}</td>
          <td className="grow2">
            {item.consolidated_at === null ? 'Não consolidada' : 'Consolidada'}
          </td>
          <td className="grow1 td-opc">
            <div
              className="btn-view"
              onClick={async () => {
                getAuditResult(item.id, item.form.id)
                setIsViewAudit(true)
                setCurrentAudit(item)
                setComments(await getComments(item.id))
              }}
            >
              <FiEye />
              <p>Visualizar</p>
            </div>
            {item.consolidated_at === null ? (
              <div
                className="btn-delete"
                onClick={() => {
                  Swal.fire({
                    title: 'Deseja realmente excluir esta auditoria?',
                    text: 'Todos os dados ligados a esta auditoria serão perdidos e a operação não poderá ser desfeita.',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim, Excluir'
                  }).then(result => {
                    if (result.isConfirmed) {
                      removeAudit(item.id)
                    }
                  })
                }}
              >
                <MdDelete />
                <p>Deletar</p>
              </div>
            ) : (
              <div
                className="btn-delete"
                onClick={() => {
                  Swal.fire({
                    title: 'Deseja desconsolidar esta auditoria?',
                    showDenyButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim',
                    denyButtonText: `Don't save`
                  }).then(result => {
                    if (result.isConfirmed) {
                      unconsolidated(item.id)
                    } else if (result.isDenied) {
                      setIsViewAudit(false)
                    }
                  })
                }}
              >
                <MdArrowBack />
                <p>Desconsolidar</p>
              </div>
            )}
          </td>
        </tr>
      )
    })
  }

  async function removeAudit(auditId) {
    try {
      setLoading(true)
      await api.delete(`/v1/audits/${auditId}`)
      setLoading(false)
      Swal.fire('A auditoria foi excluída com sucesso!!!', '', 'success')
      setAudits([])
      setQuestionsAnswered([])
      init()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  async function updateAudits(sectorid) {
    try {
      const year = parseInt(formRef.current.getFieldValue('year'))
      const semester = formRef.current.getFieldValue('semester')
      const status = formRef.current.getFieldValue('status')
      const situation = formRef.current.getFieldValue('situation')
      setLoading(true)
      const response = await api.get(
        `/v1/audits/${formRef.current.getFieldValue(
          'store'
        )}/${sectorid}?year=${year}&is_finished=${status}&is_consolidated=${situation}&semester=${semester}`
      )
      setLoading(false)
      setAudits(response.data)
    } catch (error) {
      setLoading(false)
      // console.error(error.response)
      if (error?.response) {
        showErrors(error.response.data.detail)
      }

      if (error?.response?.status === 404) {
        setAudits([])
      }
    }
  }

  const [questions, setQuestions] = useState([])

  async function getQuestions(formid) {
    try {
      setLoading(true)
      const resp = await api.get(`/v1/forms/form-question/${formid}`)
      setLoading(false)
      setQuestions(resp.data)
    } catch (error) {
      setLoading(false)
    }
  }

  async function getAuditResult(auditId, formId) {
    try {
      setLoading(true)
      await api.get(`/v1/audits/answer/${auditId}`)
      setLoading(false)
      getQuestions(formId)
      getQuestionsAnswered(auditId)
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.detail) {
        showErrors(error.response.data.detail)
      }
    }
  }

  async function getQuestionsAnswered(auditId) {
    try {
      setLoading(true)
      const resp = await api.get(`/v1/audits/answer/${auditId}`)
      setQuestionsAnswered(resp.data)
    } catch (error) {
      setLoading(false)

      if (error.response !== undefined) {
        if (error.response.status !== 404) {
          showErrors(error.response.data.detail)
        }
      }
    }
  }

  const filterSector = id => {
    const aux = sectors.find(e => e.id === id).name
    setSector(aux)
  }

  const filterSemester = p => {
    console.log(p)
    if (p === '1') {
      setSemester('1º Semestre')
    } else if (p === '2') {
      setSemester('2º Semestre')
    }
  }

  useEffect(() => {
    endForm()
  }, [questionsanswered, questions])

  const [abstract, setAbstract] = useState([])
  function endForm() {
    const copy = []
    questionsanswered.forEach(item => {
      questions.forEach(question => {
        if (question.id === item.question.id) {
          copy.push({ ...question, answer: item.answer, note: item.note })
        }
      })
    })
    sortIdentifiers(copy)
    setAbstract(copy)
  }

  const editStyle = {
    margin: '0.1rem',
    padding: '0.6rem 0.3rem',
    marginRight: '-30.2px',
    borderRadius: '1.5rem',
    boxShadow: '1px 1px 3px grey',
    cursor: 'pointer'
  }

  const [loading, setLoading] = useState(false)

  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form ref={formRef} className={styles.content}>
          <h1 className={styles.title_main}>Visualizar </h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca: </strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="brand"
                    onChange={element => {
                      if (element.target.value !== 'clear') {
                        setStores([])
                        setSectors([])
                        updateStores(element.target.value)
                      } else {
                        setStores([])
                      }
                    }}
                    array={brands}
                    id="marca"
                    label="Selecione..."
                  ></List>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja: </strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="store"
                    onChange={e => {
                      if (e.target.value !== 'clear') {
                        updateSectors(e.target.value)
                      } else {
                        setSectors([])
                      }
                    }}
                    id="loja"
                    array={stores}
                    label="Selecione"
                  ></List>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor: </strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    onChange={e => filterSector(e.target.value)}
                    name="sector"
                    id="setor"
                    array={sectors}
                    label={'Selecione...'}
                  ></List>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Ano: </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="year"
                    onChange={() => {}}
                  >
                    <option>{atualyear}</option>
                    <option>{atualyear - 1}</option>
                    <option>{atualyear - 2}</option>
                    <option>{atualyear - 3}</option>
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Semestre: </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    onChange={e => {
                      filterSemester(e.target.value)
                    }}
                    name="semester"
                  >
                    <>
                      <option value={'1'}>1° Semestre</option>
                      <option value={'2'}>2° Semestre</option>
                    </>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Status: </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    onChange={e => {
                      if (e.target.value === 'true') {
                        setFinished(true)
                      } else {
                        setFinished(false)
                      }
                    }}
                    name="status"
                  >
                    <option value={true}>Finalizadas</option>
                    <option value={false}>Pendentes</option>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione a Situação:</span>
                <div className={form.select}>
                  <Select
                    disabled={!finished}
                    className={form.campo_input}
                    onChange={e => {
                      if (e.target.value === 'true') {
                        setConsolidated(true)
                      } else {
                        setConsolidated(false)
                      }
                    }}
                    name="situation"
                  >
                    <option value={false}>Não Consolidadas</option>
                    <option value={true}>Consolidadas</option>
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.btn_bunscar}>
              <button
                className={form.btn}
                type="button"
                onClick={() => {
                  updateAudits(formRef.current.getFieldValue('sector'))
                }}
              >
                Ok
              </button>
            </div>
            <div style={{ overflowX: 'scroll' }}>
              <table className="search-table">
                <thead>
                  <tr className="title-table">
                    <th className="grow1">Código da Auditoria</th>
                    <th className="grow2">Data de Criação</th>
                    <th className="grow2">Marca</th>
                    <th className="grow2">Loja</th>
                    <th className="grow2">Setor</th>
                    <th className="grow2">Semestre</th>
                    <th className="grow2">Ano</th>
                    <th className="grow2">Status</th>
                    <th className="grow1">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <TableCompanies></TableCompanies>
                </tbody>
              </table>
            </div>
          </div>
        </Form>
        <Loading open={loading} />
      </div>
      {isViewAudit && <ModalCad />}
    </div>
  )
}

export default viewAudit
