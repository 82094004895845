import React, { useEffect, useState } from 'react'
import History from '../../services/history'
import Navbar from '../../components/navbar'
import styles from '../../styles/questionario.module.css'
import form from '../../styles/Formulario.module.css'
import { BsCardImage } from 'react-icons/bs'
import * as yup from 'yup'
import Footer from '../../components/footer'

import Input from '../../components/form/input'
import Textarea from '../../components/form/textarea'
import Loading from '../../components/alertLoading'
import QuestionBox from '../../components/question-box'
import api from '../../services/api'
import { toast, ToastContainer } from 'react-toastify'
import { Form } from '@unform/web'
import { capitalizeFirstLetter, clearAudit } from '../../services/functions'
import PreviewBox from '../../components/preview-box'

import { HiArrowNarrowLeft } from 'react-icons/hi'
import PainelComment from '../../components/painelComment'
import LogoCometa from '../../assets/gp-cometa.svg'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { sortIdentifiers, formatDate } from '../../services/auditoria'
// import formatDate from '../../services/auditoria/formatDate'

import Resizer from 'react-image-file-resizer'
import getDateYear from '../../services/auditoria/getDateYear'

function Questionario(props) {
  const [loading, setLoading] = useState(false)

  let result
  useEffect(() => {
    result = props?.location?.state
  }, [])

  const [fotos, setFotos] = useState([])

  const dados = props?.location?.state

  const [questions, setQuestions] = useState([])
  const [sector_name, setSector_name] = useState('')
  const [atualAnswer, setAtualAnswer] = useState({ answer: 0, photos: [] })

  const [idAuditoria, setIdAuditoria] = useState('')
  const [codeAuditoria, setCodeAuditoria] = useState('')

  const [questionsanswered, setQuestionsAnswered] = useState([])

  const [atualQuestion, setAtualQuestion] = useState()
  const [editing, setEditing] = useState()

  const [abstract, setAbstract] = useState([])
  // const [currentAudit, setCurrentAudit] = useState()

  const [ispreview, setIsPreview] = useState(false)
  const [auditDate, setAuditDate] = useState('')
  const [auditYear, setAuditYear] = useState('')

  const [controlView, setControlView] = useState(false)
  const [offsetY, setOffsetY] = useState(0)

  async function init() {
    if (result?.storeId) {
      const store_id = dados.storeId
      const sector_id = dados.sectorId
      setSector_name(dados.sectorName)

      if (localStorage.getItem('auditoria@id_auditoria') === null) {
        localStorage.setItem('store_id', dados.storeId)
        localStorage.setItem('sector_id', dados.sectorId)

        const bodyParameters = { store_id: store_id, sector_id: sector_id }
        setLoading(true)
        const response = await api.post('/v1/audits/', bodyParameters)

        const result = await api.get(`/v1/audits/${response.data.id}`)
        const ad = formatDate(result.data.created_at)
        const ay = getDateYear(result.data.created_at)
        localStorage.setItem('auditoria@audit_date', result.data.created_at)
        setAuditDate(ad)
        setAuditYear(ay)
        localStorage.setItem('auditoria@code_auditoria', response.data.code)
        localStorage.setItem('auditoria@id_auditoria', response.data.id)

        localStorage.setItem('auditoria@form_id', response.data.form_id)
        setIdAuditoria(response.data.id)
        getComments(response.data.id)
        setCodeAuditoria(response.data.code)
        const resp = await api.get(
          `/v1/forms/form-question/${response.data.form_id}`
        )

        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setQuestions(aux)
        getQuestionsAnswered()
        setLoading(false)
      } else {
        setIdAuditoria(localStorage.getItem('auditoria@id_auditoria'))
        getComments(localStorage.getItem('auditoria@id_auditoria'))
        setLoading(true)
        await api.get(
          `/v1/audits/${localStorage.getItem('auditoria@id_auditoria')}`
        )
        const ad = formatDate(localStorage.getItem('auditoria@audit_date'))
        const ay = getDateYear(localStorage.getItem('auditoria@audit_date'))
        setAuditDate(ad)
        setAuditYear(ay)
        setCodeAuditoria(localStorage.getItem('auditoria@code_auditoria'))
        const resp = await api.get(
          `/v1/forms/form-question/${localStorage.getItem('auditoria@form_id')}`
        )
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setQuestions(aux)
        getQuestionsAnswered()
        setLoading(false)
      }
    } else {
      if (localStorage.getItem('auditoria@id_auditoria') !== null) {
        setIdAuditoria(localStorage.getItem('auditoria@id_auditoria'))
        getComments(localStorage.getItem('auditoria@id_auditoria'))
        setCodeAuditoria(localStorage.getItem('auditoria@code_auditoria'))
        setLoading(true)
        const resp = await api.get(
          `/v1/forms/form-question/${localStorage.getItem('auditoria@form_id')}`
        )
        const ad = formatDate(localStorage.getItem('auditoria@audit_date'))
        const ay = getDateYear(localStorage.getItem('auditoria@audit_date'))
        setAuditDate(ad)
        setAuditYear(ay)
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setQuestions(aux)
        getQuestionsAnswered()
        setLoading(false)
      } else {
        toast.info('Erro interno, retornando para a tela de inicio')
      }
    }
  }

  async function getQuestionsAnswered() {
    try {
      const audit =
        idAuditoria || localStorage.getItem('auditoria@id_auditoria')
      if (audit) {
        setLoading(true)
        const resp = await api.get(`/v1/audits/answer/${audit}`)
        setQuestionsAnswered(resp.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        if (error.response.status !== 404) {
          showErrors(error.response.data.detail)
        }
      }
    }
  }

  // const url = `http://192.168.30.43/api`
  const url = `${process.env.REACT_APP_API_URL}`
  useEffect(() => {
    init()
    getComments()
  }, [])

  useEffect(() => {
    if (questions.identifier !== undefined) {
      sortIdentifiers(questions)
    }
  }, [questions])

  const [formOpen, setFormOpen] = useState(false)
  function formLauncher(questionID, answered) {
    if (answered) {
      questionsanswered.forEach(item => {
        if (questionID === item.question.id) {
          setAtualAnswer({ ...item, id: item.question.id })
        }
      })
      questions.forEach(item => {
        if (questionID === item.id) {
          setAtualQuestion({ ...item })
        }
      })
      setEditing(true)
      setFormOpen(!formOpen)
    } else {
      questions.forEach(item => {
        if (questionID === item.id) {
          setAtualQuestion({ ...item })
        }
      })

      // define valores padrão para a resposta atual
      setAtualAnswer({ answer: 0, note: '' })
      setEditing(false)
      setFormOpen(!formOpen)
    }
  }

  function updateAtualAnswer(response, index) {
    const aux = atualAnswer.photos
    aux.forEach(item => {
      if (item === newfotos[index].id) {
        item = response.data.filename
      }
    })
    setAtualAnswer({ ...atualAnswer, photos: aux })
  }

  async function handleSubmit(data) {
    data.note = parseFloat(data.note)
    if (isNaN(data.note)) {
      data.note = undefined
    }

    try {
      const schema = yup.object().shape({
        note: yup.number().required('O campo nota é obrigatório!')
      })
      await schema.validate(data, {
        abortEarly: false
      })

      const bodyParameters = {
        answer: data.note,
        note: data.comment || '',
        audit_id: idAuditoria,
        question_id: atualQuestion.id || atualQuestion.question.id
      }

      if (!editing) {
        setLoading(true)
        await api.post('v1/audits/answer', bodyParameters)
        setLoading(false)
      } else {
        setLoading(true)
        await api.put(`v1/audits/answer/${idAuditoria}/${atualQuestion.id}`, {
          answer: data.note,
          note: data.comment
        })
        setLoading(false)
      }

      const aux = []
      questions.forEach(item => {
        if (item.id === atualQuestion.id) {
          aux.push({ ...item, answered: true })
        } else {
          aux.push(item)
        }
      })

      const formData = new FormData()
      const config = {
        headers: {
          'Content-Type': 'image/*'
        }
      }

      const hasFoto1 = fotos.length >= 1
      const hasFoto2 = fotos.length >= 2
      const hasFoto3 = fotos.length >= 3

      if (editing) {
        if (newfotos.length >= 1) {
          setLoading(true)
          if (formData.get('photo')) {
            formData.delete('photo')
          }
          formData.append('photo', newfotos[0].foto)
          const response = await api.put(
            `/v1/audits/answer-photo/${newfotos[0].id}`,
            formData,
            config
          )
          updateAtualAnswer(response, 0)
          setLoading(false)
        }
        if (newfotos.length >= 2) {
          setLoading(true)
          if (formData.get('photo')) {
            formData.delete('photo')
          }
          formData.append('photo', newfotos[1].foto)
          const response = await api.put(
            `/v1/audits/answer-photo/${newfotos[1].id}`,
            formData,
            config
          )
          updateAtualAnswer(response, 1)
          setLoading(false)
        }
        if (newfotos.length >= 3) {
          setLoading(true)
          if (formData.get('photo')) {
            formData.delete('photo')
          }
          formData.append('photo', newfotos[2].foto)
          const response = await api.put(
            `/v1/audits/answer-photo/${newfotos[2].id}`,
            formData,
            config
          )
          updateAtualAnswer(response, 2)
          setLoading(false)
        }
      }
      setNewfotos([])

      if (hasFoto1) {
        try {
          setLoading(true)
          formData.append('photo', fotos[0])
          if (hasFoto2) {
            formData.append('photo', fotos[1])
          }
          if (hasFoto3) {
            formData.append('photo', fotos[2])
          }
          formData.append('audit_id', idAuditoria)
          formData.append('question_id', atualQuestion.id)
          await api.post(`/v1/audits/answer-photo/`, formData, config)

          setLoading(false)
        } catch (error) {
          setLoading(false)
          if (error.response !== undefined) {
            showErrors(error.response.data.detail)
          }
        }
      }
      getQuestionsAnswered()
      setControlView(true)
      setFormOpen(false)
      setFotos([])
      setQuestions(aux)
    } catch (error) {
      setLoading(false)
      if (error instanceof yup.ValidationError) {
        error.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else {
        const result = error.response
        setFotos([])
        if (result !== undefined) {
          showErrors(result.data.detail)
        }
      }
    }
  }

  useEffect(() => {
    const porcentagem = document.getElementById('progressAtual')
    const valor = (questionsanswered.length / questions.length) * 100
    porcentagem.style.width = valor + '%'
  }, [questionsanswered])

  function showErrors(errors) {
    if (typeof errors === 'object') {
      errors?.forEach(err => {
        toast.error(err.msg)
      })
    } else {
      toast.error(errors)
    }
  }

  async function endAudit() {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/${idAuditoria}`)

      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Auditoria finalizada com sucesso!',
        showConfirmButton: true,
        timer: 1500
      }).then(() => {
        clearAudit()
        if (localStorage.getItem('auditoria@painel_return_to')) {
          localStorage.getItem('auditoria@painel_return_to')
          localStorage.removeItem('auditoria@painel_return_to')
          History.push(`${process.env.PUBLIC_URL}/auditoria`)
        } else {
          History.push(`${process.env.PUBLIC_URL}/auditoria`)
        }
      })
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        showErrors(error.response.data.detail)
      }
    }
  }

  async function sendName(auditName) {
    try {
      const auditId = localStorage.getItem('auditoria@id_auditoria')

      await api.put(`/v1/audits/${auditId}`, { name: auditName })
      endForm()
    } catch (err) {
      console.log(err)
    }
  }

  function endForm() {
    if (questions.length === questionsanswered.length) {
      const copy = []
      questionsanswered.forEach(item => {
        questions.forEach(question => {
          if (question.id === item.question.id) {
            copy.push({ ...question, note: item.answer })
          }
        })
      })

      setIsPreview(true)
      sortIdentifiers(copy)
      setAbstract(copy)
    } else {
      toast.error('Voce não respondeu todas as questões para finalizar')
    }
  }

  function AuditName() {
    Swal.fire({
      title: 'Digite o nome da auditoria',
      input: 'text',
      inputPlaceholder: 'Campo Obrigatório',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      preConfirm: name => {
        if (name.length > 15) {
          Swal.fire({
            icon: 'error',
            title: 'Limite de caracteres ultrapassado!!!',
            showConfirmButton: false,
            timer: 1500
          })
        } else if (name.trim().length === 0) {
          Swal.fire({
            icon: 'error',
            title: 'O nome é obrigatório!!!',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          sendName(name)
        }
      }
    })
  }

  function PreviewLauncher() {
    return (
      <div className={styles.modal_container}>
        <div className={styles.preview_modal}>
          <div className={styles.preview_title}>
            <h3>Finalizar a auditoria?</h3>
          </div>
          {abstract.map((item, index) => {
            return (
              <div key={index}>
                <PreviewBox
                  number={item.identifier + 1}
                  note={item.note}
                  excerpt={item.enunciation}
                  weight={item.value}
                ></PreviewBox>
              </div>
            )
          })}
          <div className={styles.preview_buttons}>
            <button
              className={styles.form_button}
              onClick={() => {
                endAudit()
              }}
            >
              Confirmar
            </button>
            <button
              className={styles.form_button}
              onClick={() => {
                setIsPreview(false)
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    )
  }

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'base64'
      )
    })

  const [currentfotoid, setCurrentfotoid] = useState()
  const [newfotos, setNewfotos] = useState([])

  async function showFoto(fotoid) {
    setCurrentfotoid(fotoid)
    setLoading(true)
    try {
      await api.get(`/v1/audits/answer-photo/${fotoid}?id=${fotoid}`)
      setLoading(false)
      Swal.fire({
        title: 'Foto',
        html: `<img src="${url}/v1/audits/answer-photo/${fotoid}?id=${fotoid}" height="256"></img>`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: `<label for="capture_new_foto">Alterar</label>`
      })
    } catch (e) {
      setLoading(false)
    }
  }

  async function showImage(e) {
    try {
      const image = await resizeFile(e)
      const element = `<img src="${image}" >`
      Swal.fire({
        title: 'Imagem',
        html: element
      })
    } catch (err) {
      setLoading(false)
    }
  }
  async function showImageEdited(e) {
    try {
      const image = await resizeFile(e)
      const element = `<img src="${image}" >`
      Swal.fire({
        title: 'Foto',
        html: element,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        confirmButtonText: `<label for="capture_new_foto">Alterar</label>`
      })
    } catch (err) {
      setLoading(false)
    }
  }

  const [comments, setComments] = useState([])

  const getComments = async auditId => {
    try {
      setLoading(true)
      const resp = await api.get(
        `/v1/audits/comment/${
          auditId ||
          idAuditoria ||
          localStorage.getItem('auditoria@id_auditoria')
        }`
      )
      setLoading(false)
      setComments(resp?.data.reverse() || [])
    } catch (e) {
      setLoading(false)
      console.error(e)
      setComments([])
    }
  }

  const handleComments = async newComment => {
    try {
      setLoading(true)
      await api.post(`/v1/audits/comment/${idAuditoria}`, newComment)
      setLoading(false)
      await getComments()
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    if (controlView === true) {
      setControlView(false)
      setTimeout(() => {
        window.scrollTo({
          top: offsetY,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    }
  }, [controlView])

  const [comment, setComment] = useState(false)
  const handleClose = () => setComment(false)

  return (
    <div className="container">
      {ispreview && <PreviewLauncher></PreviewLauncher>}

      <Navbar></Navbar>
      <ToastContainer></ToastContainer>
      <div className="page-title">
        <a
          onClick={() => {
            Swal.fire({
              title: 'Voltar ao início?',
              icon: 'info',
              text: 'Suas respostas foram salvas, você poderá continar este formulário a qualquer momento acessando a aba "Pendentes" ',
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Confirmar',
              confirmButtonAriaLabel: 'Thumbs up, great!',
              cancelButtonText: 'Cancelar',
              cancelButtonAriaLabel: 'Thumbs down'
            }).then(result => {
              if (result.isConfirmed) {
                History.push(`${process.env.PUBLIC_URL}/auditoria`)
                clearAudit()
              }
            })
          }}
        >
          <HiArrowNarrowLeft></HiArrowNarrowLeft>
        </a>
        <h2>Questionario</h2>
      </div>
      <PainelComment
        comments={comments}
        addComment={true}
        removeComment={true}
        open={comment}
        close={handleClose}
        handleComment={event => handleComments(event)}
        onSetComment={async () => await getComments()}
      />
      <div className="content mg-ledo">
        <div className="aud-group align-center">
          <img src={LogoCometa} className="logoCometa"></img>
        </div>
        <div className="row setor-row">
          <div className="col">
            <div className="row">
              <label className="setor">Setor - </label>
              <p className="setor-nome"> {sector_name}</p>
            </div>
            <div className="row">
              <label className="question-atual">
                {questionsanswered.length}/
              </label>
              <p className="question-total">{questions.length}</p>
            </div>
          </div>
          <div className="col">
            <div className="progressAll">
              <div className="progressAtual" id="progressAtual"></div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <label className="font-bold" style={{ marginRight: '0.5rem' }}>
                Código:
              </label>
              <p className="font-regular">
                {codeAuditoria}/{auditYear}
              </p>
            </div>
            <div className="row">
              <label className="font-bold" style={{ marginRight: '0.5rem' }}>
                Data de Início:
              </label>
              <p className="font-regular"> {auditDate}</p>
            </div>
          </div>
        </div>
        {formOpen && (
          <div className="questions-body-form">
            <div className="question-form-container">
              <div className="question-item box-light w-100">
                <div>
                  <div className="question-number">
                    Indentificador {atualQuestion?.number}
                  </div>
                  <div className="question-answered"></div>
                </div>
                <div className="question-excerpt">
                  <p>
                    {capitalizeFirstLetter(
                      atualQuestion?.enunciation.toLowerCase()
                    )}
                  </p>
                </div>
                <div className="question-footer">
                  <label>Peso</label>
                  <p>{atualQuestion?.value}</p>
                </div>
              </div>
            </div>
            <div className="linha"></div>
            <Form onSubmit={handleSubmit}>
              <div className="form-answers">
                <div className={styles.form_answers_row}></div>
                <div className={styles.answer_item}>
                  <label className={styles.answer_label}>
                    Informe uma nota de 0 a 10
                  </label>
                  <div className={styles.bg_input}>
                    <div className="d-flex1">
                      <Input
                        className={form.campo_input}
                        value={atualAnswer?.answer}
                        name="note"
                        type="range"
                        step="5"
                        min="0"
                        max="10"
                        onChange={e => {
                          setAtualAnswer({
                            ...atualAnswer,
                            answer: e.target.value
                          })
                        }}
                      ></Input>
                      <p className="font-white">{atualAnswer?.answer}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.form_answers_row}></div>

                <div className={styles.answer_item_optional}>
                  <label className={styles.answer_label}>
                    Evidência da Auditoria:
                    <span>Opcional</span>
                  </label>
                  <div className={styles.bg_input}>
                    <Textarea
                      value={atualAnswer?.note}
                      name="comment"
                      placeholder="Comentário"
                      type="text"
                      onChange={e => {
                        setAtualAnswer({
                          ...atualAnswer,
                          note: e.target.value
                        })
                      }}
                    ></Textarea>
                  </div>
                  {/* )} */}
                </div>
                <div className={styles.form_answers_row}></div>

                <div className={styles.answer_item_optional}>
                  <label className={styles.answer_label}>
                    Anexar até 03 imagens: <span>Opcional</span>
                  </label>
                  {/* {hasfoto && ( */}
                  <div className="d-flex">
                    {!editing &&
                      fotos.map((image, i) => {
                        return (
                          <BsCardImage
                            onClick={() => {
                              showImage(image)
                            }}
                            key={i}
                            className={styles.img_miniature}
                          ></BsCardImage>
                        )
                      })}
                    {editing &&
                      atualAnswer.photos.map((foto, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              minWidth: '20%',
                              margin: '0 0.5rem'
                            }}
                          >
                            <BsCardImage
                              style={{ width: '100%' }}
                              onClick={() => {
                                let isID = false
                                newfotos.forEach(item => {
                                  if (item.id === foto) {
                                    showImageEdited(item.foto)
                                    isID = true
                                  }
                                })
                                if (!isID) {
                                  showFoto(foto)
                                }
                              }}
                              key={i}
                              className={styles.img_miniature}
                            />
                            <label
                              onClick={() => {
                                try {
                                  Swal.fire({
                                    title: 'Remover foto?',
                                    icon: 'warning',
                                    text: 'Deseja mesmo remover esta foto',
                                    showCloseButton: true,
                                    showCancelButton: true,
                                    focusConfirm: false,
                                    confirmButtonText: 'Sim, remover',
                                    confirmButtonAriaLabel: 'Thumbs up, great!',
                                    cancelButtonText: 'Cancelar',
                                    cancelButtonAriaLabel: 'Thumbs down'
                                  }).then(async result => {
                                    if (result.isConfirmed) {
                                      const resp = await api.delete(
                                        `/v1/file/filename/${foto}`
                                      )
                                      if (resp.status === 200) {
                                        atualAnswer.photos.splice(i, 1)
                                        toast.success(
                                          'A foto foi removida com sucesso!'
                                        )
                                      }
                                    }
                                  })
                                } catch (e) {
                                  toast.warn('A foto não foi removida!')
                                }
                              }}
                              style={{
                                borderRadius: '3rem',
                                padding: '0.3rem 0.55rem',
                                margin: '0.1rem -1.3rem',
                                display: 'block',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}
                              className={styles.plus_icon}
                            >
                              <span
                                style={{
                                  fontSize: '1rem',
                                  lineHeight: '1rem',
                                  display: 'flex'
                                }}
                              >
                                x
                              </span>
                            </label>
                          </div>
                        )
                      })}
                    {editing &&
                      fotos.length > 0 &&
                      fotos.map((image, i) => {
                        return (
                          <BsCardImage
                            onClick={() => {
                              showImage(image)
                            }}
                            key={i}
                            className={styles.img_miniature}
                          ></BsCardImage>
                        )
                      })}

                    <label className={styles.plus_icon} htmlFor="capture">
                      <span>+</span>
                    </label>

                    <input
                      className="d-none"
                      type="file"
                      id="capture_new_foto"
                      accept="image/png, image/jpeg"
                      capture="user"
                      onChange={e => {
                        let fotoexist = false
                        const aux = []
                        newfotos.forEach(item => {
                          if (item.id === currentfotoid) {
                            fotoexist = true
                          }
                          aux.push(item)
                        })

                        if (fotoexist) {
                          aux.forEach(item => {
                            if (item.id === currentfotoid) {
                              item = { ...item, foto: e.target.files[0] }
                            }
                          })
                        } else {
                          aux.push({
                            id: currentfotoid,
                            foto: e.target.files[0]
                          })
                        }

                        setNewfotos(aux)
                      }}
                    />
                    <input
                      className="d-none"
                      type="file"
                      id="capture"
                      capture="user"
                      accept="image/png, image/jpeg"
                      onChange={e => {
                        const aux = []

                        fotos.forEach(item => {
                          aux.push(item)
                        })
                        let tam
                        if (editing) {
                          tam = aux.length + atualAnswer.photos.length
                        } else {
                          tam = aux.length
                        }
                        if (tam <= 2) {
                          if (e.target.files[0]) {
                            aux.push(e.target.files[0])
                          }
                        } else {
                          toast.info('Número máximo de fotos atingido')
                        }
                        setFotos(aux)
                      }}
                    />
                  </div>
                  {/* )} */}
                </div>
                <div className={styles.form_answers_row}></div>
              </div>

              <div className="button-row">
                <button type="submit">Confirmar</button>
                <button
                  onClick={() => {
                    setFotos([])
                    setControlView(true)
                    setFormOpen(false)
                  }}
                >
                  Voltar
                </button>
              </div>
            </Form>
          </div>
        )}
        {!formOpen && (
          <div className="questions-body">
            <div className="body-title">Selecione Identidicador</div>
            <button
              className={styles.form_button}
              onClick={() => {
                setComment(true)
              }}
            >
              Ver comentários
            </button>
            {questions.map((question, index) => {
              let resp
              let answer
              questionsanswered.forEach(item => {
                if (item.question.id === question.id) {
                  resp = true
                  answer = item.answer
                }
              })
              return (
                <span
                  key={index}
                  onClick={() => {
                    setOffsetY(window.scrollY)
                    formLauncher(question.id, resp)
                  }}
                >
                  <QuestionBox
                    number={`Indicador ${question.identifier + 1}`}
                    excerpt={question.enunciation}
                    weight={question.value}
                    answered={resp}
                    answer={typeof answer === 'number' ? answer : undefined}
                  />
                </span>
              )
            })}
          </div>
        )}
        <div className="linha"></div>
        <br />
      </div>
      <div className="button-row-center">
        {!formOpen && !ispreview && (
          <>
            {questionsanswered.length !== questions.length && (
              <button
                id="finaliza-audi"
                className={styles.form_button}
                onClick={() => {
                  AuditName()
                }}
              >
                Finalizar Auditoria
              </button>
            )}
            {questionsanswered.length === questions.length && (
              <button
                id="finaliza-audi"
                className={styles.form_button_active}
                onClick={() => {
                  AuditName()
                }}
              >
                Finalizar Auditoria
              </button>
            )}
          </>
        )}
      </div>
      <Footer></Footer>
      <Loading open={loading} />
    </div>
  )
}
Questionario.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object
}
export default Questionario
