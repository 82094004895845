import React, { useState } from 'react'
import PropTypes from 'prop-types'
export const ModalsContext = React.createContext({})

export const ModalsProviders = props => {
  const [openMarca, setOpenMarca] = useState(false)
  const [openLoja, setOpenLoja] = useState(false)
  const [openSetor, setOpenSetor] = useState(false)

  const [editingMarca, setEditingMarca] = useState(false)
  const [editingStore, setEditingStore] = useState(false)
  const [editingSector, setEditingSector] = useState(false)

  const [geraRelatorio, setGeraRelatorio] = useState(false)

  return (
    <ModalsContext.Provider
      value={{
        openMarca,
        setOpenMarca,
        openLoja,
        setOpenLoja,
        openSetor,
        setOpenSetor,
        editingMarca,
        setEditingMarca,
        editingStore,
        setEditingStore,
        editingSector,
        setEditingSector,
        geraRelatorio,
        setGeraRelatorio
      }}
    >
      {props.children}
    </ModalsContext.Provider>
  )
}

ModalsProviders.propTypes = {
  children: PropTypes.any
}
