import React, { useEffect, useState, useRef, useContext } from 'react'
import Loading from '../../../components/alertLoading'
import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import styles from '../../../styles/painel.module.css'
import form from '../../../styles/Formulario.module.css'
import { Form } from '@unform/web'
import api from '../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { FiFileText } from 'react-icons/fi'
import Select from '../../../components/form/Select'
import List from '../../../components/List'
import { showErrors } from '../../../services/functions'
import { ModalsContext } from '../../../providers/modals'
import GenerateReport from '../../../components/gerarRelatorio'

function painelReport() {
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState('')
  const [stores, setStores] = useState([])
  const [store, setStore] = useState('')
  const [sectors, setSectors] = useState([])
  const [auditoria, setAuditoria] = useState([])
  const [idAuditoria, setIdAuditoria] = useState()
  const [idForm, setIdForm] = useState()
  const { geraRelatorio, setGeraRelatorio } = useContext(ModalsContext)
  const [sectorName, setSectorName] = useState('')
  const [currentBrandName, setCurrentBrandName] = useState('')
  const [currentStoreName, setCurrentStoreName] = useState('')
  const [nota, setNota] = useState(0)
  const [configs, setConfigs] = useState([])
  const [identifier, setIdentifier] = useState('')
  const [chief_auditor, setChief_auditor] = useState('')
  const [representatives, setRepresentatives] = useState('')
  const [semester, setSemester] = useState('1º Semestre')

  // const [configList, setConfigList] = useState([])
  const [formlist, setFormList] = useState([])
  // let vetor = []

  let forms = []

  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setBrands(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateStores(b) {
    try {
      setLoading(true)
      const aux = brands.find(e => e.id === b).name
      setBrand(aux)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setStores(response?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors(s) {
    try {
      setLoading(true)
      const aux = stores.find(e => e.id === s).name
      setStore(aux)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setSectors(response?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  useEffect(() => {
    init()
  }, [geraRelatorio])

  function GeraRelatorio() {
    return (
      <>
        {geraRelatorio && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setGeraRelatorio(false)
              }}
            ></div>
            <GenerateReport
              brand={currentBrandName}
              dealership={currentStoreName}
              department={sectorName}
              className={styles.imprimir}
              value={auditoria}
              form={idForm}
              configs={configs}
              nota={nota}
              identifier={identifier}
              audit={currentAudit}
              chief_auditor={chief_auditor}
              representatives={representatives}
            />
          </div>
        )}
      </>
    )
  }

  async function getAuditResult(auditId) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/audits/answer/${auditId}`)
      setLoading(false)
      setAuditoria(response.data)
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.detail) {
        showErrors(error.response.data.detail)
      }
    }
  }

  const atualDate = new Date()
  const atualyear = atualDate.getFullYear()
  const [currentAudit, setCurrentAudit] = useState()

  function TableCompanies() {
    return audits.map((item, index) => {
      const created_date = new Date(item.created_at)
      const month = created_date.getMonth()

      const day = String(created_date.getDate()).padStart(2, '0')
      const year = created_date.getFullYear()
      const output = `${day} / ${month < 9 ? '0' : ''}${month + 1} / ${year}`

      return (
        <tr key={index}>
          <td className="grow1">{item.code}</td>
          <td className="grow2">{output}</td>
          <td className="grow2">{brand}</td>
          <td className="grow2">{store}</td>
          <td className="grow2">{sectorName}</td>
          <td className="grow2">{semester}</td>
          <td className="grow2">{year}</td>
          <td className="grow2">
            {item.consolidated_at === null ? 'Não consolidada' : 'Consolidada'}
          </td>
          <td className="grow1 td-opc">
            <div
              className="btn-edit"
              onClick={() => {
                setCurrentAudit(item)
                getAuditResult(item.id)
                setGeraRelatorio(true)
                getGraphData(item.id, item.form.id, item.form.goal)
                setIdForm(item.form.id)
                setIdentifier(item.code)
                setChief_auditor(item.chief_auditor)
                setRepresentatives(item.representatives)
              }}
            >
              <FiFileText />
              <p>Gerar Reltório</p>
            </div>
          </td>
        </tr>
      )
    })
  }

  const [audits, setAudits] = useState([])

  async function updateAudits(sectorid) {
    try {
      const year = parseInt(formRef.current.getFieldValue('year'))
      const semester = formRef.current.getFieldValue('semester')
      const status = formRef.current.getFieldValue('status')

      setLoading(true)
      const response = await api.get(
        `/v1/audits/${formRef.current.getFieldValue(
          'store'
        )}/${sectorid}?year=${year}&is_finished=${true}&is_consolidated=${status}&semester=${semester}`
      )
      setLoading(false)
      setAudits(response.data)
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        showErrors(error.response.data.detail)
      }

      if (error?.response?.status === 404) {
        setAudits([])
      }
    }
  }

  async function getGraphData(auditId, formId, formGoal) {
    try {
      const localGoal = formGoal

      setIdAuditoria(auditId)
      setLoading(true)
      const resp = await api.get(`/v1/forms/form-question/${formId}`)
      const aux = []
      resp.data.forEach((item, index) => {
        aux.push({ ...item, number: index + 1 })
      })
      getQuestionsAnswered(localGoal, aux, auditId, sectorName)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error('Não foi possivel obter os dados do Gráfico.')
    }
  }

  async function getQuestionsAnswered(goal, questions, audit, sectorName) {
    try {
      const auditID = audit || idAuditoria
      if (auditID) {
        setLoading(true)
        const response = await api.get(`/v1/audits/answer/${auditID}`)

        const meta = []
        const copy = []
        const object = []
        const numbers = []
        const weights = []
        const values = []

        questions.forEach(question => {
          response.data.forEach(item => {
            if (question.id === item.question.id) {
              copy.push({ ...question, answer: item.answer })
              numbers.push('Indicador ' + (parseInt(question.identifier) + 1))
            }
          })
        })

        copy.forEach((element, index) => {
          values.push(element.answer)
          weights.push(questions[index].value)
          object.push({ note: element.answer, weight: questions[index].value })
          meta.push(goal)
        })

        UpdateConfigList(
          { values: object, goal: goal, name: sectorName },
          {
            questionsNumber: numbers,
            goals: meta,
            values: values,
            weights: weights
          }
        )
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        if (error.response.status !== 404) {
          showErrors(error.response.data.detail)
        }
      }
    }
  }

  function UpdateConfigList(element, form) {
    form.sectorName = element.name
    // vetor = [...vetor, element]
    forms = [...forms, form]
    setFormList(forms)
  }

  const filterSemester = p => {
    console.log(p)
    if (p === '1') {
      setSemester('1º Semestre')
    } else if (p === '2') {
      setSemester('2º Semestre')
    }
  }

  useEffect(() => {
    formsResultGrap(formlist)
  }, [formlist])

  function formsResultGrap(parameters) {
    if (parameters.length > 0) {
      const values = parameters[0]?.values
      const weights = parameters[0]?.weights
      let result = 0
      let weight = 0
      for (let c = 0; c < values?.length; c++) {
        result += values[c] * weights[c]
        weight += weights[c]
      }

      const calc = result / (weight / 10)

      setNota(calc.toFixed(2))
    }

    parameters.forEach(parameter => {
      const arr = []

      arr.push({
        notas: {
          series: [
            {
              name: 'Nota Atribuída',
              data: parameter.values
            }
          ],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '10'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            title: {
              text: `Setor: ${parameter.sectorName}`
            },
            xaxis: {
              categories: parameter.questionsNumber,
              title: {
                text: 'Indicadores da Auditoria'
              }
            },
            yaxis: [
              {
                title: {
                  // text: atualStores
                  text: 'Notas'
                },
                min: 0,
                max: 10
              }
            ],
            fill: {
              opacity: 1
            },
            tooltip: {}
          }
        },
        pesos: {
          series: parameter.weights,
          options: {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: parameter.questionsNumber,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            ]
          }
        },
        name: parameter.sectorName
      })
      setConfigs(arr)
    })
  }

  return (
    <div className={styles.containerRow}>
      <GeraRelatorio />
      <Sidebar opc={4}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form ref={formRef} className={styles.content}>
          <h1 className={styles.title_main}>Relatório</h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="brand"
                    onChange={element => {
                      if (element.target.value !== 'clear') {
                        setStores([])
                        setSectors([])
                        updateStores(element.target.value)
                      } else {
                        setStores([])
                      }
                      let aux = ''
                      brands.forEach(e => {
                        if (e.id === element.target.value) {
                          aux = e.name
                        }
                      })
                      setCurrentBrandName(aux)
                    }}
                    array={brands}
                    id="marca"
                    label="Selecione..."
                  />
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="store"
                    onChange={element => {
                      if (element.target.value !== 'clear') {
                        setSectors([])
                        updateSectors(element.target.value)
                      } else {
                        setSectors([])
                      }
                      let aux = ''
                      stores.forEach(e => {
                        if (e.id === element.target.value) {
                          aux = e.name
                        }
                      })
                      setCurrentStoreName(aux)
                    }}
                    id="loja"
                    array={stores}
                    label="Selecione"
                  ></List>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    onChange={e => {
                      // updateAudits(e.target.value)
                      let name = ''
                      sectors.forEach(item => {
                        if (item.id === e.target.value) {
                          name = item.name
                        }
                      })
                      setSectorName(name)
                    }}
                    name="sector"
                    id="setor"
                    array={sectors}
                    label={'Selecione...'}
                  ></List>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Ano</span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="year"
                    onChange={() => {
                      // updateAudits(formRef.current.getFieldValue('sector'))
                    }}
                  >
                    <option>{atualyear}</option>
                    <option>{atualyear - 1}</option>
                    <option>{atualyear - 2}</option>
                    <option>{atualyear - 3}</option>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Semestre</span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    onChange={e => {
                      const setor = formRef.current.getFieldValue('sector')
                      // updateAudits(setor)
                      let name = ''
                      sectors.forEach(item => {
                        if (item.id === setor) {
                          name = item.name
                        }
                      })
                      setSectorName(name)
                      filterSemester(e.target.value)
                    }}
                    name="semester"
                  >
                    <option value={'1'}>1° Semestre</option>
                    <option value={'2'}>2° Semestre</option>
                  </Select>
                </div>
              </div>

              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Status</span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    onChange={() => {
                      // updateAudits(formRef.current.getFieldValue('sector'))
                    }}
                    name="status"
                  >
                    <option value={false}>Não Consolidadas</option>
                    <option value={true}>Consolidadas</option>
                  </Select>
                </div>
              </div>
            </div>

            <div className={styles.btn_bunscar}>
              <button
                className={form.btn}
                type="button"
                onClick={() => {
                  updateAudits(formRef.current.getFieldValue('sector'))
                }}
              >
                Ok
              </button>
            </div>
            <div style={{ overflowX: 'scroll' }}>
              <table className="search-table">
                <thead>
                  <tr className="title-table">
                    <th className="grow1">Código da Auditoria</th>
                    <th className="grow2">Data de Criação</th>
                    <th className="grow2">Marca</th>
                    <th className="grow2">Loja</th>
                    <th className="grow2">Setor</th>
                    <th className="grow2">Semestre</th>
                    <th className="grow2">Ano</th>
                    <th className="grow2">Status</th>
                    <th className="grow1">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <TableCompanies></TableCompanies>
                </tbody>
              </table>
            </div>
          </div>
        </Form>
      </div>
      <Loading open={loading} />
    </div>
  )
}

export default painelReport
