/* eslint-disable no-unused-vars */
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import Chart from 'react-apexcharts'
import { ToastContainer, toast } from 'react-toastify'
import AlertLoading from '../../../components/alertLoading'
import Select from '../../../components/form/Select'
import Loading from '../../../components/loading'
import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import api from '../../../services/api'
import { getStoreName } from '../../../services/auditoria'
import { areasAverageGraph } from '../../../services/dashboard'
import form from '../../../styles/Formulario.module.css'
import styles from '../../../styles/painel.module.css'

export default function painelDashboard() {
  const [alertLoading, setAlertLoading] = useState(false)
  const [atualBrand, setAtualBrand] = useState()
  const [brands, setBrands] = useState([])
  const [stores, setStores] = useState([])
  const atualDate = new Date()
  const atualyear = atualDate.getFullYear()
  const [loading, setLoading] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(null)
  const [sectors, setSectors] = useState([])

  const [repeatedAudit, setRepeatedAudit] = useState([])
  const [conflictAudit, setConflictAudit] = useState(false)

  const formRef = useRef(null)
  useEffect(() => {
    init()
  }, [])

  async function init() {
    try {
      setAlertLoading(true)
      const resp = await api.get('/v1/stores/brand')
      setBrands(resp.data)
      setAlertLoading(false)
    } catch (err) {
      setAlertLoading(false)
      toast.error(err?.response)
    }
  }

  useEffect(() => {
    const body = document.body

    if (loading) {
      body.style.overflow = 'hidden'
      body.style.position = 'fixed'
    } else {
      body.style.overflow = 'auto'
      body.style.position = 'initial'
    }
  }, [loading])

  function Brands() {
    return (
      <div className={(styles.flex_column_bet, form.input_w100)}>
        <span className={form.title_input}>Selecione a Marca</span>
        <div className={form.select}>
          <Select
            className={form.campo_input}
            name="brand"
            value={atualBrand}
            onChange={e => {
              setStores([])
              setSectors([])
              updateStores(e.target.value)
              setAtualBrand(e.target.value)
            }}
          >
            <option>Marca</option>
            {brands.map((brand, index) => {
              return (
                <option key={index} value={brand.id}>
                  {brand.name}
                </option>
              )
            })}
          </Select>
        </div>
      </div>
    )
  }

  const [atualStores, setAtualStores] = useState('')
  async function updateStores(b) {
    try {
      setAlertLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setStores(response?.data)
      setAlertLoading(false)
      formRef.current.setFieldValue('brand', b)
    } catch (err) {
      setAlertLoading(false)
      toast.error(err?.response)
    }
  }

  function Stores() {
    return (
      <div className={(styles.flex_column_bet, form.input_w100)}>
        <span className={form.title_input}>Selecione a Loja</span>
        <div className={form.select}>
          <Select
            className={form.campo_input}
            name="stores"
            value={atualStores}
            onChange={e => {
              updateSectors(e.target.value)
              setAtualStores(e.target.value)
            }}
          >
            <option>Loja</option>
            {stores.map((store, index) => {
              return (
                <option key={index} value={store.id}>
                  {store.name}
                </option>
              )
            })}
          </Select>
        </div>
      </div>
    )
  }

  async function updateSectors(s) {
    try {
      setAlertLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setSectors(response?.data)
      setAlertLoading(false)
      formRef.current.setFieldValue('stores', s)
    } catch (err) {
      setAlertLoading(false)
      toast.error(err?.response)
    }
  }

  const confirmData = () => {
    if (formRef.current.getFieldValue('brand') === 'Marca') {
      toast.info('Selecione uma Marca!')
    } else if (formRef.current.getFieldValue('stores') === 'Loja') {
      toast.info('Selecione uma Loja!')
    } else {
      const data =
        sectors.map(sector => ({
          id: sector.id,
          name: sector.name
        })) || []
      setLoading(true)
      setLoadingStatus('Buscando setores...')
      testAudit({
        loja: formRef.current.getFieldValue('stores'),
        marca: formRef.current.getFieldValue('brand'),
        sectors: data
      })
    }
  }

  const resetConfigs = () => {
    setConfigs([])
    setMainGoal([])
    setMainValue([])
    setMainLabels([])
    setAreaData([])
  }

  const verificaTamanhoArray = arr => {
    return arr.some(objeto => objeto.audits.length > 1)
  }

  const testAudit = async dados => {
    setLoadingStatus('Buscando auditorias...')

    const year = parseInt(formRef.current.getFieldValue('year'))
    const semester = formRef.current.getFieldValue('semester')
    const status = formRef.current.getFieldValue('status')

    const listSectorAudit = []

    for (let c = 0; c < dados?.sectors.length; c++) {
      try {
        const response = await api.get(
          `/v1/audits/${dados.loja}/${
            dados.sectors[c].id
          }?year=${year}&is_finished=${true}&is_consolidated=${status}&semester=${semester}`
        )

        listSectorAudit.push({
          id: dados.sectors[c].id,
          sector: dados.sectors[c].name,
          audits: response.data
        })
      } catch (err) {
        resetConfigs()
      }
    }

    // verificaTamanhoArray:
    // true - existe mais de uma auditoria em algum setor
    // false - não existe mais de uma auditoria por setor

    if (verificaTamanhoArray(listSectorAudit) === true) {
      // console.log(dados)
      // console.log(listSectorAudit)
      for (let i = 0; i < listSectorAudit.length; i++) {
        listSectorAudit[i].audits = listSectorAudit[i].audits.map(
          (object, index) => ({
            ...object,
            checked: index === 0
          })
        )
      }

      setRepeatedAudit(listSectorAudit)
      setConflictAudit(true)
      setLoadingStatus(null)
      setLoading(false)
    } else {
      getDados(dados)
    }
  }

  const handleFilterAudits = async () => {
    setLoadingStatus('Buscando auditorias...')
    setLoading(true)
    const graphDate = []

    for (let i = 0; i < repeatedAudit.length; i++) {
      try {
        const tempResult = repeatedAudit[i].audits.find(e => e.checked === true)
        const result = tempResult
        const localGoal = result.form.goal

        const resp = await api.get(`/v1/forms/form-question/${result.form.id}`)
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        graphDate.push({
          goal: localGoal,
          questions: aux,
          audit: result.id,
          name: repeatedAudit[i].sector
        })
      } catch (err) {
        resetConfigs()
      }
    }
    setConflictAudit(false)
    getQuestionsAnswered(graphDate)
  }

  const [idAuditoria, setIdAuditoria] = useState('')

  async function getDados(dados) {
    setLoadingStatus('Buscando auditorias...')

    const year = parseInt(formRef.current.getFieldValue('year'))
    const semester = formRef.current.getFieldValue('semester')
    const status = formRef.current.getFieldValue('status')

    const lixo = []

    for (let c = 0; c < dados?.sectors.length; c++) {
      try {
        const response = await api.get(
          `/v1/audits/${dados.loja}/${
            dados.sectors[c].id
          }?year=${year}&is_finished=${true}&is_consolidated=${status}&semester=${semester}`
        )
        resetConfigs()

        const result = response.data[0]
        const localGoal = result.form.goal

        setIdAuditoria(result.id)

        const resp = await api.get(`/v1/forms/form-question/${result.form.id}`)
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        lixo.push({
          goal: localGoal,
          questions: aux,
          audit: result.id,
          name: dados.sectors[c].name
        })
      } catch (error) {
        resetConfigs()
      }
    }

    getQuestionsAnswered(lixo) // função que gera os gráficos
    // getQuestionsAnswered(localGoal, aux, result.id, dados.sectorName)
  }

  const handleOptionChange = (sectorId, auditId) => {
    const novoArray = repeatedAudit.map(setor => {
      if (setor.id === sectorId) {
        const novosAudits = setor.audits.map(audit => ({
          ...audit,
          checked: audit.id === auditId
        }))
        return { ...setor, audits: novosAudits }
      }
      return setor
    })

    setRepeatedAudit(novoArray)
  }

  const [mainValue, setMainValue] = useState([])
  const [mainGoal, setMainGoal] = useState([])
  const [mainLabels, setMainLabels] = useState([])

  /**
   * @summary Busca os dados das questões respondidas (nota, peso, id) de uma determindada auditoria e distribuí para as funções que geram os gráficos
   * @param {number} goal Meta do formulário
   * @param {array} questions Lista de questões com os pesos
   * @param {UUID} audit id da auditoria
   */

  async function getQuestionsAnswered(array) {
    setLoadingStatus('Calculando resultados...')

    const configs = []
    for (let c = 0; c < array.length; c++) {
      try {
        const auditID = array[c].audit || idAuditoria
        if (auditID) {
          const response = await api.get(`/v1/audits/answer/${auditID}`)

          const meta = []
          const copy = []
          const object = []
          const numbers = []
          const weights = []
          const values = []
          array[c].questions.forEach(question => {
            response.data.forEach(item => {
              if (question.id === item.question.id) {
                copy.push({ ...question, answer: item.answer })
                numbers.push('Indicador ' + (parseInt(question.identifier) + 1))
              }
            })
          })

          copy.forEach((element, index) => {
            values.push(element.answer)
            weights.push(array[c].questions[index].value)
            object.push({
              note: element.answer,
              weight: array[c].questions[index].value
            })
            meta.push(array[c].goal)
          })

          configs.push({
            element: {
              values: object,
              goal: array[c].goal,
              name: array[c].name
            },
            form: {
              questionsNumber: numbers,
              goals: meta,
              values: values,
              weights: weights
            }
          })
        }
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status !== 404) {
            showErrors(error.response.data.detail)
          }
        }
      }
    }

    UpdateConfigList(configs)
  }
  const [configList, setConfigList] = useState([])
  const [formlist, setFormList] = useState([])
  let vetor = []

  let forms = []

  function UpdateConfigList(array) {
    setLoadingStatus('Montando gráficos...')

    for (let c = 0; c < array.length; c++) {
      array[c].form.sectorName = array[c].element.name
      vetor = [...vetor, array[c].element]
      setConfigList(vetor)

      forms = [...forms, array[c].form]
      setFormList(forms)
    }
    setLoading(false)
  }

  const [areaData, setAreaData] = useState([])

  useEffect(() => {
    const { maingoals, mainlabels, mainvalues, sectorData } =
      sectorsAverageGraph(configList)
    setMainGoal(maingoals)
    setMainLabels(mainlabels)
    setMainValue(mainvalues)

    const resp = areasAverageGraph(sectorData || [])
    setAreaData(resp)
    // setAreaData(resp)
  }, [configList])

  useEffect(() => {
    formsResultGrap(formlist)
  }, [formlist])

  function sectorsAverageGraph(element) {
    const mv = []
    const mg = []
    const ml = []
    const sectorData = []
    element.forEach(sector => {
      const length = sector.values.length
      let result = 0
      let weight = 0
      for (let c = 0; c < length; c++) {
        result += sector.values[c].note * sector.values[c].weight
        weight += sector.values[c].weight
      }
      const calc = result / (weight / 10)
      mv.push(parseFloat(calc.toFixed(2)))
      mg.push(sector.goal)
      ml.push(sector.name)
      sectorData.push({
        value: parseFloat(calc.toFixed(2)),
        goal: sector.goal,
        name: sector.name
      })
    })
    return { mainvalues: mv, maingoals: mg, mainlabels: ml, sectorData }
  }

  function formsResultGrap(parameters) {
    parameters.forEach(parameter => {
      const arr = []
      configs.forEach(config => {
        arr.push(config)
      })
      arr.push({
        notas: {
          series: [
            {
              name: 'Nota Atribuída',
              data: parameter.values
            }
          ],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              stackType: '10'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            title: {
              text: `Setor: ${parameter.sectorName}`
            },
            xaxis: {
              categories: parameter.questionsNumber,
              title: {
                text: 'Indicadores da Auditoria'
              }
            },
            yaxis: [
              {
                title: {
                  // text: atualStores
                  text: 'Notas'
                },
                min: 0,
                max: 10
              }
            ],
            fill: {
              opacity: 1
            },
            tooltip: {}
          }
        },
        pesos: {
          series: parameter.weights,
          options: {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: parameter.questionsNumber,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            ]
          }
        },
        name: parameter.sectorName
      })
      setConfigs(arr)
    })
  }

  function showErrors(errors) {
    if (typeof errors === 'object') {
      errors?.forEach(err => {
        toast.error(err.msg)
      })
    } else {
      toast.error(errors)
    }
  }

  const formatDateTime = dateTime => {
    const date = new Date(dateTime)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    const formattedDateTime = date.toLocaleString('pt-BR', options)
    return formattedDateTime
  }

  const [configs, setConfigs] = useState([])
  function valuesWithColors(values, goals, labels) {
    const aux = []
    values?.forEach((item, index) => {
      aux.push({
        x: labels[index],
        y: item,
        fillColor: item >= goals[index] * 10 ? '#00e396' : '#ba0000'
      })
    })

    return aux
  }

  function formatedGoals(e) {
    const aux = []
    e?.forEach(i => {
      aux.push({
        x: '',
        y: i * 10,
        fill: '#ba0000'
      })
    })
    return aux
  }
  const series = [
    {
      name: 'Nota',
      type: 'column',
      data: valuesWithColors(mainValue, mainGoal, mainLabels)
    },
    {
      name: 'Meta',
      type: 'line',
      data: formatedGoals(mainGoal)
    }
  ]
  const apexOptions = {
    // chart: {
    //   height: 350,
    //   type: 'line'
    // },
    colors: ['#00e396', '#247BA0'],
    stroke: {
      width: [0, 4]
    },
    title: {
      text: 'Média das Auditorias'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },

      style: {
        fontSize: '12px',
        colors: ['#00e396', '#247BA0']
      },
      enabledOnSeries: [0]
    },

    labels: mainLabels,
    yaxis: [
      {
        title: {
          // text: atualStores
          text: 'Notas das Auditorias'
        },
        min: 0,
        max: 100,

        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(2) + '%'
          }
          return y
        }
      }
    },

    xaxis: {
      title: {
        text: 'Setores da Loja'
      }
    }
  }

  const [media, setMedia] = useState()
  const teste1 = mainValue => {
    if (mainValue.length > 0) {
      let aux = 0
      mainValue.forEach(item => {
        aux += item
      })
      const result = aux / mainValue.length
      setMedia(result.toFixed(2))
    }
  }

  const AreaSeries = [
    {
      name: 'Média',
      type: 'column',
      // data: valuesWithColors(mainValue, mainGoal, mainLabels)
      data: areaData
    }
    // {
    //   name: 'Meta',
    //   type: 'line',
    //   data: formatedGoals(mainGoal)
    // }
  ]

  const AreaOptions = {
    colors: ['#00e396', '#247BA0'],
    stroke: {
      width: [0, 4]
    },
    title: {
      text: 'Média dos Setores'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'
      },

      style: {
        fontSize: '12px',
        colors: ['#00e396', '#247BA0']
      },
      enabledOnSeries: [0, 1]
    },

    labels: mainLabels,
    yaxis: [
      {
        title: {
          // text: atualStores
          text: 'Notas das Auditorias'
        },
        min: 0,
        max: 100,

        labels: {
          formatter: function (val) {
            if (val) {
              return val.toFixed(0)
            } else {
              return 0
            }
          }
        }
      }
    ],
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    //   y: {
    //     formatter: function (y) {
    //       if (typeof y !== 'undefined') {
    //         return y.toFixed(2) + '%'
    //       }
    //       return y
    //     }
    //   }
    // },

    xaxis: {
      title: {
        text: 'Áreas da Loja'
      }
    }
  }
  useEffect(() => {
    teste1(mainValue)
  }, [mainValue])
  return (
    <div className={styles.containerRow}>
      <Loading status={loading} label={loadingStatus} />
      <Sidebar opc={3}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Gráfico </h1>
          <div className={styles.containerDash}>
            <div className={styles.containerGrafico}>
              <div className={styles.containerGrafico}>
                <div className={styles.containerGraficoMain}>
                  <Form ref={formRef} onSubmit={confirmData}>
                    <Brands />
                    <Stores />
                    <div className={styles.flex_row_bet}>
                      <div className={(styles.flex_column_bet, form.input_w30)}>
                        <span className={form.title_input}>
                          Selecione o Ano
                        </span>
                        <div className={form.select}>
                          <Select className={form.campo_input} name="year">
                            <option>{atualyear}</option>
                            <option>{atualyear - 1}</option>
                            <option>{atualyear - 2}</option>
                            <option>{atualyear - 3}</option>
                          </Select>
                        </div>
                      </div>
                      <div className={(styles.flex_column_bet, form.input_w30)}>
                        <span className={form.title_input}>
                          Selecione o Semestre
                        </span>
                        <div className={form.select}>
                          <Select className={form.campo_input} name="semester">
                            <option value={'1'}>1° Semestre</option>
                            <option value={'2'}>2° Semestre</option>
                          </Select>
                        </div>
                      </div>
                      <div className={(styles.flex_column_bet, form.input_w30)}>
                        <span className={form.title_input}>
                          Selecione o Status
                        </span>
                        <div className={form.select}>
                          <Select className={form.campo_input} name="status">
                            <option value={false}>Não Consolidadas</option>
                            <option value={true}>Consolidadas</option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className={styles.btn_bunscar}>
                      <button className={form.btn}>Ok</button>
                    </div>
                  </Form>

                  {conflictAudit === true && (
                    <div>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          borderBottom: '1px solid gray',
                          margin: '1rem 0'
                        }}
                      ></div>
                      <p style={{ marginBottom: '10px' }}>
                        Exitem setores com mais de uma auditoria no período
                        selecionado, favor escolher as auditórias, por setor,
                        que devem aparecer nos gráficos.
                      </p>
                      {repeatedAudit.map(
                        object =>
                          object.audits.length > 1 && (
                            <div key={object.id}>
                              <h3 style={{ margin: '10px 0px' }}>
                                {object.sector}
                              </h3>
                              {object.audits.map(element => (
                                <label
                                  key={element.id}
                                  style={{
                                    display: 'block',
                                    marginBottom: '10px'
                                  }}
                                >
                                  <input
                                    type="radio"
                                    value={element.id}
                                    checked={element.checked}
                                    style={{
                                      marginRight: '10px',
                                      backgroundColor: 'red'
                                    }}
                                    onChange={() =>
                                      handleOptionChange(object.id, element.id)
                                    }
                                  />
                                  {element.name !== null
                                    ? element.name
                                    : formatDateTime(element.created_at)}
                                </label>
                              ))}
                            </div>
                          )
                      )}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <button
                          style={{
                            width: '120px',
                            fontSize: '0.8rem',
                            backgroundColor: '#ba0000',
                            height: '40px',
                            color: 'white',
                            padding: '8px 12px',
                            border: 'none',
                            borderRadius: '10px',
                            cursor: 'pointer'
                          }}
                          onClick={handleFilterAudits}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      borderBottom: '1px solid gray',
                      margin: '1rem 0'
                    }}
                  ></div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <div>
                      <p>Concessionária:</p>
                      <h1>{getStoreName(atualStores || null, stores)}</h1>
                    </div>
                    <div>
                      <p>Nota Geral: </p>
                      <h1>{media}</h1>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      borderBottom: '1px solid gray',
                      margin: '1rem 0'
                    }}
                  ></div>
                  <Chart
                    className={styles.svg_auto}
                    options={apexOptions}
                    series={series}
                  ></Chart>

                  <Chart
                    className={styles.svg_auto}
                    options={AreaOptions}
                    series={AreaSeries}
                  ></Chart>
                </div>
                <div className={styles.containerGraficoMultiples}>
                  <h1> Notas e Pesos por Setor</h1>
                  {configs.map((item, index) => {
                    return (
                      <div
                        className={styles.containerGraficoMultiples}
                        key={index}
                      >
                        <div className={styles.campGraficos}>
                          <div className={styles.graficoMulti}>
                            <h2>Nota por Indicador</h2>
                            <Chart
                              className={styles.svg_auto}
                              options={item.notas.options}
                              series={item.notas.series}
                              type="bar"
                            ></Chart>
                          </div>
                          <div className={styles.graficoMulti}>
                            <h2>Peso por Indicador</h2>
                            <Chart
                              className={styles.svg_auto}
                              options={item.pesos.options}
                              series={item.pesos.series}
                              type={'donut'}
                            ></Chart>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertLoading open={alertLoading} />
    </div>
  )
}
