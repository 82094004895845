import React, {
  useEffect,
  useState
  // useRef
} from 'react'
import History from '../../services/history'
import Navbar from '../../components/navbar'
import { FiArrowRight } from 'react-icons/fi'
import Loading from '../../components/alertLoading'
// import Select from '../../components/form/Select'
// import styles from '../../styles/painel.module.css'
// import form from '../../styles/Formulario.module.css'
import { showErrors, clearAudit } from '../../services/functions'
import Footer from '../../components/footer'
import api from '../../services/api'
import { ToastContainer } from 'react-toastify'
// import { Form } from '@unform/web'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import LogoCometa from '../../assets/gp-cometa.svg'
import PropTypes from 'prop-types'
import { saveAudit } from '../../services/auditoria'

function Questionario(props) {
  const [loading, setLoading] = useState(false)

  const [audits, setAudits] = useState([])
  // const [sector, setSector] = useState('')
  const [sectorName, setSectorName] = useState('')
  // const [store, setStore] = useState('')
  // const atualDate = new Date()
  // const atualyear = atualDate.getFullYear()

  // const formRef = useRef(null)

  let routeParameters

  useEffect(() => {
    routeParameters = props?.location?.state
    // setSector(routeParameters.sectorId)
    // setStore(routeParameters.storeId)
    setSectorName(routeParameters.sectorName)
    updateAudits(routeParameters.sectorId, routeParameters.storeId)
  }, [])

  useEffect(() => {}, [])

  async function updateAudits(sectorid, storeId) {
    try {
      // const year = parseInt(formRef.current.getFieldValue('year'))
      // const semester = formRef.current.getFieldValue('semester')

      setLoading(true)
      const response = await api.get(
        `/v1/audits/${storeId}/${sectorid}?is_finished=${false}&is_consolidated=${false}`
      )
      setAudits(response.data)
      setLoading(false)
    } catch (error) {
      // console.error(error.response)
      setLoading(false)
      if (error?.response) {
        showErrors(error.response.data.detail)
      }

      if (error?.response?.status === 404) {
        setAudits([])
      }
    }
  }

  function TableCompanies() {
    return audits.map((item, index) => {
      const created_date = new Date(item.created_at)
      const month = created_date.getMonth()
      const day = String(created_date.getDate()).padStart(2, '0')
      const year = created_date.getFullYear()
      const output = `${day} / ${month < 9 ? '0' : ''}${month + 1} / ${year}`

      return (
        <tr key={index}>
          <td className="grow1 w-30">{item.code}</td>
          <td className="grow1 w-30">{output}</td>

          <td className="grow1 td-opc w-30">
            <div
              className="btn-view"
              onClick={() => {
                saveAudit(item, sectorName)
              }}
            >
              <FiArrowRight />
              <p>Continuar </p>
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="container">
      <Navbar></Navbar>
      <ToastContainer></ToastContainer>
      <div className="page-title">
        <a
          onClick={() => {
            History.push(`${process.env.PUBLIC_URL}/auditoria`)
            clearAudit()
          }}
        >
          <HiArrowNarrowLeft></HiArrowNarrowLeft>
        </a>
        <h2 style={{ fontSize: '1.7rem' }}>Auditorias Pendentes</h2>
      </div>

      <div className="content mg-ledo">
        <div className="aud-group align-center">
          <img src={LogoCometa} className="logoCometa"></img>
        </div>

        {/* <div className="linha"></div> */}
        <br />
        {/* <Form style={{ width: '95%', margin: 'auto' }} ref={formRef}>
          <div className={styles.flex_row_bet}>
            <div className={(styles.flex_column_bet, form.input_w50)}>
              <span className={form.title_input}>Selecione o Ano: </span>
              <div className={form.select}>
                <Select
                  className={form.campo_input}
                  name="year"
                  onChange={() => {
                    updateAudits(sector, store)
                  }}
                >
                  <option>{atualyear}</option>
                  <option>{atualyear - 1}</option>
                  <option>{atualyear - 2}</option>
                  <option>{atualyear - 3}</option>
                </Select>
              </div>
            </div>
            <div className={(styles.flex_column_bet, form.input_w50)}>
              <span className={form.title_input}>Selecione o Semestre: </span>
              <div className={form.select}>
                <Select
                  className={form.campo_input}
                  onChange={() => {
                    updateAudits(sector, store)
                  }}
                  name="semester"
                >
                  <>
                    <option value={'1'}>1° Semestre</option>
                    <option value={'2'}>2° Semestre</option>
                  </>
                </Select>
              </div>
            </div>
          </div>
        </Form> */}

        <br />
        <table className="search-table table-pending">
          <thead>
            <tr className="title-table">
              <th className="grow3 w-30">Código</th>
              <th className="grow3 w-30">Data de Criação</th>
              <th className="grow2 w-30">Opções</th>
            </tr>
          </thead>
          <tbody>
            <TableCompanies></TableCompanies>
          </tbody>
        </table>
      </div>

      <Footer></Footer>
      <Loading open={loading} />
    </div>
  )
}
Questionario.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object
}
export default Questionario
