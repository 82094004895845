import React, { useEffect, useState } from 'react'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'
import form from '../../../../styles/Formulario.module.css'
import { MdAddBox, MdIndeterminateCheckBox } from 'react-icons/md'
import { Form } from '@unform/web'
import Loading from '../../../../components/alertLoading'
import Select from '../../../../components/form/Select'
import api from '../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import History from '../../../../services/history'

function NovoFormulario() {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const [brands, setBrands] = useState([])
  const [stores, setStores] = useState([])
  const [sectors, setSectors] = useState([])
  const [weights, setWeights] = useState([])

  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setLoading(false)
      setStores(response?.data)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors(s) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setLoading(false)
      setSectors(response?.data)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }
  useEffect(() => {
    init()
  }, [])

  function changeWeight(value, position) {
    const newList = []
    weights.forEach((valor, index) => {
      if (index === position) {
        newList[position] = value
      } else {
        newList[index] = valor
      }
    })
    setWeights(newList)
  }

  const [questions, setQuestions] = useState([])
  const [utterance, setUtterance] = useState('')
  const [weight, setWeight] = useState('')

  async function formSubmit(data) {
    if (questions.length > 0) {
      if (utterance === '') {
        try {
          const questionIdList = []
          questions.forEach(element => {
            questionIdList.push(element.id)
          })

          const parameters = {
            form_in: {
              sector_id: data.sector,
              goal: data.goal
            },
            storeList: [data.store],
            questionList: questionIdList,
            valueList: weights
          }

          setLoading(true)
          await api.post('/v1/forms/', parameters)

          setLoading(false)
          toast.success('Formulário cadastrado com sucesso!!')
          History.push(`${process.env.PUBLIC_URL}/painel/cadastro`)
        } catch (err) {
          setLoading(false)
          toast.error('Falha ao cadastrar formulário')
        }
      } else {
        setLoading(false)
        toast.warn('A ultima questão não foi Salva')
      }
    } else {
      setLoading(false)
      toast.error('Não é possivel cadastrar um formulario sem perguntas')
    }
  }
  async function sendQuestion() {
    try {
      if (utterance === '') {
        toast.error('Preencha o enunciado da questão')
      } else {
        if (weight > 3 || weight < 0) {
          toast.error('O peso deve ser menor ou igual a 3')
        } else {
          const parameters = {
            enunciation: utterance
          }

          setLoading(true)
          const response = await api.post('/v1/forms/question', parameters)
          setLoading(false)
          const aux = []
          questions.forEach(element => {
            aux.push(element)
          })
          aux.push(response.data)

          setQuestions(aux)

          const aux2 = []
          weights.forEach(element => {
            aux2.push(element)
          })
          aux2.push(weight)
          setWeights(aux2)
          setUtterance('')
          setWeight(0)
        }
      }
    } catch (err) {
      setLoading(false)
      toast.error('Não foi possivel adicionar a pergunta')
    }
  }

  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form onSubmit={formSubmit} className={styles.content}>
          <h1 className={styles.title_main}>Editar </h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca</strong>
                </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="brand"
                    onClick={element => {
                      if (element.target.value !== 'clear') {
                        setStores([])
                        setSectors([])
                        updateStores(element.target.value)
                      } else {
                        setStores([])
                      }
                    }}
                    id="marca"
                  >
                    <option value={'clear'}>Selecione...</option>
                    {brands.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja</strong>
                </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="store"
                    onClick={e => {
                      if (e.target.value !== 'clear') {
                        updateSectors(e.target.value)
                      } else {
                        setSectors([])
                      }
                    }}
                    id="loja"
                  >
                    <option value={'clear'}>Selecione...</option>
                    {stores.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor</strong>
                </span>
                <div className={form.select}>
                  <Select className={form.campo_input} name="sector" id="setor">
                    <option value={'clear'}>Selecione...</option>
                    {sectors.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={form.containerCad}>
            {!isNew && (
              <>
                <h1 className={form.titleForm}>Questionario</h1>
                <div className={styles.flex_row_bet}>
                  <div className={form.btn_form}>
                    <button
                      className={form.btn}
                      onClick={() => {
                        setIsNew(true)
                      }}
                    >
                      Novo Questionario
                    </button>
                  </div>
                </div>
              </>
            )}
            {questions.map((question, index) => {
              return (
                <label key={index}>
                  <div className={styles.flex_row_bet}>
                    <div className={(styles.flex_column_bet, form.input_w90)}>
                      <span className={form.title_input}>
                        <strong>Pergunta {index + 1} </strong>
                      </span>
                      <div>
                        <textarea
                          className={form.campo_input}
                          disabled
                          rows="3"
                          value={question.enunciation}
                        ></textarea>
                      </div>
                      <div className={(styles.flex_column_bet, form.input_w50)}>
                        <span className={form.title_input}>
                          <strong>Peso</strong>
                        </span>
                        <div className="d-flex1">
                          <input
                            className={form.campo_input}
                            type="range"
                            step=".1"
                            min="0"
                            max="3"
                            value={weights[index]}
                            onChange={e => {
                              changeWeight(e.target.value, index)
                            }}
                          />
                          <p>{weights[index]}</p>
                        </div>
                      </div>
                    </div>
                    <div className={form.input_w10}>
                      <span>a</span>
                      <a>
                        <MdIndeterminateCheckBox></MdIndeterminateCheckBox>
                      </a>
                    </div>
                  </div>
                </label>
              )
            })}
            {isNew && (
              <>
                <div className={styles.flex_row_bet}>
                  <div className={(styles.flex_column_bet, form.input_w90)}>
                    {/* adicionar aqui */}

                    <span className={form.title_input}>
                      <strong>Pergunta</strong>
                    </span>
                    <div className={form.select}>
                      <textarea
                        className={form.campo_input}
                        name=""
                        value={utterance}
                        minLength="20"
                        onChange={e => {
                          setUtterance(e.target.value)
                        }}
                        rows="3"
                      ></textarea>
                    </div>
                    <div className={(styles.flex_column_bet, form.input_w50)}>
                      <span className={form.title_input}>
                        <strong>Peso</strong>
                      </span>
                      <div className="d-flex1">
                        <input
                          className={form.campo_input}
                          type="range"
                          step=".1"
                          min="0"
                          max="3"
                          onChange={e => {
                            setWeight(e.target.value)
                          }}
                          value={weight}
                        />
                        <p>{weight}</p>
                      </div>
                    </div>
                  </div>
                  <div className={form.input_w10}>
                    <span>a</span>
                    <a>
                      <MdAddBox onClick={sendQuestion}></MdAddBox>
                    </a>
                  </div>
                </div>
                <div className={form.btn_cadastro}>
                  <button className={form.btn}>Cadastrar</button>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
      <Loading open={loading} />
    </div>
  )
}

export default NovoFormulario
