import React from 'react'
import Proptypes from 'prop-types'
import styles from '../../styles/questionario.module.css'

function PreviewBox(props) {
  const questionNumber = props.number
  const questionNote = props.note
  const questionExcerpt = props.excerpt
  const questionWeight = props.weight
  return (
    <div className="question-item box-light">
      <div className={styles.question_tittle}>
        <div className="question-number">Indicador {questionNumber}</div>
      </div>
      <div className="question-excerpt">
        <p>{questionExcerpt}</p>
      </div>
      <div className={styles.preview_footer}>
        <div>
          <label>Peso: </label>
          <p>{questionWeight}</p>
        </div>
        <div>
          <label>Nota: </label>
          <p>{questionNote}</p>
        </div>
      </div>
    </div>
  )
}
PreviewBox.propTypes = {
  number: Proptypes.number,
  note: Proptypes.number,
  excerpt: Proptypes.string,
  weight: Proptypes.number
}
export default PreviewBox
