import React from 'react'
import { Form } from '@unform/web'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'

import { FiEye } from 'react-icons/fi'
import { MdArrowBack, MdOutlineCreate, MdDelete } from 'react-icons/md'

import List from '../../../../components/List'
import Loading from '../../../../components/alertLoading'
import NavbarPainel from '../../../../components/navbarPainel'
import Select from '../../../../components/form/Select'
import PainelComment from '../../../../components/painelComment'
import PainelEditAudit from '../../../../components/painel-edit-audit'
import PreviewAudit from '../../../../components/preview-audit'
import Sidebar from '../../../../components/sidebar'
import api from '../../../../services/api'
import { getSectorName, sortIdentifiers } from '../../../../services/auditoria'
import { showErrors } from '../../../../services/functions'
import saveAudit from '../../../../services/auditoria/saveAudit'
import form from '../../../../styles/Formulario.module.css'
import styles from '../../../../styles/painel.module.css'

const viewAuditV2 = () => {
  const formRef = React.useRef(null)
  const [abstract, setAbstract] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [audits, setAudits] = React.useState([])
  const [brands, setBrands] = React.useState([])
  const [brand, setBrand] = React.useState('')
  const [currentAudit, setCurrentAudit] = React.useState()
  const [currentEditingQuestion, setCurrentEditingQuestion] = React.useState()
  const [filter, setFilter] = React.useState(true)
  const [data, setData] = React.useState([])
  const [filteredData, setFilteredData] = React.useState([])
  const [stores, setStores] = React.useState([])
  const [store, setStore] = React.useState('')
  const [sectors, setSectors] = React.useState([])
  const [sector, setSector] = React.useState('')
  const [year, setYear] = React.useState('')
  const [semester, setSemester] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [consolidated, setConsolidated] = React.useState('')
  const [comment, setComment] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [questionsanswered, setQuestionsAnswered] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [isViewAudit, setIsViewAudit] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [finished, setFinished] = React.useState(true)
  const [media, setMedia] = React.useState(0)

  const editStyle = {
    margin: '0.1rem',
    padding: '0.6rem 0.3rem',
    marginRight: '-30.2px',
    borderRadius: '1.5rem',
    boxShadow: '1px 1px 3px grey',
    cursor: 'pointer'
  }

  const column = {
    backgroundColor: '#ba0000',
    color: 'white'
  }

  const row = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    textAlign: 'center',
    padding: '0px 2px'
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const loadAudits = async () => {
    try {
      setLoading(true)
      const response = await api.get('/v1/filters/brand', {
        params: {
          atributo: 'id',
          value: brand
        }
      })

      setData(response.data[0])
      setStores(response.data[0].store)
      filterAudits(response.data[0])
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  async function consolidateAudit(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/consolidate/${auditId}`)
      setLoading(false)
      Swal.fire('Auditoria Consolidada!', '', 'success').then(() => {
        setIsViewAudit(false)
        loadAudits()
        // window.location.reload()
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const getComments = async auditId => {
    try {
      setLoading(true)
      const resp = await api.get(
        `/v1/audits/comment/${auditId || currentAudit.id}`
      )
      setLoading(false)
      return resp.data.reverse()
    } catch (e) {
      setLoading(false)
      console.error(e)
      return []
    }
  }

  async function getQuestionsAnswered(auditId) {
    try {
      setLoading(true)
      const resp = await api.get(`/v1/audits/answer/${auditId}`)
      setQuestionsAnswered(resp.data)
    } catch (error) {
      setLoading(false)

      if (error.response !== undefined) {
        if (error.response.status !== 404) {
          showErrors(error.response.data.detail)
        }
      }
    }
  }

  async function getAuditResult(auditId, formId) {
    setComment(false)
    setComments([])
    setQuestionsAnswered([])
    setQuestions([])

    try {
      setLoading(true)
      await api.get(`/v1/audits/answer/${auditId}`)
      setLoading(false)
      getQuestions(formId)
      getQuestionsAnswered(auditId)
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.detail) {
        showErrors(error.response.data.detail)
      }
    }
  }

  async function getQuestions(formid) {
    try {
      setLoading(true)
      const resp = await api.get(`/v1/forms/form-question/${formid}`)
      setLoading(false)
      setQuestions(resp.data)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleComments = async newComment => {
    try {
      setLoading(true)
      await api.post(`/v1/audits/comment/${currentAudit.id}`, newComment)
      setLoading(false)
      setComments(await getComments())
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  function endForm() {
    const copy = []
    questionsanswered.forEach(item => {
      questions.forEach(question => {
        if (question.id === item.question.id) {
          copy.push({ ...question, answer: item.answer, note: item.note })
        }
      })
    })

    sortIdentifiers(copy)
    setAbstract(copy)

    if (copy.length > 0) {
      let tempMedia = 0
      let tempPeso = 0

      for (let i = 0; i < copy.length; i++) {
        tempMedia += copy[i].answer * copy[i].value
        tempPeso += copy[i].value
      }

      setMedia((tempMedia * 10) / tempPeso)
    }
  }

  async function enableAudit(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/${auditId}`)
      setLoading(false)
      Swal.fire(
        'A auditoria foi habilitada para a edição!',
        '',
        'success'
      ).then(() => {
        setIsViewAudit(false)
        loadAudits()
        // window.location.reload()
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const parentTest = () => {
    setEditing(!editing)
    getAuditResult(currentAudit.id, currentAudit.form.id)
  }

  const configureName = async ({
    name,
    representatives,
    chief_auditor,
    target
  }) => {
    try {
      let params = {}
      if (name) {
        params = { ...params, name: name }
      }
      if (representatives) {
        params = { ...params, representatives }
      }
      if (chief_auditor) {
        params = { ...params, chief_auditor }
      }

      setLoading(true)
      const response = await api.put(`/v1/audits/${currentAudit.id}`, params)
      if (response.status === 200) {
        const resp = await api.get(`/v1/audits/${currentAudit.id}`)
        setLoading(false)
        if (resp.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `${target} da auditoria alterado com sucesso!!!`,
            showConfirmButton: false,
            timer: 1500
          })
          setCurrentAudit(resp.data)
        }
      }
    } catch (err) {
      setLoading(false)
    }
  }

  async function removeAudit(auditId) {
    try {
      setLoading(true)
      await api.delete(`/v1/audits/${auditId}`)
      setLoading(false)
      Swal.fire('A auditoria foi excluída com sucesso!!!', '', 'success')
      setIsViewAudit(false)
      loadAudits()
      // window.location.reload()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  async function loadComentarios() {
    setComments(await getComments())
  }

  function ModalCad() {
    return (
      <>
        <div className={form.modal}>
          <div
            className={form.bg_modal}
            onClick={() => {
              // setIsViewAudit(false)
            }}
          ></div>
          <PainelComment
            comments={comments}
            addComment={true}
            removeComment={true}
            open={comment}
            close={() => setComment(false)}
            handleComment={event => handleComments(event)}
            onSetComment={async () => setComments(await getComments())}
          />
          <div
            style={{ marginRight: comment ? '30%' : 'auto' }}
            className={form.containerCadAuditoria}
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 style={{ marginBottom: '0' }} className={form.titleForm}>
                    Dados da Auditoria:
                  </h1>
                  <h2 style={{ marginLeft: '0.75rem', height: 'max-content' }}>
                    {currentAudit.name === null ? '' : currentAudit.name}
                  </h2>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      border: '2px solid #ab1414c5',
                      padding: '5px',
                      borderRadius: '10px',
                      marginRight: '20px'
                    }}
                  >
                    <p style={{ color: '#ab1414c5' }}>
                      <spam style={{ fontWeight: 'bold' }}>NOTA:</spam>{' '}
                      {media.toFixed(2)}
                    </p>
                  </div>
                  <button
                    style={{ width: 'max-content', height: 'max-content' }}
                    className={form.btn}
                    onClick={() => {
                      Swal.fire({
                        title: 'Editar nome da auditoria',
                        input: 'text',
                        inputPlaceholder: 'Nome da Auditoria',
                        inputAttributes: {
                          autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        showLoaderOnConfirm: true,
                        preConfirm: name => {
                          if (name.length > 15) {
                            Swal.fire({
                              icon: 'error',
                              title: 'Limite de caracteres ultrapassado!!!',
                              showConfirmButton: false,
                              timer: 1500
                            })
                          } else {
                            configureName({ name: name, target: 'Nome' })
                          }
                        }
                      })
                    }}
                  >
                    Editar Nome
                  </button>
                </div>
              </div>
              <div className={styles.flex_row_bet}>
                <div className={(styles.flex_column_bet, form.input_w100)}>
                  {!editing && (
                    <div className={form.btn_cadastro_view_audit}>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={() => {
                          Swal.fire({
                            title: 'Editar Auditor Líder',
                            input: 'text',
                            inputPlaceholder: 'Nome do Auditor Líder',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirmar',
                            showLoaderOnConfirm: true,
                            preConfirm: text => {
                              if (text.length > 255) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Limite de caracteres ultrapassado!!!',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              } else {
                                configureName({
                                  chief_auditor: text,
                                  target: 'Auditor Líder'
                                })
                              }
                            }
                          })
                        }}
                      >
                        Editar Auditor Lider
                      </button>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={() => {
                          Swal.fire({
                            title: 'Editar Representante',
                            input: 'text',
                            inputPlaceholder: 'Representante da auditoria',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirmar',
                            showLoaderOnConfirm: true,
                            preConfirm: text => {
                              if (text.length > 255) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Limite de caracteres ultrapassado!!!',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              } else {
                                configureName({
                                  representatives: text,
                                  target: 'Representante'
                                })
                              }
                            }
                          })
                        }}
                      >
                        Editar Representante
                      </button>
                      <button
                        style={{ width: 'max-content', height: 'max-content' }}
                        className={form.btn}
                        onClick={() => {
                          loadComentarios()
                          setComment(true)
                        }}
                      >
                        Abrir comentários
                      </button>
                    </div>
                  )}

                  {abstract.map((item, index) => {
                    return (
                      <div key={index}>
                        {!editing && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <PreviewAudit
                              identifier={item.identifier}
                              number={item.number}
                              note={item.answer}
                              excerpt={item.enunciation}
                              weight={questions[index]?.value}
                              comment={item.note || ''}
                            />
                            <p
                              style={editStyle}
                              onClick={() => {
                                setEditing(true)
                                setCurrentEditingQuestion(item)
                              }}
                            >
                              <MdOutlineCreate
                                style={{
                                  fill: '#ba0000',
                                  width: 28,
                                  height: 28
                                }}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  })}
                  {editing && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PainelEditAudit
                        questionId={currentEditingQuestion.id}
                        auditId={currentAudit.id}
                        parentCallback={parentTest}
                        identifier={currentEditingQuestion.identifier}
                        number={currentEditingQuestion.number}
                        note={currentEditingQuestion.answer}
                        excerpt={currentEditingQuestion.enunciation}
                        weight={questions[currentEditingQuestion]?.value}
                        comment={currentEditingQuestion.note || ''}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!editing && (
                <div className={form.btn_cadastro_view_audit}>
                  {!consolidated && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        Swal.fire({
                          title:
                            'Deseja habilitar esta auditoria para a edição?',
                          showDenyButton: false,
                          showCancelButton: true,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Sim',
                          denyButtonText: `Don't save`
                        }).then(result => {
                          if (result.isConfirmed) {
                            enableAudit(currentAudit.id)
                          } else if (result.isDenied) {
                            setIsViewAudit(false)
                          }
                        })
                      }}
                    >
                      Habilitar edição
                    </button>
                  )}

                  {!consolidated && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        Swal.fire({
                          title: 'Deseja consolidar esta auditoria?',
                          showDenyButton: false,
                          showCancelButton: true,
                          html: `<input class="${form.campo_input}" >`,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Sim',
                          denyButtonText: `Don't save`
                        }).then(result => {
                          if (result.isConfirmed) {
                            consolidateAudit(currentAudit.id)
                          } else if (result.isDenied) {
                            setIsViewAudit(false)
                          }
                        })
                      }}
                    >
                      Consolidar
                    </button>
                  )}
                  {!finished && (
                    <button
                      className={form.btn}
                      onClick={() => {
                        saveAudit(
                          currentAudit,
                          getSectorName(
                            formRef.current.getFieldValue('sector'),
                            sectors
                          )
                        )
                      }}
                    >
                      Continuar auditoria
                    </button>
                  )}
                  <button
                    className={form.btn}
                    onClick={() => {
                      setIsViewAudit(false)
                    }}
                  >
                    Voltar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  async function unconsolidated(auditId) {
    try {
      setLoading(true)
      await api.patch(`/v1/audits/unconsolidated/${auditId}`)
      setLoading(false)
      Swal.fire('Auditoria Desconsolidada!', '', 'success').then(() => {
        setIsViewAudit(false)
        loadAudits()
        // window.location.reload()
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const filterBrand = () => {
    return brands.find(e => e.id === brand).name
  }

  const filterAudits = data => {
    let tempAudits = []

    for (let i = 0; i < data.store.length; i++) {
      tempAudits = [...tempAudits, ...data.store[i].audit]
    }

    setAudits(tempAudits)
    setFilteredData(tempAudits)
  }

  const requisitionFilterAudits = () => {
    if (filter === true) {
      if (brand !== '') {
        loadAudits()
        setFilter(false)
      }
    } else {
      let aux = audits

      if (store !== '') {
        const indexStore = data.store.findIndex(obj => obj.name === store)
        const tempAudit = data.store[indexStore].audit
        const tempFilteredAudits = aux.filter(obj1 =>
          tempAudit.some(obj2 => obj1.id === obj2.id)
        )

        aux = tempFilteredAudits
      }

      if (sector !== '') {
        aux = aux.filter(e => e.form.sector.name === sector)
      }

      if (year !== '') {
        aux = aux.filter(
          e => filterYear(e.created_at).toString() === year.toString()
        )
      }

      if (semester !== '') {
        aux = aux.filter(e => filterSemester(e.created_at) === semester)
      }

      if (status !== '') {
        setFinished(status === 'true')
        if (status === 'true') {
          aux = aux.filter(e => e.finished_at !== null)
        } else {
          aux = aux.filter(e => e.finished_at === null)
        }
      }

      if (consolidated !== '') {
        if (consolidated === 'true') {
          aux = aux.filter(e => e.consolidated_at !== null)
        } else {
          aux = aux.filter(e => e.consolidated_at === null)
        }
      }

      setFilteredData(aux)
    }
  }

  const filterSectors = () => {
    let tempdata = stores.find(e => e.name === store).audit
    const tempSectors = []

    for (let i = 0; i < tempdata.length; i++) {
      tempSectors.push(tempdata[i].form.sector)
    }

    tempdata = tempSectors.filter((object, index) => {
      return index === tempSectors.findIndex(e => e.id === object.id)
    })

    return tempdata
  }

  const maskDate = date => {
    const data = new Date(date)

    const dia = String(data.getDate()).padStart(2, '0')
    const mes = String(data.getMonth() + 1).padStart(2, '0')
    const ano = String(data.getFullYear())

    return `${dia}/${mes}/${ano}`
  }

  const findStore = id => {
    const indexStore = data.store.findIndex(item => {
      return item.audit.some(audit => audit.id === id)
    })

    return data.store[indexStore].name
  }

  const filterSemester = date => {
    const data = new Date(date)
    const mes = data.getMonth()

    if (mes >= 0 && mes <= 5) {
      return '1º Semestre'
    } else if (mes >= 6 && mes <= 11) {
      return '2º Semestre'
    } else {
      console.log('A data não pertence a nenhum semestre.')
    }
  }

  const filterYear = date => {
    const data = new Date(date)
    const year = data.getFullYear()

    return year
  }

  React.useEffect(() => {
    if (store !== '') {
      setSectors(filterSectors())
    }
  }, [store])

  React.useEffect(() => {
    loadData()
  }, [])

  React.useEffect(() => {
    endForm()
  }, [questionsanswered, questions])

  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form ref={formRef} className={styles.content}>
          <h1 className={styles.title_main}>Visualizar </h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca: </strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="brand"
                    onChange={e => {
                      setBrand(e.target.value)
                    }}
                    array={brands}
                    id="marca"
                    label="Selecione..."
                  ></List>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja: </strong>
                </span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    name="store"
                    onChange={e => setStore(e.target.value)}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    {stores.map(e => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor: </strong>
                </span>
                <div className={form.select}>
                  <Select
                    disabled={store === ''}
                    className={form.campo_input}
                    name="sector"
                    onChange={e => setSector(e.target.value)}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    {sectors.map(e => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Ano: </span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    name="year"
                    onChange={e => setYear(e.target.value)}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    <option>{new Date().getFullYear()}</option>
                    <option>{new Date().getFullYear() - 1}</option>
                    <option>{new Date().getFullYear() - 2}</option>
                    <option>{new Date().getFullYear() - 3}</option>
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Semestre: </span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    onChange={e => setSemester(e.target.value)}
                    name="semester"
                  >
                    <>
                      <option value={''}>{'Selecione...'}</option>
                      <option value={'1º Semestre'}>{'1º Semestre'}</option>
                      <option value={'2º Semestre'}>{'2º Semestre'}</option>
                    </>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Status: </span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    onChange={e => {
                      setStatus(e.target.value)
                    }}
                    name="status"
                  >
                    <option value={''}>{'Selecione...'}</option>
                    <option value={'false'}>{'Pendentes'}</option>
                    <option value={'true'}>{'Finalizadas'}</option>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione a Situação:</span>
                <div className={form.select}>
                  <Select
                    disabled={
                      filter
                      // !finished
                    }
                    className={form.campo_input}
                    onChange={e => {
                      setConsolidated(e.target.value)
                    }}
                    name="situation"
                  >
                    <option value={''}>{'Selecione...'}</option>
                    <option value={'false'}>{'Não Consolidadas'}</option>
                    <option value={'true'}>{'Consolidadas'}</option>
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.btn_bunscar}>
              <button
                className={form.btn}
                type="button"
                onClick={() => {
                  requisitionFilterAudits()
                  // updateAudits(formRef.current.getFieldValue('sector'))
                }}
              >
                Ok
              </button>
            </div>
            {filteredData.length > 0 && (
              <div style={{ overflowX: 'scroll' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={column}>Código</th>
                      <th style={column}>Criação</th>
                      <th style={column}>Marca</th>
                      <th style={column}>Loja</th>
                      <th style={column}>Setor</th>
                      <th style={column}>Semestre</th>
                      <th style={column}>Ano</th>
                      <th style={column}>Status</th>
                      <th style={column}>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td style={row}>{item.code}</td>
                        <td style={row}>{maskDate(item.created_at)}</td>
                        <td style={row}>{filterBrand()}</td>
                        <td style={row}>{findStore(item.id)}</td>
                        <td style={row}>{item.form.sector.name}</td>
                        <td style={row}>{filterSemester(item.created_at)}</td>
                        <td style={row}>{filterYear(item.created_at)}</td>
                        <td style={row}>
                          {item.finished_at === null
                            ? 'Pendente'
                            : 'Finalizada'}
                        </td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'black',
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '2px'
                          }}
                        >
                          <div
                            className="btn-view"
                            onClick={async () => {
                              getAuditResult(item.id, item.form.id)
                              setIsViewAudit(true)
                              setCurrentAudit(item)
                              setComments(await getComments(item.id))
                            }}
                          >
                            <FiEye />
                            <p style={{ fontSize: '12px' }}>Visualizar</p>
                          </div>
                          {item.consolidated_at === null ? (
                            <div
                              className="btn-delete"
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    'Deseja realmente excluir esta auditoria?',
                                  text: 'Todos os dados ligados a esta auditoria serão perdidos e a operação não poderá ser desfeita.',
                                  showCancelButton: true,
                                  cancelButtonText: 'Cancelar',
                                  confirmButtonText: 'Sim, Excluir'
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    removeAudit(item.id)
                                  }
                                })
                              }}
                            >
                              <MdDelete />
                              <p style={{ fontSize: '12px' }}>Deletar</p>
                            </div>
                          ) : (
                            <div
                              className="btn-delete"
                              onClick={() => {
                                Swal.fire({
                                  title: 'Deseja desconsolidar esta auditoria?',
                                  showDenyButton: false,
                                  showCancelButton: true,
                                  cancelButtonText: 'Cancelar',
                                  confirmButtonText: 'Sim',
                                  denyButtonText: `Don't save`
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    unconsolidated(item.id)
                                  } else if (result.isDenied) {
                                    setIsViewAudit(false)
                                  }
                                })
                              }}
                            >
                              <MdArrowBack />
                              <p style={{ fontSize: '12px' }}>Desconsolidar</p>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Form>
        <Loading open={loading} />
      </div>
      {isViewAudit && <ModalCad />}
    </div>
  )
}

export default viewAuditV2
