import React from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import api from '../../services/api'
import { Form } from '@unform/web'
import Loading from '../alertLoading'
import Input from '../../components/form/input'
import { ModalsContext } from '../../providers/modals'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import {
  removeSpacesFromBeginning,
  removeSpacesFromBeginningAndEnd
} from '../../services/functions'

export default function NovaMarca() {
  const [loading, setLoading] = React.useState(false)
  const { setOpenMarca } = React.useContext(ModalsContext)
  async function brandSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!')
      })
      await schema.validate(data, {
        abortEarly: false
      })

      const bodyParameters = {
        name: data.name
      }
      setLoading(true)
      await api.post('/v1/stores/brand', bodyParameters)
      setLoading(false)
      setOpenMarca(false)
      toast.success('Marca cadastrada com sucesso!')
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }
  return (
    <div className={form.containerCad}>
      <Form onSubmit={brandSubmit}>
        <h1 className={form.titleForm}>
          Cadastrar nova <strong> Marca</strong>
        </h1>
        <div className={styles.flex_row_bet}>
          <div className={(styles.flex_column_bet, form.input_w100)}>
            <span className={form.title_input}>Nome</span>
            <Input
              className={form.campo_input}
              type="text"
              onChange={e => {
                e.target.value = removeSpacesFromBeginning(
                  e.target.value,
                  e.target
                )
              }}
              onBlur={e => {
                e.target.value = removeSpacesFromBeginningAndEnd(e.target.value)
              }}
              name="name"
              id=""
            />
          </div>
        </div>
        <Loading open={loading} />
        <div className={form.btn_cadastro}>
          <button className={form.btn}>Cadastrar</button>
        </div>
      </Form>
    </div>
  )
}
