export const divContainer = {
  backgroundColor: 'white',
  width: '100%', // ou '100%' se você quiser que ele ocupe todo o espaço disponível até 800px
  maxWidth: '800px',
  height: 'auto',
  margin: '0px auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 3
}

export const container = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const header = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: 'solid 2.5px rgb(124, 124, 124)',
  margin: '0 auto'
}

export const row = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 5px',
  borderBottom: 'solid 1px rgb(160, 160, 160)'
}

export const grow2R = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 2,
  padding: '5px',
  maxWidth: '50%'
}

export const grow1R = {
  display: 'flex',
  alignItems: 'flex-start',
  flexGrow: 1,
  padding: '5px',
  maxWidth: '50%'
}

export const font1 = {
  fontWeight: 'bold',
  marginRight: '0.3rem'
}

export const font2 = {
  fontWeight: 'lighter',
  color: 'rgb(100, 100, 100)',
  marginRight: '0.3rem'
}

export const font3 = {
  fontWeight: 'normal'
}

export const assinaturas = {
  flexWrap: 'wrap',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '30px 0'
}

export const assina = {
  width: '43%',
  margin: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export const linha = {
  width: '100%',
  padding: '10px 0',
  borderBottom: '1px solid black'
}

export const boxLogo = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}

export const img = {
  width: '5.5vw',
  height: '5.5vw'
}

export const resumo = {
  margin: '20px 0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}
