import History from '../../history'

const saveAudit = (auditoria, sector_name) => {
  localStorage.setItem('auditoria@code_auditoria', auditoria.code)
  localStorage.setItem('auditoria@id_auditoria', auditoria.id)
  localStorage.setItem('auditoria@form_id', auditoria.form.id)
  // localStorage.setItem('auditoria@form_id', auditoria.form.id)

  localStorage.setItem('auditoria@audit_date', auditoria.created_at)
  localStorage.setItem(
    'auditoria@painel_return_to',
    '/painel/visualizar/auditoria'
  )
  History.push({
    pathname: `${process.env.PUBLIC_URL}/questionario`,
    state: {
      sectorName: sector_name
    }
  })
}

export default saveAudit
