/**
 * @param {string} date
 */
const formatDate = date => {
  const created_date = new Date(date)
  const month = created_date.getMonth()
  const day = String(created_date.getDate()).padStart(2, '0')
  const year = created_date.getFullYear()
  const output = `${day}/${month < 9 ? '0' : ''}${month + 1}/${year}`
  return output
}

export default formatDate
