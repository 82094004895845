/**
 * @param {string} date
 */
const getDateYear = date => {
  const created_date = new Date(date)
  const year = created_date.getFullYear()
  return year
}

export default getDateYear
