import { Form } from '@unform/web'
import React, { useEffect, useRef, useState, useContext } from 'react'
import Loading from '../../components/alertLoading'
import Select from '../../components/form/Select'
import Navbar from '../../components/navbar'
import History from '../../services/history'
import { toast, ToastContainer } from 'react-toastify'
import Footer from '../../components/footer'
import List from '../../components/List'
import {
  HiDocumentAdd,
  HiDocumentText,
  HiDocument,
  HiArrowNarrowLeft
} from 'react-icons/hi'
import { clearAudit } from '../../services/functions'
import { ImCheckmark } from 'react-icons/im'

import LogoCometa from '../../assets/Imagem1.png'
import './style.css'

import { FaUser } from 'react-icons/fa'

import api from '../../services/api'
import { AuthContext } from '../../providers/auth'
import { getNameById } from '../../services/estabelecimentos'

function Auditoria() {
  const [loading, setLoading] = useState(true)

  const { username } = useContext(AuthContext)
  const formRef = useRef(null)
  useEffect(() => {
    init()
  }, [])

  const [brands, setBrands] = useState([])
  async function init() {
    if (localStorage.getItem('auditoria@painel_return_to')) {
      localStorage.removeItem('auditoria@painel_return_to')
    }
    try {
      setLoading(true)
      const resp = await api.get('/v1/stores/brand')
      setBrands(resp.data)
      setLoading(false)
    } catch (err) {
      toast.error(err?.response)
      setLoading(false)
    }
  }
  const [atualBrand, setAtualBrand] = useState('')

  const [stores, setStores] = useState([])
  const [atualStores, setAtualStores] = useState('')
  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setStores(response?.data)
      formRef.current.setFieldValue('brand', b)
      setLoading(false)
    } catch (err) {
      toast.error(err?.response)
      setLoading(false)
    }
  }

  const [sectors, setSectors] = useState([])
  // const [hasPending, setHasPendent] = useState(null)
  const verificaPendentes = async sectorid => {
    try {
      setLoading(true)
      const response = await api.get(
        `/v1/audits/${formRef.current.getFieldValue(
          'stores'
        )}/${sectorid}?&is_finished=${false}`
      )
      setLoading(false)
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    } catch (except) {
      setLoading(false)
      return false
    }
  }

  async function updateSectors(s) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setSectors(response?.data)
      setLoading(false)
      formRef.current.setFieldValue('stores', s)
    } catch (err) {
      toast.error(err?.response)
      setLoading(false)
    }
  }

  function confirmData() {
    let result = false
    if (formRef.current.getFieldValue('brand') === 'Marca') {
      toast.info('Selecione uma Marca!')
    } else if (formRef.current.getFieldValue('stores') === 'Loja') {
      toast.info('Selecione uma Loja!')
    } else if (formRef.current.getFieldValue('sectors') === 'Setor') {
      toast.info('Selecione um Setor')
    } else {
      result = true
    }
    return result
  }

  const sendPendingAuditToStorage = () => {
    const dataConfirmed = confirmData()
    if (dataConfirmed) {
      clearAudit()
      const sectorId = formRef.current.getFieldValue('sectors')
      const storeId = formRef.current.getFieldValue('stores')
      const sector_name = getNameById(sectorId, sectors)

      api
        .get(`/v1/audits/user/${storeId}/${sectorId}`)
        .then(response => {
          localStorage.setItem('auditoria@code_auditoria', response.data.code)
          localStorage.setItem('auditoria@id_auditoria', response.data.id)
          localStorage.setItem('auditoria@form_id', response.data.form.id)
          History.push({
            pathname: `${process.env.PUBLIC_URL}/auditorias-pendentes`,
            state: {
              sectorName: sector_name,
              sectorId: sectorId
            }
          })
        })
        .catch(() => {})
    }
  }

  return (
    <div className="container">
      <ToastContainer />
      <Navbar></Navbar>
      <div className="page-title">
        <a
          onClick={() => {
            History.push(`${process.env.PUBLIC_URL}/`)
          }}
        >
          <HiArrowNarrowLeft></HiArrowNarrowLeft>
        </a>
        <h2>Auditoria</h2>
      </div>
      <div className="content">
        <Form ref={formRef}>
          <div className="aud-group">
            <img src={LogoCometa} className="logoCometa"></img>
            <div className="label-auditor">
              <div className="auditor-name">
                <label>Auditor:</label>
                <p>{username}</p>
              </div>
              <div className="area-user">
                <FaUser></FaUser>
              </div>
            </div>
          </div>
          <div className="aud-group">
            <div className="text-1">Selecione Opções</div>
            <div>
              <div className="select">
                <List
                  label={'Marca'}
                  name={'brand'}
                  value={atualBrand}
                  onChange={e => {
                    updateStores(e.target.value)
                    setAtualBrand(e.target.value)
                  }}
                  array={brands}
                ></List>
              </div>
            </div>
            <div>
              <div className="select">
                <List
                  name="stores"
                  value={atualStores}
                  onChange={e => {
                    updateSectors(e.target.value)
                    setAtualStores(e.target.value)
                  }}
                  array={stores}
                  label="Loja"
                ></List>
              </div>
            </div>
            <div>
              <div className="select">
                <Select
                  name="sectors"
                  onChange={e => {
                    verificaPendentes(e.target.value)
                  }}
                >
                  <option>Setor</option>
                  {sectors.map((sector, index) => {
                    return (
                      <option key={index} value={sector.id}>
                        {sector.name}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </div>
          </div>
          <div className="aud-opc-group">
            {/* <div>Selecione uma Opção</div> */}
            <div className="aud-opc-buttons">
              <a
                onClick={async () => {
                  const hasPending = await verificaPendentes(
                    formRef.current.getFieldValue('sectors')
                  )
                  if (hasPending === false) {
                    const dataConfirmed = confirmData()
                    if (dataConfirmed) {
                      clearAudit()

                      let sector_name = ''
                      sectors.forEach(sector => {
                        if (
                          sector.id === formRef.current.getFieldValue('sectors')
                        ) {
                          sector_name = sector.name
                        }
                      })

                      History.push({
                        pathname: `${process.env.PUBLIC_URL}/questionario`,
                        state: {
                          sectorId: formRef.current.getFieldValue('sectors'),
                          sectorName: sector_name,
                          storeId: formRef.current.getFieldValue('stores')
                        }
                      })
                    }
                  } else {
                    toast.warn(
                      'Finalize a auditoria que está pendente para iniciar uma nova!'
                    )
                  }
                }}
              >
                <div className="encerrada">
                  <div className="check"></div>
                  <HiDocumentAdd></HiDocumentAdd>
                </div>
                Nova
              </a>
              <a
                onClick={() => {
                  sendPendingAuditToStorage()
                  const dataConfirmed = confirmData()
                  if (dataConfirmed) {
                    clearAudit()
                    const sectorId = formRef.current.getFieldValue('sectors')
                    const sector_name = getNameById(sectorId, sectors)
                    const storeId = formRef.current.getFieldValue('stores')

                    History.push({
                      pathname: `${process.env.PUBLIC_URL}/auditorias-pendentes`,
                      state: {
                        sectorName: sector_name,
                        sectorId: sectorId,
                        storeId: storeId
                      }
                    })
                  }
                }}
              >
                <div className="encerrada">
                  <div className="check"></div>
                  <HiDocumentText></HiDocumentText>
                </div>
                Pendentes
              </a>
              <a
                onClick={() => {
                  const dataConfirmed = confirmData()
                  if (dataConfirmed) {
                    clearAudit()
                    let sector_name = ''
                    sectors.forEach(sector => {
                      if (
                        sector.id === formRef.current.getFieldValue('sectors')
                      ) {
                        sector_name = sector.name
                      }
                    })
                    setLoading(true)
                    api
                      .get(
                        `/v1/audits/user/${formRef.current.getFieldValue(
                          'stores'
                        )}/${formRef.current.getFieldValue(
                          'sectors'
                        )}?is_finished=${true}
                        `
                      )
                      .then(response => {
                        localStorage.setItem(
                          'auditoria@code_auditoria',
                          response.data.code
                        )
                        localStorage.setItem(
                          'auditoria@id_auditoria',
                          response.data.id
                        )
                        localStorage.setItem(
                          'auditoria@form_id',
                          response.data.form.id
                        )
                        localStorage.setItem(
                          'auditoria@sector_name',
                          sector_name
                        )
                        setLoading(false)
                        History.push({
                          pathname: `${process.env.PUBLIC_URL}/auditoriasfinalizadas`,
                          state: {
                            sectorName: sector_name
                          }
                        })
                      })
                      .catch(error => {
                        setLoading(false)
                        if (error?.response) {
                          if (error.response.status === 404) {
                            toast.info('Nenhum resultado encontrado!')
                          }
                        }
                      })
                  }
                }}
              >
                <div className="encerrada">
                  <div className="check">
                    <ImCheckmark></ImCheckmark>
                  </div>
                  <HiDocument></HiDocument>
                </div>
                Encerradas
              </a>
            </div>
            <Footer />
          </div>
        </Form>
      </div>
      <Loading open={loading} />
    </div>
  )
}

export default Auditoria
