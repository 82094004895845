import React, { useState, useEffect } from 'react'
import History from '../../services/history'
import styles from '../../styles/painel.module.css'

import { MdLiveHelp } from 'react-icons/md'
import {
  RiHome3Line,
  RiBarChart2Fill,
  RiUserLine,
  RiQuestionAnswerFill,
  // RiCalendarTodoFill,
  RiMenuFill,
  RiCloseFill,
  RiSearch2Line
} from 'react-icons/ri'
import LogoCometa from '../../assets/Imagem1.png'
import LogoRisc from '../../assets/risc.svg'

export default function Sidebar(opc) {
  const [openMenu, setOpenMenu] = useState(true)
  const [opcMenu, setOpcMenu] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ])

  useEffect(() => {
    const novaLista = []
    let count = 0
    const valor = opc.opc
    opcMenu.forEach(item => {
      if (count === valor) {
        novaLista[valor] = true
        count++
      } else {
        novaLista[count] = item
        count++
      }
    })
    setOpcMenu(novaLista)
  }, [opc])

  return (
    <>
      <button
        className={styles.btn_drop}
        onClick={() => {
          setOpenMenu(!openMenu)
        }}
      >
        <RiMenuFill></RiMenuFill>
      </button>
      <div className={openMenu && styles.menuMobile}>
        <div className={styles.sidebar}>
          <div className={styles.menu_close}>
            <button
              className={styles.btn_close}
              onClick={() => {
                setOpenMenu(!openMenu)
              }}
            >
              <RiCloseFill></RiCloseFill>
            </button>
          </div>
          <div className={styles.info_user}>
            <img src={LogoCometa}></img>
          </div>
          <p className={styles.menu_title}>MENU</p>
          <ul className={styles.menu_list}>
            <li className={(opcMenu[0] && styles.active) || styles.menu_item}>
              <a
                className={styles.item_link}
                onClick={() => {
                  History.push(`${process.env.PUBLIC_URL}/painel`)
                }}
              >
                <RiHome3Line></RiHome3Line>
                Inicio
              </a>
            </li>
            <li className={(opcMenu[1] && styles.active) || styles.menu_item}>
              <a
                className={styles.item_link}
                onClick={() => {
                  History.push(`${process.env.PUBLIC_URL}/painel/cadastro`)
                }}
              >
                <RiUserLine></RiUserLine>
                Cadastrar
              </a>
            </li>

            <li className={(opcMenu[2] && styles.active) || styles.menu_item}>
              <a
                className={styles.item_link}
                onClick={() => {
                  History.push(`${process.env.PUBLIC_URL}/painel/visualizar`)
                }}
              >
                <RiSearch2Line />
                Visualizar
              </a>
            </li>
            <li className={(opcMenu[3] && styles.active) || styles.menu_item}>
              <a
                className={styles.item_link}
                onClick={() => {
                  History.push(`${process.env.PUBLIC_URL}/painel/dashboard`)
                }}
              >
                <RiBarChart2Fill></RiBarChart2Fill>
                Painel
              </a>
            </li>
            <li className={(opcMenu[4] && styles.active) || styles.menu_item}>
              <a
                className={styles.item_link}
                onClick={() => {
                  History.push(`${process.env.PUBLIC_URL}/painel/relatorio/v2`)
                }}
              >
                <RiQuestionAnswerFill></RiQuestionAnswerFill>
                Relatório
              </a>
            </li>
          </ul>
          <div className={styles.help}>
            <a className={styles.item_link}>
              <MdLiveHelp></MdLiveHelp>
              Ajuda
            </a>
            <div className={styles.LogoRisc}>
              <img src={LogoRisc}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
