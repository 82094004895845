import React, { useEffect, useState } from 'react'
import Loading from '../../../../components/alertLoading'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'
import form from '../../../../styles/Formulario.module.css'
import { MdAddBox, MdIndeterminateCheckBox } from 'react-icons/md'
import { Form } from '@unform/web'
import Select from '../../../../components/form/Select'
import api from '../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import History from '../../../../services/history'
import Input from '../../../../components/form/input'
import List from '../../../../components/List'
import { AiOutlineFileDone } from 'react-icons/ai'
import Swal from 'sweetalert2'

function NovoFormulario() {
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const [brands, setBrands] = useState([])
  const [stores, setStores] = useState([])
  const [sectors, setSectors] = useState([])
  const [weights, setWeights] = useState([])
  const [forms, setForms] = useState([])
  const atualDate = new Date()
  const atualyear = atualDate.getFullYear()
  const [currentSector, setCurrentSector] = useState()
  const [currentYear, setCurrentYear] = useState()

  const [sectors2, setSectors2] = useState([])

  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateStores(brand, updater) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${brand}`)
      setLoading(false)
      if (updater === 'stores') {
        setStores(response?.data)
      } else if (updater === 'stores2') {
        setStores2(response?.data)
      }
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors(s, updater) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setLoading(false)
      if (updater === 'sector') {
        setSectors(response?.data)
      } else if (updater === 'sector2') {
        setSectors2(response?.data)
      }
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  useEffect(() => {
    init()
  }, [])

  function changeWeight(value, position) {
    const newList = []
    weights.forEach((valor, index) => {
      if (index === position) {
        newList[position] = value
      } else {
        newList[index] = valor
      }
    })
    setWeights(newList)
  }

  const [questions, setQuestions] = useState([])
  const [utterance, setUtterance] = useState('')
  const [weight, setWeight] = useState('')

  async function formSubmit(data) {
    if (data.brand === 'clear') {
      toast.info('Selecione uma Marca!')
    } else if (data.store === 'clear') {
      toast.info('Selecione uma Loja!')
    } else if (data.sector === 'clear' || data.sector === '') {
      toast.info('Selecione um Setor')
    } else if (data.goal === '') {
      toast.info('Informe uma Meta')
    } else {
      if (questions.length > 0) {
        if (utterance === '') {
          try {
            const questionIdList = []
            questions.forEach(element => {
              questionIdList.push(element.id)
            })
            const parameters = {
              form_in: {
                sector_id: data.sector,
                goal: data.goal
              },
              storeList: [data.store],
              questionList: questionIdList,
              valueList: weights
            }
            setLoading(true)
            await api.post('/v1/forms/', parameters)
            setLoading(false)
            Swal.fire('Formulário cadastrado com sucesso!', '', 'success').then(
              () => {
                History.push(`${process.env.PUBLIC_URL}/painel/cadastro`)
              }
            )
          } catch (err) {
            setLoading(false)
            toast.error('Falha ao cadastrar formulário')
          }
        } else {
          toast.warn('A ultima questão não foi Salva')
        }
      } else {
        toast.error('Não é possivel cadastrar um formulario sem perguntas')
      }
    }
  }

  function removeQuestion(questId) {
    const aux = []
    const aux2 = []
    questions.forEach((question, index) => {
      if (question.id !== questId) {
        aux.push(question)
        aux2.push(weights[index])
      }
    })
    setWeights(aux2)
    setQuestions(aux)
  }

  async function sendQuestion() {
    try {
      if (utterance === '') {
        toast.error('Preencha o enunciado da questão')
      } else {
        if (weight > 3 || weight < 0) {
          toast.error('O peso deve ser menor ou igual a 3')
        } else if (weight === '') {
          toast.warn('Informe o peso da questão!!')
        } else {
          const parameters = {
            enunciation: utterance
          }
          setLoading(true)
          const response = await api.post('/v1/forms/question', parameters)
          setLoading(false)
          const aux = []
          questions.forEach(element => {
            aux.push(element)
          })
          aux.push(response.data)

          setQuestions(aux)

          const aux2 = []
          weights.forEach(element => {
            aux2.push(element)
          })
          aux2.push(weight)
          setWeights(aux2)
          setUtterance('')
          setWeight(0)
        }
      }
    } catch (err) {
      setLoading(false)
      toast.error('Não foi possivel adicionar a pergunta')
    }
  }

  async function listForms(data) {
    try {
      const year = data.year
      if (data.store2 === 'clear') {
        toast.info('Selecione um loja!')
        return
      } else if (data.sector2 === 'clear') {
        toast.info('Selecione uma setor!')
        return
      }
      setLoading(true)
      const resp = await api.get(
        `/v1/forms/${data.store2}/${data.sector2}?year=${parseInt(year)}`
      )
      setLoading(false)
      setForms(resp.data)
    } catch (error) {
      setLoading(false)
      if (error.response) {
        if (error.response.status === 404) {
          toast.info('não foram encontrados resultados')
        }
        setForms([])
      }
    }
  }

  const [sForm, setSform] = useState(false)
  const [currentBrand, setCurrentBrand] = useState()
  const [currentStore, setCurrentStore] = useState()
  const [stores2, setStores2] = useState([])
  function SearchForms() {
    return (
      <>
        {sForm && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setSform(false)
              }}
            ></div>
            <BuscaForm></BuscaForm>
          </div>
        )}
      </>
    )
  }

  function BuscaForm() {
    return (
      <Form className={form.containerCad} onSubmit={listForms}>
        <h1 className={form.titleForm}>Selecione o formulário de Origem</h1>
        <div className={styles.flex_row_bet}>
          <div className={(styles.flex_column_bet, form.input_w50)}>
            <span className={form.title_input}>Selecione a Marca</span>
            <div className={form.select}>
              <List
                name={'brand2'}
                className={form.campo_input}
                value={currentBrand}
                onChange={e => {
                  if (e.target.value !== 'clear') {
                    setStores2([])
                    setSectors2([])
                    updateStores(e.target.value, 'stores2')
                    setCurrentBrand(e.target.value)
                  } else {
                    setStores([])
                  }
                }}
                array={brands}
                label="Marca"
              ></List>
            </div>
          </div>
          <div className={(styles.flex_column_bet, form.input_w50)}>
            <span className={form.title_input}>Selecione a Loja</span>
            <div className={form.select}>
              <List
                name="store2"
                className={form.campo_input}
                array={stores2}
                value={currentStore}
                label="Lojas"
                onChange={e => {
                  updateSectors(e.target.value, 'sector2')
                  setCurrentStore(e.target.value)
                }}
              ></List>
            </div>
          </div>
        </div>
        <div className={styles.flex_row_bet}>
          <div className={(styles.flex_column_bet, form.input_w50)}>
            <span className={form.title_input}>Setor</span>
            <div className={form.select}>
              <List
                value={currentSector}
                name={'sector2'}
                className={form.campo_input}
                array={sectors2}
                onChange={e => {
                  setCurrentSector(e.target.value)
                }}
                label="Setor"
              ></List>
            </div>
          </div>
          <div className={(styles.flex_column_bet, form.input_w50)}>
            <span className={form.title_input}>Selecione o Ano</span>
            <div className={form.select}>
              <Select
                className={form.campo_input}
                name="year"
                value={currentYear}
                onChange={e => {
                  setCurrentYear(e.target.value)
                }}
              >
                <option>{atualyear}</option>
                <option>{atualyear - 1}</option>
                <option>{atualyear - 2}</option>
                <option>{atualyear - 3}</option>
              </Select>
            </div>
          </div>
        </div>
        <div className={form.line}></div>
        <div className="formselect-container">
          {forms.map((element, index) => {
            const created_date = new Date(element.created_at)
            const month = created_date.getMonth()
            const day = String(created_date.getDate()).padStart(2, '0')
            const year = created_date.getFullYear()
            const output = `${day} - ${month < 9 ? '0' : ''}${
              month + 1
            } - ${year}`

            // created_date.toLocaleTimeString()
            return (
              <div
                className="formselect-item"
                key={index}
                onClick={() => {
                  getPerguntas(element.id)
                }}
              >
                <AiOutlineFileDone></AiOutlineFileDone>
                <p>{output}</p>
                <button>selecionar</button>
              </div>
            )
          })}
        </div>
        <div className={form.line}></div>
        <div className={form.btn_cadastro_view_audit}>
          <button className={form.btn}>Buscar</button>

          <button
            className={form.btn}
            onClick={() => {
              setSform(false)
            }}
          >
            Voltar
          </button>
        </div>
      </Form>
    )
  }

  async function getPerguntas(id) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/forms/form-question/${id}`)
      const data = response?.data
      setLoading(false)
      const tempQuestions = []
      const tempWeghts = []
      data.forEach(element => {
        tempQuestions.push(element)
        tempWeghts.push(element.value)
      })
      setQuestions(tempQuestions)
      setWeights(tempWeghts)
      setSform(false)
      setIsNew(true)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={styles.containerRow}>
      <SearchForms></SearchForms>
      <Sidebar opc={1}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form onSubmit={formSubmit} className={styles.content}>
          <h1 className={styles.title_main}>Cadastrar </h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca</strong>
                </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="brand"
                    onClick={element => {
                      if (element.target.value !== 'clear') {
                        setStores([])
                        setSectors([])
                        updateStores(element.target.value, 'stores')
                      } else {
                        setStores([])
                      }
                    }}
                    id="marca"
                  >
                    <option value={'clear'}>Selecione...</option>
                    {brands.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja</strong>
                </span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="store"
                    onClick={e => {
                      if (e.target.value !== 'clear') {
                        updateSectors(e.target.value, 'sector')
                      } else {
                        setSectors([])
                      }
                    }}
                    id="loja"
                  >
                    <option value={'clear'}>Selecione...</option>
                    {stores.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor</strong>
                </span>
                <div className={form.select}>
                  <Select className={form.campo_input} name="sector" id="setor">
                    <option>Selecione...</option>
                    {sectors.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {element.name}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>Meta do formulário</span>
                <div className={form.select}>
                  <Input
                    step="any"
                    type="number"
                    className={form.campo_input}
                    placeholder="informe um numero de 0 a 10"
                    name="goal"
                    max="10"
                    min="0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={form.containerCad}>
            {!isNew && (
              <>
                <h1 className={form.titleForm}>Questionario</h1>
                <div className={styles.flex_row_bet}>
                  <div className={form.btn_form}>
                    <button
                      className={form.btn}
                      onClick={() => {
                        setIsNew(true)
                      }}
                    >
                      Novo Questionario
                    </button>
                  </div>
                  <div className={form.btn_form}>
                    <button
                      type="button"
                      className={form.btn}
                      onClick={() => {
                        setSform(true)
                      }}
                    >
                      Clonar Questionario
                    </button>
                  </div>
                </div>
              </>
            )}
            {questions.map((question, index) => {
              return (
                <label key={index}>
                  <div className={styles.flex_row_bet}>
                    <div className={(styles.flex_column_bet, form.input_w90)}>
                      <span className={form.title_input}>
                        <strong>Indicador {index + 1} </strong>
                      </span>
                      <div>
                        <textarea
                          className={form.campo_input}
                          disabled
                          rows="3"
                          value={question.enunciation}
                        ></textarea>
                      </div>
                      <div className={(styles.flex_column_bet, form.input_w50)}>
                        <span className={form.title_input}>
                          <strong>Peso</strong>
                        </span>
                        <div className="d-flex1">
                          <input
                            className={form.campo_input}
                            type="number"
                            step=".01"
                            min="0"
                            max="3"
                            value={weights[index]}
                            onChange={e => {
                              changeWeight(e.target.value, index)
                            }}
                          />
                          {/* <p>{weights[index]}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className={form.input_w10}>
                      <span>a</span>
                      <a>
                        <MdIndeterminateCheckBox
                          onClick={() => {
                            removeQuestion(question.id)
                          }}
                        ></MdIndeterminateCheckBox>
                      </a>
                    </div>
                  </div>
                </label>
              )
            })}

            {isNew && (
              <>
                <div className={styles.flex_row_bet}>
                  <div className={(styles.flex_column_bet, form.input_w90)}>
                    <span className={form.title_input}>
                      <strong>Indicador</strong>
                    </span>
                    <div className={form.select}>
                      <textarea
                        className={form.campo_input}
                        name=""
                        value={utterance}
                        minLength="20"
                        onChange={e => {
                          setUtterance(e.target.value)
                        }}
                        rows="3"
                      ></textarea>
                    </div>
                    <div className={(styles.flex_column_bet, form.input_w50)}>
                      <span className={form.title_input}>
                        <strong>Peso</strong>
                      </span>
                      <div className="d-flex1">
                        <input
                          className={form.campo_input}
                          type="number"
                          step=".01"
                          min="0"
                          max="3"
                          onChange={e => {
                            setWeight(e.target.value)
                          }}
                          value={weight}
                        />
                        {/* <p>{weight}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className={form.input_w10}>
                    <span>a</span>
                    <a>
                      <MdAddBox
                        onClick={() => {
                          sendQuestion()
                        }}
                      ></MdAddBox>
                    </a>
                  </div>
                </div>
                <div className={form.btn_cadastro}>
                  <button className={form.btn}>Cadastrar</button>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
      <Loading open={loading} />
    </div>
  )
}

export default NovoFormulario
