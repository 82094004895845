import React from 'react'
// import { useRouter } from 'next/router'
import LogoCometa from '../../assets/gp-cometa.svg'
import NavbarPainel from '../../components/navbarPainel'
import Sidebar from '../../components/sidebar'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'

export default function painelCad() {
  return (
    <div className={styles.containerRow}>
      <Sidebar opc={0}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel back={true}></NavbarPainel>
        <div className={styles.content}>
          <div className={form.containerCad}>
            <img src={LogoCometa} className={styles.LogoCometa}></img>
            <h1 className={styles.title}>Bem Vindo de Novo!</h1>
            <h2 className={styles.subtitle}>Painel do Sistema de Auditoria</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
