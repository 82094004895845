import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import PropTypes from 'prop-types'
function Select({ name, ...rest }) {
  const selectRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return <select ref={selectRef} {...rest} defaultValue={defaultValue}></select>
}
Select.defaultProps = {
  name: ''
}

Select.propTypes = {
  name: PropTypes.string
}
export default Select
