import React from 'react'
import History from '../../../services/history'

import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import styles from '../../../styles/painel.module.css'

import { RiUserLine, RiShoppingCartLine } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { AiOutlineRise } from 'react-icons/ai'

export default function painelCad() {
  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Visualizar</h1>
          <div className={styles.flex_row_bet}>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiShoppingCartLine></RiShoppingCartLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Estabelecimentos</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  Visualize as Marcas, Lojas e Setores existentes no Sistema...
                </p>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento`
                      )
                    }}
                  >
                    Visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <AiOutlineRise></AiOutlineRise>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Formulários</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  Visualize as perguntas e pesos realizadas na Auditoria...
                </p>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/formulario`
                      )
                    }}
                  >
                    Visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiUserLine></RiUserLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Auditorias</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  visualize as auditorias finalizadas e consolide...
                </p>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/auditoria/v2`
                      )
                    }}
                  >
                    visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
