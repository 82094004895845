import React from 'react'
// import { useRouter } from 'next/router'
import History from '../../../services/history'

import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import styles from '../../../styles/painel.module.css'

import { RiUserLine, RiShoppingCartLine } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { AiOutlineRise } from 'react-icons/ai'
import { ToastContainer } from 'react-toastify'

export default function painelCad() {
  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Editar</h1>
          <div className={styles.flex_row_bet}>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiShoppingCartLine></RiShoppingCartLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Estabelecimento</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  Faça a alteração de Marcas, Lojas e Setores para realização da
                  auditoria...
                </p>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/editar/estabelecimento`
                      )
                    }}
                  >
                    Editar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <AiOutlineRise></AiOutlineRise>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Formulário</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  Faça a alteração de perguntas e pesos realizadas na
                  Auditoria...
                </p>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/editar/formulario`
                      )
                    }}
                  >
                    Editar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiUserLine></RiUserLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Auditor</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <p className={styles.resume_cadastro}>
                  Faça a alteração dos novos auditores do sistema...
                </p>
                <div className={styles.btn_cadastro}>
                  <button className={styles.btn}>
                    Editar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
