import React from 'react'
import { bool, string } from 'prop-types'
import './style.css'
import { BiLoaderAlt } from 'react-icons/bi'

const Loading = ({ status, label = 'Carregando...' }) => {
  const containerStyle = {
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.3)',
    position: 'fixed',
    zIndex: '3',
    display: status ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const boxStyle = {
    padding: '1rem 2.5rem',
    background: 'white',
    borderRadius: '0.7rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <BiLoaderAlt style={{ width: 36, height: 36 }} className="rotating" />
        <p style={{ margin: '0.2rem' }}>{label}</p>
      </div>
    </div>
  )
}

Loading.propTypes = {
  status: bool,
  label: string
}
export default Loading
