import React, { useState } from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import api from '../../services/api'
import { Form } from '@unform/web'
import Loading from '../alertLoading'
import Input from '../../components/form/input'
import { ModalsContext } from '../../providers/modals'
import PropTypes from 'prop-types'
import Select from '../form/Select'
import { toast, ToastContainer } from 'react-toastify'

export default function EditBrand(props) {
  const [loading, setLoading] = useState(false)
  const { setEditingMarca } = React.useContext(ModalsContext)
  async function brandSubmit(data) {
    try {
      const bodyParameters = {
        name: data.name
      }
      setLoading(true)
      await api.put(`/v1/stores/brand/${props.brandId}`, bodyParameters)
      setEditingMarca(false)
      setLoading(false)
      toast.success('Marca editada com sucesso!!!')
    } catch (error) {
      setLoading(false)
      toast.error('Não foi possivel Realizar a opração')
    }
  }

  const [activeSelect, setActveSelect] = useState(false)

  // useEffect(() => {}, [])

  return (
    <div className={form.containerCad}>
      <ToastContainer></ToastContainer>
      <Form onSubmit={brandSubmit}>
        <h1 className={form.titleForm}>
          Editar <strong> Marca</strong>
        </h1>
        <div className={styles.flex_row_bet}>
          <div className={(styles.flex_column_bet, form.input_w100)}>
            <span className={form.title_input}>Nome Antigo</span>
            <div className={form.select}>
              <Select
                disabled={true}
                className={form.campo_input}
                name="brand"
                onChange={() => setActveSelect(!activeSelect)}
              >
                <option value={props.brandId}>{props.brandName}</option>
              </Select>
            </div>
          </div>
          <div className={form.line}></div>
          <div className={(styles.flex_column_bet, form.input_w100)}>
            <span className={form.title_input}>Novo Nome</span>
            <Input className={form.campo_input} type="text" name="name" id="" />
          </div>
        </div>
        <div className={form.btn_cadastro}>
          <button className={form.btn}>Editar</button>
        </div>
      </Form>
      <Loading open={loading} />
    </div>
  )
}

EditBrand.propTypes = {
  brandName: PropTypes.string,
  brandId: PropTypes.string
}
