import { toast } from 'react-toastify'
import History from './history'

function removeSpacesFromBeginningAndEnd(str) {
  str = str.replace(/^[ ]+|[ ]+$/g, '')
  return str
}

function logOut(module) {
  if (module === 'panel') {
    History.push(`${process.env.PUBLIC_URL}/login-painel`)
  } else {
    History.push(`${process.env.PUBLIC_URL}/`)
  }
  localStorage.removeItem('auditoria@token')
  localStorage.removeItem('auditoria@userName')
}

function removeSpacesFromBeginning(str, element) {
  const array = str.split('')
  if (array[0] === ' ') {
    array[0] = ''
    if (element) {
      const styles = getComputedStyle(element, 'border')
      const border = styles.border
      element.style.border = '1px solid red'

      const elParent = element.parentNode

      const p = document.createElement('p')
      p.setAttribute('class', 'p-alert')
      p.style.color = 'red'
      p.style.fontSize = '0.8rem'
      p.innerText = `Não é permitido iniciar com espaços`
      const ps = elParent.getElementsByClassName('p-alert')
      if (ps.length === 0) {
        elParent.appendChild(p)
        setTimeout(() => {
          element.style.border = border
          elParent.removeChild(p)
        }, 2200)
      }
    }
  }
  str = array.toString().replace(/,/g, '')
  return str
}

function capitalize(value) {
  return value.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1, string.length)
}

function clearAudit() {
  localStorage.removeItem('auditoria@form_id')
  localStorage.removeItem('auditoria@id_auditoria')
  localStorage.removeItem('auditoria@code_auditoria')
  localStorage.removeItem('store_id')
  localStorage.removeItem('sector_id')
  localStorage.removeItem('auditoria@audit_date')
}

function dataAddMask(value) {
  let aux = value.split('')
  aux = `${aux[8]} ${aux[9]} /${aux[5]} ${aux[6]} /${aux[0]} ${aux[1]} ${aux[2]} ${aux[3]} `
  return aux
}

function dataRemoveMask(value) {
  let aux = value.split('')
  aux = `${aux[6]}   ${aux[7]} ${aux[8]} ${aux[9]} -${aux[3]} ${aux[4]} -${aux[0]} ${aux[1]} `
  return aux
}

function cpfAddMask(value) {
  let aux = value
  aux = aux?.replace(/(\d{3})(\d)/, '$1.$2')
  aux = aux?.replace(/(\d{3})(\d)/, '$1.$2')
  aux = aux?.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  return aux
}

function telAddMask(value) {
  let aux = removeMask(value)
  aux = aux.replace(/^(\d{2})(\d)/g, '($1) $2')
  aux = aux.replace(/(\d)(\d{4})$/, '$1-$2')
  return aux
}

function removeMask(value) {
  return value?.replace(/[^0-9]/g, '')
}

function cepAddMask(value) {
  let aux = removeMask(value)
  aux = aux.replace(/(\d{2})(\d)/, '$1.$2')
  aux = aux.replace(/(\d{3})(\d)/, '$1-$2')
  return aux
}

function filter(value, upLim) {
  if (value.length > upLim) {
    return `${value.substr(0, upLim)} ...`
  } else {
    return value
  }
}

function CpfCnpjMask(value) {
  let aux = value
  if (aux.length < 12) {
    aux = aux.replace(/(\d{3})(\d)/, '$1.$2')
    aux = aux.replace(/(\d{3})(\d)/, '$1.$2')
    aux = aux.replace(/(\d{3})(\d{1,2} )$/, '$1-$2')
  } else {
    aux = aux.replace(/^(\d{2})(\d)/, '$1.$2')
    aux = aux.replace(/^(\d{2})\.(\d{3} )(\d)/, '$1.$2.$3')
    aux = aux.replace(/\.(\d{3})(\d)/, '.$1/$2')
    aux = aux.replace(/(\d{4})(\d)/, '$1-$2')
  }
  return aux
}

function formatMoney(value) {
  let aux = value
  // aux = aux.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  aux = aux.replace(/([0-9]{2})$/g, ',$1')
  aux = aux.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  aux = aux.replace(/.,/g, ',')
  return aux
}

function formatReal(value) {
  let aux = removeMask(value)
  aux = aux.replace(/([0-9]{2})$/g, ',$1')
  if (aux.length > 6) aux = aux.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return aux
}

function formatWeight(value) {
  let aux = value + ''
  aux = aux.replace(/(\d)(\d{3} )/, '$1.$2')
  return aux
}

function rndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

function rndArray(max, value) {
  const nums = []
  let aux = rndInteger(0, max)
  nums.push(aux)

  while (nums.length < value) {
    let err = false
    aux = rndInteger(0, max)
    for (let i = 0; i < nums.length; i++) {
      if (aux === nums[i]) {
        err = true
        break
      }
    }
    if (err === false) {
      nums.push(aux)
    }
  }
  return nums
}

function openMarket(date, hOpen, hClose) {
  if (date.getHours() >= hOpen.substring(0, 2)) {
    if (date.getMinutes() >= hOpen.substring(3, 5)) {
      if (date.getHours() <= hClose.substring(0, 2)) {
        if (date.getMinutes() <= hClose.substring(3, 5)) {
          return true
        }
      }
    }
  }

  return false
}
function showErrors(errors) {
  if (typeof errors === 'object') {
    errors?.forEach(err => {
      toast.error(err.msg)
    })
  } else {
    toast.error(errors)
  }
}
export {
  showErrors,
  capitalize,
  capitalizeFirstLetter,
  clearAudit,
  formatMoney,
  cepAddMask,
  cpfAddMask,
  CpfCnpjMask,
  dataAddMask,
  dataRemoveMask,
  filter,
  formatReal,
  formatWeight,
  removeMask,
  rndArray,
  rndInteger,
  openMarket,
  telAddMask,
  removeSpacesFromBeginningAndEnd,
  removeSpacesFromBeginning,
  logOut
}
