import React, { useEffect, useState } from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import api from '../../services/api'
import { toast, ToastContainer } from 'react-toastify'
import { Form } from '@unform/web'
import Loading from '../alertLoading'
import Select from '../form/Select'
import Input from '../form/input'
import { ModalsContext } from '../../providers/modals'
import * as Yup from 'yup'
import {
  removeSpacesFromBeginning,
  removeSpacesFromBeginningAndEnd
} from '../../services/functions'

export default function NovoSetor() {
  const [loading, setLoading] = useState(false)
  const { setOpenSetor } = React.useContext(ModalsContext)

  const [brands, setBrands] = useState([])
  const [stores, setStores] = useState([])
  const [sectors, setSectors] = useState([])
  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setBrands(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setStores(response?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors() {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector`)
      setSectors(response?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  useEffect(() => {
    init()
    updateSectors()
  }, [])

  async function sectorSubmit(data) {
    try {
      const parameters = {
        store_id: data.store,
        sectorListId: [data.sector]
      }

      if (!isOther) {
        const schema = Yup.object().shape({
          otherSector: Yup.string().required(
            'O nome do novo setor é obrigatório!'
          )
        })
        await schema.validate(data, {
          abortEarly: false
        })

        const bodyParameters = {
          name: data.otherSector
        }
        setLoading(true)
        const response = await api.post('/v1/stores/sector', bodyParameters)
        setLoading(false)
        parameters.sectorListId = [response.data.id]
      }
      setLoading(true)
      await api.post('/v1/stores/store-sector/', parameters)

      setLoading(false)
      toast.success('Setor cadastrado com sucesso!')
      setOpenSetor(false)
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }
  const [isOther, setIsOther] = useState(true)
  return (
    <Form onSubmit={sectorSubmit} className={form.containerCad}>
      <ToastContainer></ToastContainer>
      <h1 className={form.titleForm}>
        Cadastrar novo <strong> Setor</strong>
      </h1>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Selecione a Marca</span>
          <div className={form.select}>
            <Select
              name="brand"
              className={form.campo_input}
              onChange={e => {
                setStores([])
                setSectors([])
                updateStores(e.target.value)
              }}
            >
              <option>Selecione...</option>

              {brands.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Selecione a Loja</span>
          <div className={form.select}>
            <Select
              name="store"
              className={form.campo_input}
              onChange={e => {
                updateSectors(e.target.value)
              }}
            >
              <option value={'clear'}>Selecione...</option>
              {stores.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </div>
        </div>
      </div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Adicionar Setor</span>
          <div
            className={form.select}
            onChange={e => {
              if (e.target.value === 'other') {
                setIsOther(false)
              } else {
                setIsOther(true)
              }
            }}
          >
            <Select className={form.campo_input} name="sector">
              <option>Selecione...</option>
              {sectors.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
              <option value="other">Outro</option>
            </Select>
          </div>
        </div>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}> Novo Setor (outros)</span>
          <Input
            className={form.campo_input}
            type="text"
            name="otherSector"
            disabled={isOther}
            placeholder={'Nome do novo setor'}
            onChange={e => {
              e.target.value = removeSpacesFromBeginning(
                e.target.value,
                e.target
              )
            }}
            onBlur={e => {
              e.target.value = removeSpacesFromBeginningAndEnd(e.target.value)
            }}
          />
        </div>
      </div>

      <div className={form.btn_cadastro}>
        <button className={form.btn}>Cadastrar</button>
      </div>

      <Loading open={loading} />
    </Form>
  )
}
