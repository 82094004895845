import saveAudit from './saveAudit'
import formatDate from './formatDate'
import getDateYear from './getDateYear'

const getStoreName = (storeId, stores) => {
  let result
  if (storeId === null) {
    result = ''
  } else {
    stores.forEach(item => {
      if (item.id === storeId) {
        result = ' ' + item.name
      }
    })
  }

  return result
}

const getSectorName = (sectorId, sectors) => {
  let result
  if (sectorId === null) {
    result = ''
  } else {
    sectors.forEach(item => {
      if (item.id === sectorId) {
        result = ' ' + item.name
      }
    })
  }

  return result
}

const sortIdentifiers = list => {
  list.sort(function (a, b) {
    return a.identifier > b.identifier
      ? 1
      : b.identifier > a.identifier
      ? -1
      : 0
  })
}
export {
  getStoreName,
  sortIdentifiers,
  getSectorName,
  saveAudit,
  formatDate,
  getDateYear
}
