import React, { useState, useEffect } from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import { ModalsContext } from '../../providers/modals'
import Loading from '../alertLoading'
import Select from '../form/Select'
import Input from '../../components/form/input'
import api from '../../services/api'
import { cepAddMask, removeMask } from '../../services/functions'
import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

export default function EditStore(props) {
  const [loading, setLoading] = useState(false)
  const store = props.store
  const { setEditingStore } = React.useContext(ModalsContext)
  const [cep, setCep] = useState()
  const [active, setActve] = useState(false)
  const [activeSelect, setActveSelect] = useState(false)

  useEffect(() => {
    init()
  }, [])

  async function init() {
    try {
      setLoading(true)
      await api.get('/v1/stores/brand')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function storeSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!'),
        cep: Yup.string().required('O campo cep é obrigatório!'),
        street: Yup.string().required('O campo rua é obrigatório!'),
        number: Yup.string()
          .required('O campo número é obrigatório!')
          .test(
            'len',
            'O campo número deve ter no máximo 5 caracteres',
            val => val.length <= 5
          ),
        district: Yup.string().required('O campo bairro é obrigatório!'),
        complement: Yup.string().required('O campo complemento é obrigatório!'),
        city: Yup.string().required('O campo cidade é obrigatório! '),
        uf: Yup.string().required('O campo UF é obrigatório!'),
        georeference: Yup.string()
      })
      await schema.validate(data, {
        abortEarly: false
      })

      const parameters = data
      parameters.cep = removeMask(data.cep)
      setLoading(true)
      await api.put(`/v1/stores/${store.id}`, parameters)
      setLoading(false)
      setEditingStore(false)
      toast.success('A Loja foi editada com sucesso!!')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else {
        const result = err.response
        if (result !== undefined) {
          const errs = result.data.detail

          errs.forEach(err => {
            toast.error(err.msg)
          })
        }
      }
    }
  }

  return (
    <Form
      initialData={store}
      onSubmit={storeSubmit}
      className={form.containerCad}
    >
      <h1 className={form.titleForm}>
        Editar <strong> Loja</strong>
      </h1>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Marca</span>
          <div className={form.select}>
            <Select
              className={form.campo_input}
              name="brand"
              onChange={() => setActveSelect(!activeSelect)}
              disabled={true}
            ></Select>
          </div>
        </div>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Loja</span>
          <div className={form.select}>
            <Select
              name="Store"
              disabled={true}
              className={form.campo_input}
              onChange={() => setActve(!active)}
            ></Select>
          </div>
        </div>
      </div>
      <div className={form.line}></div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Nome</span>
          <Input
            className={form.campo_input}
            type="text"
            name="name"
            maxLength="80"
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w70)}>
          <span className={form.title_input}>Rua</span>
          <Input
            className={form.campo_input}
            type="text"
            name="street"
            maxLength="100"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w30)}>
          <span className={form.title_input}>Nº</span>
          <Input
            className={form.campo_input}
            type="text"
            name="number"
            id=""
            maxLength="5"
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w70)}>
          <span className={form.title_input}>Cidade</span>
          <Input
            className={form.campo_input}
            type="text"
            name="city"
            maxLength="80"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w30)}>
          <span className={form.title_input}>UF</span>
          <Input
            className={form.campo_input}
            type="text"
            name="uf"
            maxLength="2"
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Bairro</span>
          <Input
            className={form.campo_input}
            type="text"
            name="district"
            maxLength="80"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>CEP</span>
          <Input
            className={form.campo_input}
            // maxLength="8"
            value={cep}
            onChange={e => {
              const valor = cepAddMask(e.target.value)
              setCep(valor)
            }}
            maxLength="10"
            type="text"
            name="cep"
          />
        </div>
      </div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Complemento</span>
          <Input
            className={form.campo_input}
            type="text"
            name="complement"
            maxLength="100"
          />
        </div>
      </div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Referencia</span>
          <Input className={form.campo_input} type="text" name="georeference" />
        </div>
      </div>

      <div className={form.btn_cadastro}>
        <button type="submit" className={form.btn}>
          Editar
        </button>
      </div>

      <Loading open={loading} />
    </Form>
  )
}
EditStore.propTypes = {
  store: PropTypes.any
}
