import React from 'react'
import History from './services/history'
import { Router } from 'react-router-dom'
import Routes from './routes'
import './globals.css'
import 'react-toastify/dist/ReactToastify.css'
import { ModalsProviders } from './providers/modals'
import { AuthProvider } from './providers/auth'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

function App() {
  return (
    <Router history={History}>
      <ModalsProviders>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ModalsProviders>
    </Router>
  )
}

export default App
