const Redirect = () => {
  const host =
    window.location.protocol +
    '//' +
    window.location.host +
    process.env.PUBLIC_URL
  window.location.href = `https://auth.viacometa.com.br/?redirect=${host}/loading`
  return null
}

const PainelRedirect = () => {
  const host =
    window.location.protocol +
    '//' +
    window.location.host +
    process.env.PUBLIC_URL
  window.location.href = `https://auth.viacometa.com.br/?redirect=${host}/painel-loading`
  return null
}

export { Redirect, PainelRedirect }
