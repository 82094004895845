import axios from 'axios'
import { toast } from 'react-toastify'
import History from './history'
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
  // baseURL: `http://192.168.10.37:8000/api`
  // baseURL: `http://localhost/3004`
})

api.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'auditoria@token'
    )}`

    return config
  },
  error => {
    // Do something with request error
    console.log(error.response)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      toast.error('Não foi possível validar suas credenciais')
      localStorage.removeItem('auditoria@token')
      localStorage.removeItem('auditoria@userName')
      setTimeout(() => {
        History.push(`${process.env.PUBLIC_URL}/`)
      }, 2000)
    }

    return Promise.reject(error)
  }
)

export default api
