import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import PropTypes from 'prop-types'

function BasicTextArea({ name, ...rest }) {
  const TextareatRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextareatRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <textarea
      ref={TextareatRef}
      defaultValue={defaultValue}
      {...rest}
    ></textarea>
  )
}
BasicTextArea.defaultProps = {
  name: ''
}

BasicTextArea.propTypes = {
  name: PropTypes.string
}
export default BasicTextArea
