import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import PropTypes from 'prop-types'
import autosize from 'autosize'
function Textarea({ name, ...rest }) {
  const TextareatRef = useRef(null)
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextareatRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  useEffect(() => {
    autosize(TextareatRef.current)
  }, [])
  return (
    <textarea
      ref={TextareatRef}
      defaultValue={defaultValue}
      {...rest}
    ></textarea>
  )
}
Textarea.defaultProps = {
  name: ''
}

Textarea.propTypes = {
  name: PropTypes.string
}
export default Textarea
