import React, { useState, useContext } from 'react'
import styles from '../../styles/painel.module.css'
import History from '../../services/history'
import PropTypes from 'prop-types'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
// import { IoIosNotifications } from 'react-icons/io'
import { MdOutlineLogout, MdOutlineKeyboardBackspace } from 'react-icons/md'
import { AuthContext } from '../../providers/auth'
import { logOut } from '../../services/functions'

export default function NavbarPainel(props) {
  const { username } = useContext(AuthContext)

  const [activeMenu, setActiveMenu] = useState(false)
  // const [activeNotification, setActiveNotification] = useState(false)

  // function ModalNotification() {
  //   return (
  //     <div className={styles.modal_container_1}>
  //       <div className={styles.modal_content}>
  //         <p className={styles.notification_title}>Notificações</p>
  //         <a>
  //           <div className={styles.img_audi}></div>
  //         </a>
  //       </div>
  //     </div>
  //   )
  // }

  function ModalMenu() {
    return (
      <div className={styles.modal_container}>
        <div className={styles.modal_content}>
          <a>
            <p className={styles.color_red}>
              <strong>Status:</strong> Online
            </p>
          </a>
          <a>
            <p>Configurações </p>
          </a>
          <a>
            <p>Feedback</p>
          </a>
          <a
            onClick={() => {
              logOut('panel')
            }}
          >
            <p className={styles.color_red}>Sair</p>
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.navbar_section}>
        {/* <div className={styles.section_item}>
          <button
            className={styles.icon_btn}
            onClick={e => {

              setActiveNotification(!activeNotification)
            }}
          >
            <IoIosNotifications></IoIosNotifications>
          </button>
          {activeNotification && ModalNotification()}
        </div> */}
        <div className={styles.section_item}>
          <div className={styles.area_user}>
            <button
              className={styles.icon_btn}
              onClick={() => {
                setActiveMenu(!activeMenu)
              }}
            >
              {/* <p className={styles.user}>Fulano da Silva</p> */}
              <p className={styles.user}>{username}</p>
              {activeMenu && <FiChevronUp></FiChevronUp>}
              {!activeMenu && <FiChevronDown></FiChevronDown>}
            </button>
          </div>
          {activeMenu && ModalMenu()}
        </div>

        <div className={styles.section_item}>
          <div className={styles.btn_sair}>
            <button
              className={styles.icon_btn}
              onClick={() => {
                logOut('panel')
              }}
            >
              <MdOutlineLogout></MdOutlineLogout>
              <p>Sair</p>
            </button>
          </div>
        </div>
      </div>
      {!props.back && (
        <button
          type="button"
          className={styles.btn_voltar}
          onClick={() => {
            History.goBack()
          }}
        >
          <MdOutlineKeyboardBackspace />
          <span>Voltar</span>
        </button>
      )}
    </div>
  )
}
NavbarPainel.propTypes = {
  back: PropTypes.any
}
