/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import {
  ToastContainer
  // toast
} from 'react-toastify'
import { FiFileText } from 'react-icons/fi'
import { Form } from '@unform/web'
import { MdDownload } from 'react-icons/md'
import List from '../../../components/List'
import Loading from '../../../components/alertLoading'
import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import Select from '../../../components/form/Select'
import GenerateReport from '../../../components/gerarRelatorio'
import api from '../../../services/api'
import { showErrors } from '../../../services/functions'
import styles from '../../../styles/painel.module.css'
import form from '../../../styles/Formulario.module.css'

import History from '../../../services/history'

function painelReport() {
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState('')
  const [stores, setStores] = useState([])
  const [store, setStore] = useState('')
  const [sectors, setSectors] = useState([])
  const [sector, setSector] = useState('')
  const [year, setYear] = useState('')
  const [semester, setSemester] = useState('1º Semestre')
  const [audits, setAudits] = useState([])
  const [filter, setFilter] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [consolidated, setConsolidated] = useState('')
  const [dataReport, setDataReport] = useState({})
  const [geraRelatorio, setGeraRelatorio] = useState(false)
  const [geraPrint, setGeraPrint] = useState(false)

  const formRef = useRef(null)

  const column = {
    backgroundColor: '#ba0000',
    color: 'white'
  }

  const row = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    textAlign: 'center',
    padding: '0px 2px'
  }

  const filterAudits = data => {
    let tempAudits = []

    for (let i = 0; i < data.store.length; i++) {
      tempAudits = [...tempAudits, ...data.store[i].audit]
    }

    setAudits(tempAudits)
    setFilteredData(tempAudits)
  }

  const requisitionFilterAudits = () => {
    if (filter === true) {
      if (brand !== '') {
        loadAudits()
        setFilter(false)
      }
    } else {
      let aux = audits

      if (store !== '') {
        const indexStore = data.store.findIndex(obj => obj.name === store)
        const tempAudit = data.store[indexStore].audit
        const tempFilteredAudits = aux.filter(obj1 =>
          tempAudit.some(obj2 => obj1.id === obj2.id)
        )

        aux = tempFilteredAudits
      }

      if (sector !== '') {
        aux = aux.filter(e => e.form.sector.name === sector)
      }

      if (year !== '') {
        aux = aux.filter(
          e => filterYear(e.created_at).toString() === year.toString()
        )
      }

      if (semester !== '') {
        aux = aux.filter(e => filterSemester(e.created_at) === semester)
      }

      if (consolidated !== '') {
        if (consolidated === 'true') {
          aux = aux.filter(e => e.consolidated_at !== null)
        } else {
          aux = aux.filter(e => e.consolidated_at === null)
        }
      }

      setFilteredData(aux)
    }
  }

  const loadAudits = async () => {
    try {
      setLoading(true)
      const response = await api.get('/v1/filters/brand', {
        params: {
          atributo: 'id',
          value: brand
        }
      })

      setData(response.data[0])
      setStores(response.data[0].store)
      filterAudits(response.data[0])
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  async function loadData() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const filterBrand = () => {
    const tempBrand = brands.find(e => e.id === brand).name
    return tempBrand
  }

  const filterSectors = () => {
    let tempdata = stores.find(e => e.name === store).audit
    const tempSectors = []

    for (let i = 0; i < tempdata.length; i++) {
      tempSectors.push(tempdata[i].form.sector)
    }

    tempdata = tempSectors.filter((object, index) => {
      return index === tempSectors.findIndex(e => e.id === object.id)
    })

    return tempdata
  }

  const maskDate = date => {
    const localData = new Date(date)

    const dia = String(localData.getDate()).padStart(2, '0')
    const mes = String(localData.getMonth() + 1).padStart(2, '0')
    const ano = String(localData.getFullYear())

    return `${dia}/${mes}/${ano}`
  }

  const findStore = id => {
    const indexStore = data.store.findIndex(item => {
      return item.audit.some(audit => audit.id === id)
    })

    return data.store[indexStore].name
  }

  const filterSemester = date => {
    const localData = new Date(date)
    const mes = localData.getMonth()

    if (mes >= 0 && mes <= 5) {
      return '1º Semestre'
    } else if (mes >= 6 && mes <= 11) {
      return '2º Semestre'
    } else {
      console.log('A data não pertence a nenhum semestre.')
    }
  }

  const filterYear = date => {
    const localData = new Date(date)
    const year = localData.getFullYear()

    return year
  }

  // const reportGenerate = item => {
  //   console.log(item)
  // }

  function formsResultGrap(parameters) {
    let nota

    if (parameters.length > 0) {
      const values = parameters[0]?.values
      const weights = parameters[0]?.weights
      let result = 0
      let weight = 0
      for (let c = 0; c < values?.length; c++) {
        result += values[c] * weights[c]
        weight += weights[c]
      }

      const calc = result / (weight / 10)

      nota = calc.toFixed(2)
    }

    const arr = parameters.map(parameter => {
      const notasSeries = [
        {
          name: 'Nota Atribuída',
          data: parameter.values
        }
      ]

      const notasOptions = {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '10'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        title: {
          text: `Setor: ${parameter.name}`
        },
        xaxis: {
          categories: parameter.questionsNumber,
          title: {
            text: 'Indicadores da Auditoria'
          }
        },
        yaxis: [
          {
            title: {
              text: 'Notas'
            },
            min: 0,
            max: 10
          }
        ],
        fill: {
          opacity: 1
        },
        tooltip: {}
      }

      const pesosOptions = {
        labels: parameter.questionsNumber
      }

      return {
        notas: {
          series: notasSeries,
          options: notasOptions
        },
        pesos: {
          series: parameter.weights,
          options: pesosOptions
        },
        name: parameter.sectorName
      }
    })

    const config = arr

    return { nota, config }
  }

  async function getAuditResult(item, type) {
    try {
      setLoading(true)
      const respAuditsAnswer = await api.get(`/v1/audits/answer/${item.id}`)

      const meta = []
      const copy = []
      const object = []
      const numbers = []
      const weights = []
      const values = []

      const resp = await api.get(`/v1/forms/form-question/${item.form.id}`)
      const questions = []

      resp.data.forEach((item, index) => {
        questions.push({ ...item, number: index + 1 })
      })

      questions.forEach(question => {
        respAuditsAnswer.data.forEach(item => {
          if (question.id === item.question.id) {
            copy.push({ ...question, answer: item.answer })
            numbers.push('Indicador ' + (parseInt(question.identifier) + 1))
          }
        })
      })

      copy.forEach((element, index) => {
        values.push(element.answer)
        weights.push(questions[index].value)
        object.push({ note: element.answer, weight: questions[index].value })
        meta.push(item.form.goal)
      })

      const parameters = [
        {
          name: item.form.sector.name,
          questionsNumber: numbers,
          goals: meta,
          values: values,
          weights: weights
        }
      ]

      const { nota, config } = formsResultGrap(parameters)

      const tempData = {
        brand: filterBrand(brand),
        dealership: findStore(item.id),
        department: item.form.sector.name,
        className: styles.imprimir,
        value: respAuditsAnswer.data,
        form: item.form.id,
        configs: config,
        nota: nota,
        identifier: item.code,
        audit: item,
        chief_auditor: item.chief_auditor || '',
        representatives: item.representatives || ''
      }

      setDataReport(tempData)

      // if (type === 1) {
      //   setGeraRelatorio(true)
      // } else {
      //   setGeraPrint(true)
      // }

      setLoading(false)

      History.push(`${process.env.PUBLIC_URL}/painel/imprimir`, tempData)
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.detail) {
        showErrors(error.response.data.detail)
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (store !== '') {
      setSectors(filterSectors())
    }
  }, [store])

  return (
    <div className={styles.containerRow}>
      <Sidebar opc={4}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form ref={formRef} className={styles.content}>
          <h1 className={styles.title_main}>Relatório</h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="brand"
                    onChange={e => setBrand(e.target.value)}
                    array={brands}
                    id="marca"
                    label="Selecione..."
                  />
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja</strong>
                </span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    name="store"
                    onChange={e => {
                      setStore(e.target.value)
                    }}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    {stores.map(e => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor</strong>
                </span>
                <div className={form.select}>
                  <Select
                    disabled={store === ''}
                    className={form.campo_input}
                    name="sector"
                    onChange={e => setSector(e.target.value)}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    {sectors.map(e => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Ano</span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    name="year"
                    onChange={e => setYear(e.target.value)}
                  >
                    <option value={''}>{'Selecione...'}</option>
                    <option>{new Date().getFullYear()}</option>
                    <option>{new Date().getFullYear() - 1}</option>
                    <option>{new Date().getFullYear() - 2}</option>
                    <option>{new Date().getFullYear() - 3}</option>
                  </Select>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Semestre</span>
                <div className={form.select}>
                  <Select
                    disabled={filter}
                    className={form.campo_input}
                    onChange={e => setSemester(e.target.value)}
                    name="semester"
                  >
                    <>
                      <option value={''}>{'Selecione...'}</option>
                      <option value={'1º Semestre'}>{'1º Semestre'}</option>
                      <option value={'2º Semestre'}>{'2º Semestre'}</option>
                    </>
                  </Select>
                </div>
              </div>

              <div className={(styles.flex_column_bet, form.input_w30)}>
                <span className={form.title_input}>Selecione o Status</span>
                <div className={form.select}>
                  <Select
                    disabled={
                      filter
                      // !finished
                    }
                    className={form.campo_input}
                    onChange={e => {
                      setConsolidated(e.target.value)
                    }}
                    name="situation"
                  >
                    <option value={''}>{'Selecione...'}</option>
                    <option value={'false'}>{'Não Consolidadas'}</option>
                    <option value={'true'}>{'Consolidadas'}</option>
                  </Select>
                </div>
              </div>
            </div>

            <div className={styles.btn_bunscar}>
              <button
                className={form.btn}
                type="button"
                onClick={() => {
                  // updateAudits(formRef.current.getFieldValue('sector'))
                  requisitionFilterAudits()
                }}
              >
                Ok
              </button>
            </div>
            {filteredData.length > 0 && (
              <div style={{ overflowX: 'scroll' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={column}>Código</th>
                      <th style={column}>Criação</th>
                      <th style={column}>Marca</th>
                      <th style={column}>Loja</th>
                      <th style={column}>Setor</th>
                      <th style={column}>Semestre</th>
                      <th style={column}>Ano</th>
                      <th style={column}>Status</th>
                      <th style={column}>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td style={row}>{item.code}</td>
                        <td style={row}>{maskDate(item.created_at)}</td>
                        <td style={row}>{filterBrand()}</td>
                        <td style={row}>{findStore(item.id)}</td>
                        <td style={row}>{item.form.sector.name}</td>
                        <td style={row}>{filterSemester(item.created_at)}</td>
                        <td style={row}>{filterYear(item.created_at)}</td>
                        <td style={row}>
                          {item.consolidated_at === null
                            ? 'Não consolidada'
                            : 'Consolidada'}
                        </td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'black',
                            textAlign: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '2px'
                          }}
                        >
                          <div
                            className="btn-edit"
                            onClick={() => getAuditResult(item, 1)}
                          >
                            <FiFileText />
                            <p style={{ fontSize: '12px' }}>Gerar Reltório</p>
                          </div>
                          {/* <div
                            className="btn-edit"
                            onClick={() => getAuditResult(item, 2)}
                          >
                            <MdDownload />
                            <p style={{ fontSize: '12px' }}>Download</p>
                          </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Form>
      </div>

      <Loading open={loading} />
      {geraRelatorio === true && (
        <div className={form.modal}>
          <div
            className={form.bg_modal}
            onClick={() => {
              setGeraRelatorio(false)
            }}
          />
          <GenerateReport
            brand={dataReport.brand}
            dealership={dataReport.dealership}
            department={dataReport.department}
            className={dataReport.className}
            value={dataReport.value}
            form={dataReport.form}
            configs={dataReport.configs}
            nota={dataReport.nota}
            identifier={dataReport.identifier}
            audit={dataReport.audit}
            chief_auditor={dataReport.chief_auditor}
            representatives={dataReport.representatives}
          />
        </div>
      )}
      {geraPrint === true && (
        <div className={form.modal}>
          <div
            className={form.bg_modal}
            onClick={() => {
              setGeraPrint(false)
            }}
          />

          <GenerateReport
            brand={dataReport.brand}
            dealership={dataReport.dealership}
            department={dataReport.department}
            className={dataReport.className}
            value={dataReport.value}
            form={dataReport.form}
            configs={dataReport.configs}
            nota={dataReport.nota}
            identifier={dataReport.identifier}
            audit={dataReport.audit}
            chief_auditor={dataReport.chief_auditor}
            representatives={dataReport.representatives}
            download={true}
            onClose={() => setGeraPrint(false)}
            name={dataReport.audit.name}
          />
        </div>
      )}
    </div>
  )
}

export default painelReport
