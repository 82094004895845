import React, { useEffect, useState } from 'react'
import History from '../../../services/history'
import Loading from '../../../components/alertLoading'
import NavbarPainel from '../../../components/navbarPainel'
import Sidebar from '../../../components/sidebar'
import styles from '../../../styles/painel.module.css'

import { RiShoppingCartLine } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { AiOutlineRise } from 'react-icons/ai'
import api from '../../../services/api'

export default function painelCad() {
  const [loading, setLoading] = useState(false)

  const [statistics, setStatistics] = useState()
  async function getStatistics() {
    try {
      setLoading(true)
      const response = await api.get(`/v1/others/statistics`)
      setLoading(false)
      setStatistics(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getStatistics()
  }, [])
  return (
    <div className={styles.containerRow}>
      <Sidebar opc={1}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Cadastrar</h1>
          <div className={styles.flex_column_bet}>
            <div className={styles.flex_row_ev}>
              <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
                <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                  <div className={styles.img_cadastro}>
                    <RiShoppingCartLine></RiShoppingCartLine>
                  </div>
                  <div className={styles.name_cadastro}>
                    <p>Estabelecimento</p>
                    <div className={styles.barra}>_ _ _</div>
                  </div>
                  <p className={styles.resume_cadastro}>
                    Realize cadastros de Marcas, Lojas e Setores para realização
                    da auditoria...
                  </p>
                  <div className={styles.btn_cadastro}>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        History.push(
                          `${process.env.PUBLIC_URL}/painel/cadastro/estabelecimento`
                        )
                      }}
                    >
                      Cadastrar
                      <HiArrowNarrowRight></HiArrowNarrowRight>
                    </button>
                  </div>
                </div>
              </div>
              <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
                <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                  <div className={styles.img_cadastro}>
                    <AiOutlineRise></AiOutlineRise>
                  </div>
                  <div className={styles.name_cadastro}>
                    <p>Formulário</p>
                    <div className={styles.barra}>_ _ _</div>
                  </div>
                  <p className={styles.resume_cadastro}>
                    Realize cadastros de perguntas e pesos realizadas na
                    Auditoria...
                  </p>
                  <div className={styles.btn_cadastro}>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        History?.push(
                          `${process.env.PUBLIC_URL}/painel/cadastro/formulario`
                        )
                      }}
                    >
                      Cadastrar
                      <HiArrowNarrowRight></HiArrowNarrowRight>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_center}>
              <div className={styles.info_cadastro}>
                <p className={styles.info_title}>Total de Marcas Cadastradas</p>
                <p className={styles.info_value}>{statistics?.brands}</p>
              </div>
              <div className={styles.info_cadastro}>
                <p className={styles.info_title}>Total de Lojas Cadastradas</p>
                <p className={styles.info_value}>{statistics?.stores}</p>
              </div>
              <div className={styles.info_cadastro}>
                <p className={styles.info_title}>
                  Total de Setores Cadastrados
                </p>
                <p className={styles.info_value}>{statistics?.sectors}</p>
              </div>
              <div className={styles.info_cadastro}>
                <p className={styles.info_title}>
                  Total de Formularios Cadastrados
                </p>
                <p className={styles.info_value}>{statistics?.forms}</p>
              </div>
              <div className={styles.info_cadastro}>
                <p className={styles.info_title}>
                  Total de Auditorias Finalizadas
                </p>
                <p className={styles.info_value}>{statistics?.audits}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading open={loading} />
    </div>
  )
}
