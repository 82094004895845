import React from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import Loading from '../alertLoading'
import Input from '../form/input'
import { Form } from '@unform/web'
import Select from '../form/Select'
import { ModalsContext } from '../../providers/modals'
import api from '../../services/api'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
export default function EditSector(props) {
  const [loading, setLoading] = React.useState(false)
  const { setEditingSector } = React.useContext(ModalsContext)

  async function sectorSubmit(data) {
    try {
      const parameters = {
        name: data.name
      }
      setLoading(true)
      await api.put(`/v1/stores/sector/${data.sector}`, parameters)
      toast.success('O Setor foi editado com sucesso!')
      setEditingSector(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
        toast.error('Falha ao editar setor!')
        setEditingSector(false)
      }
    }
  }
  return (
    <Form className={form.containerCad} onSubmit={sectorSubmit}>
      <h1 className={form.titleForm}>
        Editar <strong> Setor</strong>
      </h1>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Nome Antigo</span>
          <div className={form.select}>
            <Select disabled className={form.campo_input} name="sector">
              <option value={props.sectorId}>{props.sectorName}</option>
            </Select>
          </div>
        </div>
      </div>
      <div className={form.line}></div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}> Novo Nome</span>
          <Input className={form.campo_input} name="name" type="text" />
        </div>

        <div className={form.btn_cadastro}>
          <button className={form.btn}>Editar</button>
        </div>
      </div>
      <Loading open={loading} />
    </Form>
  )
}
EditSector.propTypes = {
  sectorId: PropTypes.string,
  sectorName: PropTypes.string
}
