import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../providers/auth'
import History from '../../services/history'

const Loading = () => {
  const query = new URLSearchParams(useLocation().search)
  const { setUsername } = useContext(AuthContext)

  const init = async () => {
    try {
      const token = query.get('hash')
      const apiLogin = axios.create({
        baseURL: 'https://auth.viacometa.com.br/api'
      })
      apiLogin.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      })
      const response = await apiLogin.get('/auth/me')
      localStorage.setItem('auditoria@token', token)

      localStorage.setItem('auditoria@userName', response?.data?.nome)
      setUsername(response.data.nome)
      History.push(`${process.env.PUBLIC_URL}/auditoria`)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <>
      <p>Loading...</p>
    </>
  )
}

const PainelLoading = () => {
  const query = new URLSearchParams(useLocation().search)
  const { setUsername } = useContext(AuthContext)

  const init = async () => {
    try {
      const token = query.get('hash')
      const apiLogin = axios.create({
        baseURL: 'https://auth.viacometa.com.br/api'
      })
      apiLogin.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      })
      const response = await apiLogin.get('/auth/me')
      localStorage.setItem('auditoria@token', token)

      localStorage.setItem('auditoria@userName', response?.data?.nome)
      setUsername(response.data.nome)
      History.push(`${process.env.PUBLIC_URL}/painel`)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <>
      <p>Loading...</p>
    </>
  )
}

export { PainelLoading, Loading }
