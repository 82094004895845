import React, { useEffect, useState, useRef, useContext } from 'react'
import NavbarPainel from '../../../../../components/navbarPainel'
import Sidebar from '../../../../../components/sidebar'
import styles from '../../../../../styles/painel.module.css'
import api from '../../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Select from '../../../../../components/form/Select'
import { Form } from '@unform/web'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import Loading from '../../../../../components/alertLoading'
import EditStore from '../../../../../components/EditarForms/Loja'
import form from '../../../../../styles/Formulario.module.css'
import { ModalsContext } from '../../../../../providers/modals'
import { cepAddMask } from '../../../../../services/functions'
import Swal from 'sweetalert2'
export default function tabelaLoja() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    init()
  }, [])
  const { editingStore, setEditingStore } = useContext(ModalsContext)

  const formRef = useRef(null)
  const [brands, setBrands] = useState([])
  async function init() {
    try {
      setLoading(true)
      const resp = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(resp.data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (atualBrand) {
      updateStores(atualBrand)
    }
  }, [editingStore])
  const [atualBrand, setAtualBrand] = useState('')
  function Brands() {
    return (
      <div className={(styles.flex_column_bet, form.input_w100)}>
        <span className={form.title_input}>Selecione a Marca</span>
        <div className={form.select}>
          <Select
            name="brand"
            value={atualBrand}
            className={form.campo_input}
            onChange={e => {
              updateStores(e.target.value)
              setAtualBrand(e.target.value)
            }}
          >
            <option>Marca</option>
            {brands.map((brand, index) => {
              return (
                <option key={index} value={brand.id}>
                  {brand.name}
                </option>
              )
            })}
          </Select>
        </div>
      </div>
    )
  }
  const [stores, setStores] = useState([])

  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setLoading(false)
      setStores(response?.data)
      formRef.current.setFieldValue('brand', b)
    } catch (err) {
      console.log(err)
    }
  }
  const [currentStore, setCurrentStore] = useState()
  function EditaLoja() {
    return (
      <>
        {editingStore && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setEditingStore(false)
              }}
            ></div>
            <EditStore store={currentStore}></EditStore>
          </div>
        )}
      </>
    )
  }

  function TableCompanies() {
    return stores.map((item, index) => {
      const n = index + 1
      return (
        <tr key={index}>
          <td className="grow1">{n}</td>
          <td className="grow2">{item.name}</td>
          <td className="grow1 td-opc">
            <div
              className="btn-edit"
              onClick={() => {
                setCurrentStore({
                  ...item,
                  cep: cepAddMask(item.cep)
                })
                setEditingStore(true)
              }}
            >
              <FiEdit2 />
              <p>Editar</p>
            </div>
            <div
              className="btn-delete"
              onClick={() => {
                Swal.fire({
                  title: 'Deseja realmente excluir esta loja?',
                  text: 'Todos os dados ligados a esta loja serão perdidos e a operação não poderá ser desfeita.',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim, Excluir'
                }).then(result => {
                  if (result.isConfirmed) {
                    removeStore(item.id)
                  }
                })
              }}
            >
              <FiTrash2 />
              <p>Excluir</p>
            </div>
          </td>
        </tr>
      )
    })
  }

  async function removeStore(storeId) {
    try {
      setLoading(true)
      const response = await api.delete(`/v1/stores/${storeId}`)
      setLoading(false)
      console.info(response)
      Swal.fire('A loja foi excluída com sucesso!!!', '', 'success')
      setStores([])
      init()
    } catch (error) {
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }
  return (
    <div className={styles.containerRow}>
      <EditaLoja />
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Visualizar</h1>
          <div className={styles.containerTable}>
            <div className={styles.containerGrafico}>
              <h1 className="title-info-table">Visualizar Todas as Lojas</h1>
              <Form ref={formRef}>
                <Brands></Brands>
                <div className="search-body">
                  <table className="search-table">
                    <thead>
                      <tr className="title-table">
                        <th className="grow1">Nº</th>
                        <th className="grow2">Loja</th>
                        <th className="grow1">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <TableCompanies></TableCompanies>
                    </tbody>
                  </table>
                </div>
                <Loading open={loading} />
                {/* <table className={styles.tabelaBrands}>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stores.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name} </td>
                          <td>
                            <FiEdit2
                              onClick={() => {
                                setCurrentStore({
                                  ...item,
                                  cep: cepAddMask(item.cep)
                                })
                                setEditingStore(true)
                              }}
                            ></FiEdit2>
                            <FiTrash2></FiTrash2>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
