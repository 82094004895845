const formatMedia = (Comercial, Administrativo, PosVenda) => {
  const result = []

  if (areasMedia(Comercial) !== -1) {
    result.push({ x: 'Comercial', y: areasMedia(Comercial).toFixed(2) })
  }
  if (areasMedia(Administrativo) !== -1) {
    result.push({
      x: 'Administrativo',
      y: areasMedia(Administrativo).toFixed(2)
    })
  }
  if (areasMedia(PosVenda) !== -1) {
    result.push({ x: 'Pós venda', y: areasMedia(PosVenda).toFixed(2) })
  }
  return result
}

const formatMediaGeral = sectors => {
  const result = []
  if (areasMedia(sectors) !== -1) {
    result.push({ x: 'Media Geral', y: areasMedia(sectors).toFixed(2) })
  }
  return result
}

const areasMedia = area => {
  let soma = 0
  area.forEach(item => {
    soma += item.value
  })

  return soma / area.length || -1
}

const areasAverageGraph = elements => {
  const Comercial = []
  const Administrativo = []
  const PosVenda = []

  elements.forEach(sector => {
    const name = sector.name
    if (sector.name === 'Setor Comercial') {
      Comercial.push(sector)
    } else if (
      name === 'Administrativo Financeiro' ||
      name === 'Faturamento e Estoque' ||
      name === 'Contabilidade e DP'
    ) {
      Administrativo.push(sector)
    } else if (name === 'Peças' || name === 'Oficina') {
      PosVenda.push(sector)
    }
  })

  return formatMedia(Comercial, Administrativo, PosVenda).concat(
    formatMediaGeral(elements)
  )
}

export { areasMedia, formatMedia, formatMediaGeral, areasAverageGraph }
