import React from 'react'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'
import form from '../../../../styles/Formulario.module.css'
import Marca from '../../../../components/cadastro/novaMarca'
import Loja from '../../../../components/cadastro/novaLoja'
import Setor from '../../../../components/cadastro/novoSetor'
import { RiHomeGearLine, RiBuilding2Line } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { AiFillShop } from 'react-icons/ai'
import { ModalsContext } from '../../../../providers/modals'
import { ToastContainer } from 'react-toastify'

export default function CadastroEstab() {
  const {
    openMarca,
    setOpenMarca,
    openLoja,
    setOpenLoja,
    openSetor,
    setOpenSetor
  } = React.useContext(ModalsContext)

  function ModalCad() {
    return (
      <>
        {openMarca && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setOpenMarca(false)
              }}
            ></div>
            <Marca></Marca>
          </div>
        )}
        {openLoja && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setOpenLoja(false)
              }}
            ></div>
            <Loja></Loja>
          </div>
        )}
        {openSetor && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setOpenSetor(false)
              }}
            ></div>
            <Setor></Setor>
          </div>
        )}
      </>
    )
  }

  return (
    <div className={styles.containerRow}>
      <Sidebar opc={1}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Cadastrar</h1>
          <div className={styles.flex_row_bet}>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiBuilding2Line></RiBuilding2Line>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Nova Marca</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      setOpenMarca(true)
                    }}
                  >
                    Cadastrar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <AiFillShop></AiFillShop>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Nova Loja</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      setOpenLoja(true)
                    }}
                  >
                    Cadastrar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiHomeGearLine></RiHomeGearLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Novo Setor</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      setOpenSetor(true)
                    }}
                  >
                    Cadastrar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCad></ModalCad>
    </div>
  )
}
