import React, { useState, useRef, useEffect, useContext } from 'react'
import NavbarPainel from '../../../../../components/navbarPainel'
import Sidebar from '../../../../../components/sidebar'
import styles from '../../../../../styles/painel.module.css'
import api from '../../../../../services/api'
import { Form } from '@unform/web'
import { ToastContainer, toast } from 'react-toastify'
import Loading from '../../../../../components/alertLoading'
import Select from '../../../../../components/form/Select'
import { FiTrash2, FiEdit2 } from 'react-icons/fi'
import { ModalsContext } from '../../../../../providers/modals'
import EditSector from '../../../../../components/EditarForms/Setor'
import form from '../../../../../styles/Formulario.module.css'
import Swal from 'sweetalert2'

export default function tabelaSetor() {
  const [loading, setLoading] = useState(false)

  const { editingSector, setEditingSector } = useContext(ModalsContext)
  useEffect(() => {
    init()
  }, [])

  const formRef = useRef(null)
  const [brands, setBrands] = useState([])
  async function init() {
    try {
      setLoading(true)
      const resp = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(resp.data)
    } catch (err) {
      setLoading(false)
    }
  }
  const [atualBrand, setAtualBrand] = useState('')
  function Brands() {
    return (
      <div className={(styles.flex_column_bet, form.input_w100)}>
        <span className={form.title_input}>Selecione a Marca</span>
        <div className={form.select}>
          <Select
            name="brand"
            className={form.campo_input}
            value={atualBrand}
            onChange={e => {
              setStores([])
              setSectors([])
              setAtualStores('clear')
              updateStores(e.target.value)
              setAtualBrand(e.target.value)
            }}
          >
            <option>Marca</option>
            {brands.map((brand, index) => {
              return (
                <option key={index} value={brand.id}>
                  {brand.name}
                </option>
              )
            })}
          </Select>
        </div>
      </div>
    )
  }
  const [stores, setStores] = useState([])

  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setLoading(false)
      setStores(response?.data)
      formRef.current.setFieldValue('brand', b)
    } catch (err) {
      setLoading(false)
    }
  }
  const [atualStores, setAtualStores] = useState()

  function Stores() {
    return (
      <div className={(styles.flex_column_bet, form.input_w100)}>
        <span className={form.title_input}>Selecione a Loja</span>
        <div className={form.select}>
          <Select
            className={form.campo_input}
            name="stores"
            value={atualStores}
            onChange={e => {
              updateSectors(e.target.value)
              setAtualStores(e.target.value)
            }}
          >
            <option value={'clear'}>Loja</option>
            {stores.map((store, index) => {
              return (
                <option key={index} value={store.id}>
                  {store.name}
                </option>
              )
            })}
          </Select>
        </div>
      </div>
    )
  }

  const [sectors, setSectors] = useState([])
  async function updateSectors(s) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setLoading(false)
      setSectors(response?.data)
      formRef.current.setFieldValue('stores', s)
    } catch (err) {
      setLoading(false)
    }
  }
  const [sectorId, setSectorId] = useState()
  const [sectorName, setSectorName] = useState()
  function EditaSetor() {
    return (
      <>
        {editingSector && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setEditingSector(false)
              }}
            ></div>
            <EditSector
              sectorId={sectorId}
              sectorName={sectorName}
            ></EditSector>
          </div>
        )}
      </>
    )
  }

  function TableCompanies() {
    return sectors.map((item, index) => {
      const n = index + 1
      return (
        <tr key={index}>
          <td className="grow1">{n}</td>
          <td className="grow2">{item.name}</td>
          <td className="grow1 td-opc">
            <div
              className="btn-edit"
              onClick={() => {
                setEditingSector(true)
                setSectorId(item.id)
                setSectorName(item.name)
              }}
            >
              <FiEdit2 />
              <p>Editar</p>
            </div>
            <div
              className="btn-delete"
              onClick={() => {
                Swal.fire({
                  title: 'Deseja realmente excluir este setor?',
                  text: 'Todos os dados ligados a este setor serão perdidos e a operação não poderá ser desfeita.',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim, Excluir'
                }).then(result => {
                  if (result.isConfirmed) {
                    removeSector(item.id)
                  }
                })
              }}
            >
              <FiTrash2 />
              <p>Excluir</p>
            </div>
            <div
              className="btn-delete"
              onClick={() => {
                Swal.fire({
                  title: 'Desvincular esse setor?',
                  text: '',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim'
                }).then(result => {
                  if (result.isConfirmed) {
                    unlinksSector(item.id)
                  }
                })
              }}
            >
              <FiTrash2 />
              <p>Desvincular</p>
            </div>
          </td>
        </tr>
      )
    })
  }

  async function removeSector(sectorId) {
    try {
      setLoading(true)
      const response = await api.delete(`/v1/stores/sector/${sectorId}`)
      console.info(response)
      setLoading(false)
      Swal.fire('O setor foi excluído com sucesso!!!', '', 'success')
      setSectors([])
      setStores([])
      init()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  async function unlinksSector(sectorId) {
    try {
      setLoading(true)
      const response = await api.patch(
        `/v1/stores/sector/${sectorId}?store_id=${atualStores}`
      )
      console.info(response)
      setLoading(false)
      Swal.fire('O setor foi desvinculado!', '', 'success')
      setSectors([])
      setStores([])
      init()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  return (
    <div className={styles.containerRow}>
      <EditaSetor />
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Visualizar</h1>
          <div className={styles.containerTable}>
            <div className={styles.containerGrafico}>
              <h1 className="title-info-table">Visualizar Todas os Setores</h1>
              <Form ref={formRef}>
                <Brands></Brands>
                <Stores></Stores>
                <table className="search-table">
                  <thead>
                    <tr className="title-table">
                      <th className="grow1">Nº</th>
                      <th className="grow2">Setor</th>
                      <th className="grow1">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableCompanies></TableCompanies>
                  </tbody>
                </table>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Loading open={loading} />
    </div>
  )
}
