import React, { useEffect, useState } from 'react'
import styles from '../../styles/painel.module.css'
import form from '../../styles/Formulario.module.css'
import { Form } from '@unform/web'
import Loading from '../alertLoading'
import Input from '../form/input'
import api from '../../services/api'
import * as Yup from 'yup'
// import { ModalsContext } from '../../providers/modals'
import {
  cepAddMask,
  removeMask,
  removeSpacesFromBeginning,
  removeSpacesFromBeginningAndEnd
} from '../../services/functions'
import { toast } from 'react-toastify'

function NovaLoja() {
  const [loading, setLoading] = useState(false)
  const [marcaGroup, setMarcaGroup] = useState([])
  // const { setOpenLoja } = React.useContext(ModalsContext)
  async function storeSubmit(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome é obrigatório!'),
        cep: Yup.string().required('O campo cep é obrigatório!'),
        street: Yup.string().required('O campo rua é obrigatório!'),
        number: Yup.string()
          .required('O campo número é obrigatório!')
          .test(
            'len',
            'O campo número deve ter no máximo 5 caracteres',
            val => val.length <= 5
          ),
        district: Yup.string().required('O campo bairro é obrigatório!'),
        complement: Yup.string().required('O campo complemento é obrigatório!'),
        city: Yup.string().required('O campo cidade é obrigatório! '),
        uf: Yup.string().required('O campo UF é obrigatório!'),
        georeference: Yup.string()
      })
      await schema.validate(data, {
        abortEarly: false
      })

      const brandList = []
      marcaGroup.forEach(item => {
        brandList.push(item.id)
      })
      const parameters = {
        brandListId: brandList,
        store_in: data
      }

      parameters.store_in.cep = removeMask(data.cep)
      setLoading(true)
      await api.post('/v1/stores/', parameters)
      setLoading(false)
      // setOpenLoja(false)
      toast.success('Loja cadastrada com sucesso!!')
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(erro => {
          toast.error(erro.message)
        })
      } else {
        const result = err.response
        if (result !== undefined) {
          const errs = result.data.detail

          errs.forEach(err => {
            toast.error(err.msg)
          })
        }
      }
    }
  }

  const [brands, setBrands] = useState([])
  useEffect(() => {
    init()
  }, [])

  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setBrands(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const [cep, setCep] = useState('')
  return (
    <Form onSubmit={storeSubmit} className={form.containerCad}>
      <h1 className={form.titleForm}>
        Cadastrar nova <strong> Loja</strong>
      </h1>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Selecione a Marca</span>
          <div className={form.select}>
            <select
              className={form.campo_input}
              name="setor"
              id="setor"
              onChange={e => {
                const aux = []
                marcaGroup.forEach(item => {
                  aux.push(item?.id)
                })
                aux.push(e.target.value)
                const newAux = []
                brands.forEach(brand => {
                  aux.forEach(item => {
                    if (brand.id === item) {
                      newAux.push(brand)
                    }
                  })
                })
                setMarcaGroup(newAux)
              }}
            >
              <option>Selecione...</option>

              {brands.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className={styles.flex_row_bet}>
          {marcaGroup.map((item, index) => {
            return (
              <div key={index} className={styles.flex_row_around}>
                <p>{item.name}</p>
                <span
                  onClick={() => {
                    const lista = []
                    let count = 0
                    marcaGroup.forEach(valor => {
                      if (valor.id !== item.id) {
                        lista[count] = valor
                        count++
                      }
                    })
                    setMarcaGroup(lista)
                  }}
                >
                  X
                </span>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Nome</span>
          <Input
            className={form.campo_input}
            type="text"
            name="name"
            maxLength="80"
            onChange={e => {
              e.target.value = removeSpacesFromBeginning(
                e.target.value,
                e.target
              )
            }}
            onBlur={e => {
              e.target.value = removeSpacesFromBeginningAndEnd(e.target.value)
            }}
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w70)}>
          <span className={form.title_input}>Rua</span>
          <Input
            className={form.campo_input}
            type="text"
            name="street"
            maxLength="100"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w30)}>
          <span className={form.title_input}>Nº</span>
          <Input
            className={form.campo_input}
            type="text"
            name="number"
            id=""
            maxLength="5"
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w70)}>
          <span className={form.title_input}>Cidade</span>
          <Input
            className={form.campo_input}
            type="text"
            name="city"
            maxLength="80"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w30)}>
          <span className={form.title_input}>UF</span>
          <Input
            className={form.campo_input}
            type="text"
            name="uf"
            maxLength="2"
          />
        </div>
      </div>

      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>Bairro</span>
          <Input
            className={form.campo_input}
            type="text"
            name="district"
            maxLength="80"
          />
        </div>
        <div className={(styles.flex_column_bet, form.input_w50)}>
          <span className={form.title_input}>CEP</span>
          <Input
            className={form.campo_input}
            // maxLength="8"
            value={cep}
            onChange={e => {
              const valor = cepAddMask(e.target.value)
              setCep(valor)
            }}
            maxLength="10"
            type="text"
            name="cep"
          />
        </div>
      </div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Complemento</span>
          <Input
            className={form.campo_input}
            type="text"
            name="complement"
            maxLength="100"
          />
        </div>
      </div>
      <div className={styles.flex_row_bet}>
        <div className={(styles.flex_column_bet, form.input_w100)}>
          <span className={form.title_input}>Referencia</span>
          <Input className={form.campo_input} type="text" name="georeference" />
        </div>
      </div>

      <Loading open={loading} />

      <div className={form.btn_cadastro}>
        <button className={form.btn}>Cadastrar</button>
      </div>
    </Form>
  )
}

export default NovaLoja
