import React from 'react'
import Proptypes from 'prop-types'
import styles from '../../styles/questionario.module.css'

function PreviewAudit({ note, excerpt, weight, comment, identifier }) {
  const questionNote = note
  const questionExcerpt = excerpt
  const questionWeight = weight
  const questionComment = comment

  const formatE = text => {
    let aux = text.replace(/\n/g, '<br/>')
    aux = aux.replace(/ /g, ' ')
    return aux
  }

  return (
    <div className="question-item box-light">
      <div className={styles.question_tittle}>
        {/* <div className="question-number">Indicador {questionNumber}</div> */}
      </div>
      <div className="question-excerpt">
        <strong>Indicador: {parseInt(identifier) + 1}</strong>
        <p
          dangerouslySetInnerHTML={{
            __html: formatE(
              questionExcerpt || 'O Indicador da Auditoria não foi Informado'
            )
          }}
        ></p>
      </div>
      <div className="question-excerpt">
        <strong>Evidência da Auditoria:</strong>
        <p
          dangerouslySetInnerHTML={{
            __html: formatE(
              questionComment || 'Evidência da Auditoria não foi Informada'
            )
          }}
        ></p>
      </div>
      <div className={styles.preview_footer}>
        <div>
          <label>Peso: </label>
          <p>{questionWeight}</p>
        </div>
        <div>
          <label>Nota atribuída: </label>
          <p>{questionNote}</p>
        </div>
      </div>
    </div>
  )
}
PreviewAudit.propTypes = {
  number: Proptypes.number,
  note: Proptypes.number,
  excerpt: Proptypes.string,
  weight: Proptypes.number,
  comment: Proptypes.string,
  identifier: Proptypes.number
}
export default PreviewAudit
