/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import '../../relatorio.css'
// import Style from '../../styles/painel.module.css'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import Loading from '../alertLoading'
import api from '../../services/api'
import LogoRisc from '../../assets/risc.svg'
import LogoAudi from '../../assets/Imagem1.png'
import Chart from 'react-apexcharts'
import { jsPDF as JSPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import * as Styles from './styles'

export default function generateReport(props) {
  // const { setEditingMarca } = React.useContext(ModalsContext)
  const [loading, setLoading] = useState(false)
  const [dados, setDados] = useState([])
  const [questions, setQuestions] = useState([])
  const [idForm, setIdForm] = useState('')
  const [configs, setConfigs] = useState([])

  const [questionRefs, setQuestionRefs] = React.useState([])
  const [chartRefs, setChartRefs] = React.useState([])
  const [ready, setReady] = React.useState(false)

  // const url = `http://192.168.30.43/api`
  // const url = `${process.env.REACT_APP_API_URL}`
  // const url = `https://apps.risc.unemat.br/dev/auditoria/api`

  React.useEffect(() => {
    // Inicializa (ou reinicializa) chartRefs com base no tamanho de configsData
    setChartRefs(
      Array(configs.length)
        .fill(null)
        .map(() => React.createRef())
    )
  }, [configs])

  React.useEffect(() => {
    setQuestionRefs(
      Array(questions.length)
        .fill(null)
        .map(() => React.createRef())
    )
  }, [questions])

  async function init() {
    if (idForm !== '' && idForm !== null && idForm !== undefined) {
      try {
        setLoading(true)
        const resp = await api.get(`/v1/forms/form-question/${idForm}`)
        const aux = []
        resp.data.forEach(item => {
          dados.forEach(dados => {
            if (item.id === dados.question.id) {
              const note = dados.note
              const photos = dados.photos
              const answer = dados.answer
              aux.push({ ...item, note, photos, answer })
            }
          })
        })
        setQuestions(aux)
        setLoading(false)
        if (props?.download && props.download === true) {
          setReady(true)
        }
        props = ''
      } catch (error) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    init()
  }, [idForm])

  useEffect(() => {
    setDados(props?.value)
    setIdForm(props?.form)
    setConfigs(props?.configs)
  }, [props])

  useEffect(() => {
    if (ready === true) {
      generatePDF()
    }
  }, [ready])

  const formatE = text => {
    let aux = text.replace(/\n/g, '<br/>')
    aux = aux.replace(/ /g, ' ')
    return aux
  }

  function ReturnQuestions({ state, questions }) {
    return (
      <>
        {questions.map((item, index) => {
          return (
            <div style={Styles.resumo} key={index} ref={state[index]}>
              <div className="row-quest">
                <div className="part1">
                  <p className="font-2">
                    Indicador: {parseInt(item?.identifier) + 1}
                  </p>

                  {/* <p className="font-1">
                    {item.enunciation
                      ? item.enunciation
                      : 'Indicador não foi Cadastrado'}
                  </p> */}
                  <p
                    className="font-1"
                    dangerouslySetInnerHTML={{
                      __html: formatE(
                        item.enunciation
                          ? item.enunciation
                          : 'Indicador não foi Cadastrado'
                      )
                    }}
                  ></p>
                </div>
                <div className="part2">
                  <p className="font-2">nota</p>
                  <p className="font-1">{item.answer}</p>
                </div>
                <div className="part2">
                  <p className="font-2">peso</p>
                  <p className="font-1">{item.value}</p>
                </div>
              </div>
              <div className="row-quest">
                <div className="part-3">
                  <p className="font-2">Evidência da Auditoria</p>
                  <p
                    className="font-1"
                    dangerouslySetInnerHTML={{ __html: formatE(item.note) }}
                  ></p>
                </div>
              </div>
              <div className="row-quest">
                <div className="part-3">
                  <p className="font-2">Fotos da Evidência</p>
                  <div className="row-quest">
                    {item.photos.map((element, index) => {
                      if (
                        element !== '' &&
                        element !== null &&
                        element !== undefined
                      ) {
                        return (
                          <div key={index}>
                            <img
                              className="imgEvidencia"
                              style={{
                                width: '750px', // width foto
                                height: 'auto'
                              }}
                              src={`${process.env.REACT_APP_API_URL}/v1/audits/answer-photo/${element}?id=${element}`}
                            />
                          </div>
                        )
                      } else {
                        return 'Não foi Registrada Nenhuma Foto'
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  function ReturnChart({ state, configs }) {
    return (
      <div className="graficoRelatorio" style={{ height: 'auto' }}>
        <p className="txt_info">Notas e Pesos da Auditoria</p>
        {configs.map((item, index) => {
          return (
            <div
              className="graficoRelatorio"
              style={{ height: 'auto' }}
              key={index}
              ref={state[index]}
            >
              <div className="campGraficos">
                <div className="graficoMultiReport">
                  <h2>Nota por Indicador</h2>
                  <Chart
                    className="svg_auto"
                    options={item.notas.options}
                    series={item.notas.series}
                    type="bar"
                  ></Chart>
                </div>
                <div className="graficoMultiReport">
                  <h2>Peso por Indicador</h2>
                  <Chart
                    className="svg_auto"
                    options={{
                      ...item.pesos.options,
                      grid: {
                        padding: {
                          top: 10,
                          right: 10,
                          bottom: 10,
                          left: 10
                        }
                      },
                      chart: {
                        animations: {
                          enabled: false
                        }
                      },
                      legend: {
                        position: 'left',
                        fontSize: '12px'
                      }
                    }}
                    series={item.pesos.series}
                    type={'donut'}
                  ></Chart>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const headerRef = React.useRef(null)
  const footerRef = React.useRef(null)

  const areImagesLoaded = element => {
    const images = element.querySelectorAll('img')
    for (const img of images) {
      if (!img.complete) {
        return false
      }
    }
    return true
  }

  const generatePDF = async () => {
    setLoading(true)
    const waitForImages = ref => {
      return new Promise(resolve => {
        if (areImagesLoaded(ref.current)) {
          resolve()
        } else {
          const checkInterval = setInterval(() => {
            if (areImagesLoaded(ref.current)) {
              clearInterval(checkInterval)
              resolve()
            }
          }, 50) // verifica a cada 50ms
        }
      })
    }

    const materialPdf = []
    let tempData = await html2canvas(headerRef.current)
    materialPdf.push(tempData)

    for (let i = 0; i < questionRefs.length; i++) {
      await waitForImages(questionRefs[i])
      tempData = await html2canvas(questionRefs[i].current)
      materialPdf.push(tempData)
    }

    for (let i = 0; i < chartRefs.length; i++) {
      tempData = await html2canvas(chartRefs[i].current)
      materialPdf.push(tempData)
    }

    tempData = await html2canvas(footerRef.current)
    materialPdf.push(tempData)

    const pdf = new JSPDF()

    const pdfWidth = 210 // Largura do PDF em mm (A4)
    const pdfHeight = 297 // Altura do PDF em mm (A4)
    const marginTop = 20 // margin superior em milímetros
    const marginLeft = 30 // margin lateral
    const marginRight = 30 // margin lateral
    const marginBottom = 30 // margin inferior em milímetros

    const contentWidth = pdfWidth - marginLeft - marginRight
    // const contentHeight = pdfHeight - marginTop - marginBottom

    let currentHeight = marginTop

    for (const canvas of materialPdf) {
      const imgData = canvas.toDataURL('image/png')

      // Convert canvas height to PDF dimensions
      const canvasHeightInPdf = (canvas.height * contentWidth) / canvas.width

      // Check if canvas fits on the current page
      if (currentHeight + canvasHeightInPdf > pdfHeight - marginBottom) {
        pdf.addPage()
        currentHeight = marginTop
      }

      pdf.addImage(
        imgData,
        'PNG',
        marginLeft,
        currentHeight,
        contentWidth,
        canvasHeightInPdf
      )
      currentHeight += canvasHeightInPdf
    }

    pdf.save(`${props.name}.pdf`)
    setReady(false)
    setLoading(false)
    props.onClose()
  }

  return (
    <div style={Styles.divContainer}>
      <ToastContainer></ToastContainer>
      <div>
        <div ref={headerRef}>
          <div style={Styles.container}>
            <img style={{ width: '180px' }} src={LogoAudi} />
          </div>
          <p style={{ fontSize: '12px', fontWeight: 'bolder', margin: '10px' }}>
            Requisitos
          </p>
          <div style={Styles.header}>
            <div style={Styles.row}>
              <p style={{ margin: '10px 0' }}>Auditoria</p>
            </div>
            <div style={Styles.row}>
              <div style={Styles.grow2R}>
                <p style={Styles.font1}>Nome: </p>
                <p style={Styles.font1}>
                  {props?.audit?.name === null ? '' : props?.audit?.name}
                </p>
              </div>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Indicador: </p>
                <p style={Styles.font3}>{parseInt(props?.identifier) + 1}</p>
              </div>
            </div>
            <div style={Styles.row}>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Concessionária: </p>
                <p style={Styles.font3}> {props?.dealership}</p>
              </div>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Representante: </p>
                <p style={Styles.font3}>
                  {props?.audit.representatives === null
                    ? ''
                    : props?.audit.representatives}
                </p>
              </div>
            </div>
            <div style={Styles.row}>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Marca: </p>
                <p style={Styles.font3}> {props?.brand} </p>
              </div>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Depto: </p>
                <p style={Styles.font3}> {props?.department}</p>
              </div>
              <div style={Styles.grow2R}>
                <p style={Styles.font2}>Auditor Lider: </p>
                <p style={Styles.font3}>
                  {props?.audit.chief_auditor === null
                    ? ''
                    : props?.audit.chief_auditor}
                </p>
              </div>
              <div style={Styles.grow1R}>
                <p style={Styles.font2}>Nota: </p>
                <p style={Styles.font3}>
                  {' '}
                  {isNaN(props?.nota) ? 0 : props?.nota}{' '}
                </p>
              </div>
              <div style={Styles.grow1R}>
                <p style={Styles.font2}>C: </p>
                <p style={Styles.font3}>
                  {isNaN(props?.nota) ? 0 : props?.nota}%
                </p>
              </div>
            </div>
          </div>
        </div>
        <Loading open={loading} />
        <ReturnQuestions state={questionRefs} questions={questions} />
        <ReturnChart state={chartRefs} configs={configs} />
        <div ref={footerRef}>
          <div style={Styles.assinaturas}>
            <div style={Styles.assina}>
              <div style={Styles.linha}></div>
              <p>Auditor Lider</p>
            </div>
            <div style={Styles.assina}>
              <div style={Styles.linha}></div>
              <p>Auditor</p>
            </div>
            <div style={Styles.assina}>
              <div style={Styles.linha}></div>
              <p>Gerente da Concessionária</p>
            </div>
            <div style={Styles.assina}>
              <div style={Styles.linha}></div>
              <p>Gerente da Área</p>
            </div>
            <div style={Styles.assina}>
              <div style={Styles.linha}></div>
              <p> Auditados</p>
            </div>
          </div>
          <div style={Styles.boxLogo}>
            <img style={Styles.img} src={LogoRisc} />
          </div>
        </div>
      </div>
    </div>
  )
}

generateReport.propTypes = {
  value: PropTypes.array,
  form: PropTypes.any,
  chief_auditor: PropTypes.string,
  representatives: PropTypes.string
}
