import React from 'react'
import Proptypes from 'prop-types'
import { BsCheck } from 'react-icons/bs'
import styles from '../../styles/questionario.module.css'

function QuestionBox(props) {
  const questionNumber = props.number
  const questionAnswered = props.answered
  const questionExcerpt = props.excerpt
  const questionWeight = props.weight
  function BoxBody() {
    return (
      <>
        <div className={styles.question_tittle}>
          <div className="question-number">{questionNumber}</div>
          {questionAnswered && (
            <div className={styles.question_answered}>
              <BsCheck></BsCheck>
            </div>
          )}
        </div>
        <div className="question-excerpt">
          <p>{questionExcerpt}</p>
        </div>
        <div className="question-footer">
          <label>Peso:</label>
          <p>{questionWeight}</p>
        </div>
        {props.answer !== undefined && (
          <div className="question-footer">
            <label>Nota:</label>
            <p>{props.answer}</p>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {questionAnswered && (
        <div className="question-item box-light">
          <BoxBody />
        </div>
      )}
      {!questionAnswered && (
        <div className="question-item box-dark">
          <BoxBody />
        </div>
      )}
    </>
  )
}
QuestionBox.propTypes = {
  number: Proptypes.string,
  answered: Proptypes.bool,
  excerpt: Proptypes.string,
  weight: Proptypes.number,
  answer: Proptypes.any
}

export default QuestionBox
