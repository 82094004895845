import React, { useEffect, useState, useRef } from 'react'
import Loading from '../../../../components/alertLoading'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'
import form from '../../../../styles/Formulario.module.css'
import { Form } from '@unform/web'
import History from '../../../../services/history'
import api from '../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { FiEdit2, FiTrash2, FiFileText } from 'react-icons/fi'
import List from '../../../../components/List'
import Select from '../../../../components/form/Select'
import Swal from 'sweetalert2'
import '../../../../relatorio.css'
import '../../../../globals.css'
import LogoRisc from '../../../../assets/risc.svg'
import LogoAudi from '../../../../assets/Imagem1.png'
import { sortIdentifiers, getSectorName } from '../../../../services/auditoria'

function viewAudit() {
  const [loading, setLoading] = useState(false)

  const formRef = useRef(null)
  const [brands, setBrands] = useState([])
  const [stores, setStores] = useState([])
  const [sectors, setSectors] = useState([])
  const atualDate = new Date()
  const atualyear = atualDate.getFullYear()
  const [editingForm, setEditingForm] = useState(false)
  const [displayForm, setDispayForm] = useState(false)
  const [weights, setWeights] = useState([])

  const [sector, setSector] = useState('')
  const [questions, setQuestions] = useState([])

  async function init() {
    try {
      setLoading(true)
      const response = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(response.data)
    } catch (err) {
      setLoading(false)
    }
  }

  async function updateStores(b) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/?brand=${b}`)
      setLoading(false)
      setStores(response?.data)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }

  async function updateSectors(s) {
    try {
      setLoading(true)
      const response = await api.get(`/v1/stores/sector?store=${s}`)
      setLoading(false)
      setSectors(response?.data)
    } catch (err) {
      setLoading(false)
      toast.error(err?.response)
    }
  }
  useEffect(() => {
    init()
  }, [])

  function changeWeight(value, position) {
    const newList = []
    weights.forEach((valor, index) => {
      if (index === position) {
        newList[position] = value
      } else {
        newList[index] = valor
      }
    })
    setWeights(newList)
  }

  async function getPerguntas(id, type = 'view') {
    try {
      setLoading(true)
      const response = await api.get(`/v1/forms/form-question/${id}`)
      const data = response?.data
      const tempQuestions = []
      const tempWeghts = []
      data.forEach(element => {
        tempQuestions.push(element)
        tempWeghts.push(element.value)
      })

      setQuestions(tempQuestions)
      setWeights(tempWeghts)
      setLoading(false)

      if (type !== 'edit') {
        History.push(`${process.env.PUBLIC_URL}/painel/form/imprimir`, {
          sector: sector,
          questions: tempQuestions
        })
      }
    } catch (error) {
      setLoading(false)
    }
  }

  async function editQuestionEnunciation(id, utterance) {
    try {
      const parameters = {
        enunciation: utterance
      }
      setLoading(true)
      await api.put(`/v1/forms/questions/${id}`, parameters)
      setLoading(false)
      toast.success('O enunciado da pergunta foi atualizado com sucesso!')
      // setEditingForm(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const filterSector = sec => {
    const s = sectors.find(e => e.id === sec).name
    setSector(s)
  }

  function DisplayForm() {
    return (
      <div className={form.modal}>
        <div
          className={form.bg_modal}
          onClick={() => {
            setDispayForm(false)
          }}
        ></div>
        <div className={styles.imprimir}>
          <div className={styles.ContainerImprimir}>
            <div className="area_logos">
              <div className="logoAuditoria">
                <img src={LogoAudi} />
              </div>
            </div>
            <div className="area_logos">
              <h2>
                {getSectorName(
                  formRef.current.getFieldValue('sector'),
                  sectors
                )}
              </h2>
            </div>
            {questions.map((item, index) => {
              return (
                <div className="resumo" key={index}>
                  <div className="row-quest">
                    <div className="part1">
                      <p className="font-2"> Indicador {index + 1} </p>
                      <p className="font-1">
                        {item.enunciation
                          ? item.enunciation
                          : 'Indicador não foi Cadastrado'}
                      </p>
                    </div>
                    <div className="part2">
                      <p className="font-2">peso</p>
                      <p className="font-1">{item.value}</p>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="area_logos">
              <div className="logoRisc">
                <img src={LogoRisc} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function TableCompanies() {
    return forms.map((item, index) => {
      const created_date = new Date(item.created_at)
      const month = created_date.getMonth()
      const day = String(created_date.getDate()).padStart(2, '0')
      const year = created_date.getFullYear()
      const output = `${day} / ${month < 9 ? '0' : ''}${month + 1} / ${year}`

      return (
        <tr key={index}>
          <td className="grow1">{index + 1}</td>
          <td className="grow2">{output}</td>
          <td className="grow1 td-opc">
            <div
              className="btn-edit"
              onClick={() => {
                getPerguntas(item.id, 'edit')
                //
                setEditingForm(true)
              }}
            >
              <FiEdit2 />
              <p>Editar</p>
            </div>
            <div
              className="btn-delete"
              onClick={() => {
                Swal.fire({
                  title: 'Deseja realmente excluir este formulário?',
                  text: 'Todos os dados ligados a este formulário serão perdidos e a operação não poderá ser desfeita.',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim, Excluir'
                }).then(result => {
                  if (result.isConfirmed) {
                    removeForm(item.id)
                  }
                })
              }}
            >
              <FiTrash2 />
              <p>Excluir</p>
            </div>
            <div
              className="btn-edit"
              onClick={() => {
                getPerguntas(item.id)
                // setDispayForm(true)
              }}
            >
              <FiFileText />
              <p>Gerar Reltório</p>
            </div>
          </td>
        </tr>
      )
    })
  }

  const [forms, setForms] = useState([])

  async function listForms(sectorid) {
    try {
      const year = parseInt(formRef.current.getFieldValue('year'))
      setLoading(true)
      const response = await api.get(
        `/v1/forms/${formRef.current.getFieldValue(
          'store'
        )}/${sectorid}?year=${year}`
      )
      setLoading(false)
      console.error(response)
      // const aux = []
      // aux.push(response.data)
      setForms(response.data)
    } catch (error) {
      setLoading(false)
      if (error.response) {
        if (error.response.status === 404) {
          toast.info('Não foram encontrados resultados')
        }
        setForms([])
      }
    }
  }

  async function removeForm(formId) {
    try {
      setLoading(true)
      await api.delete(`/v1/forms/${formId}`)
      setLoading(false)
      Swal.fire('O formulário foi excluído com sucesso!!!', '', 'success')
      setStores([])
      setForms([])
      init()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  useEffect(() => {
    if (questions.identifier !== undefined) {
      sortIdentifiers(questions)
    }
  }, [questions])

  return (
    <div className={styles.containerRow}>
      {editingForm && (
        <div className={form.modal}>
          <div
            className={form.bg_modal}
            onClick={() => {
              setEditingForm(false)
            }}
          ></div>
          <Form className={form.containerCad} onSubmit={listForms}>
            <h1 className={form.titleForm}>
              Edite o Indicador do formulário para a Auditoria
            </h1>
            {questions.map((question, index) => {
              return (
                <label key={index}>
                  <div className={styles.flex_row_bet}>
                    <div className={(styles.flex_column_bet, form.input_w90)}>
                      <span className={form.title_input}>
                        <strong>Indicador {index + 1} </strong>
                      </span>
                      <div>
                        <textarea
                          className={form.campo_input}
                          rows="3"
                          value={question.enunciation}
                          onChange={e => {
                            const newvalue = e.target.value
                            const aux = []
                            questions.forEach(element => {
                              if (element.id === question.id) {
                                aux.push({
                                  ...element,
                                  enunciation: newvalue
                                })
                              } else {
                                aux.push(element)
                              }
                            })
                            setQuestions(aux)
                          }}
                        ></textarea>
                      </div>
                      <div className={(styles.flex_column_bet, form.input_w50)}>
                        <span className={form.title_input}>
                          <strong>Peso</strong>
                        </span>
                        <div className="d-flex1">
                          <input
                            className={form.campo_input}
                            type="range"
                            step=".01"
                            min="0"
                            max="3"
                            disabled
                            value={weights[index]}
                            onChange={e => {
                              changeWeight(e.target.value, index)
                            }}
                          />
                          <p>{weights[index]}</p>
                        </div>
                      </div>
                    </div>
                    <div className={form.input_w10}>
                      <span>a</span>
                      <div className={styles.btn_bunscar}>
                        <button
                          type="button"
                          className={form.btn}
                          onClick={() => {
                            editQuestionEnunciation(
                              question.id,
                              question.enunciation
                            )
                          }}
                        >
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>
                </label>
              )
            })}

            <div className={form.btn_cadastro_view_audit}>
              <button
                className={form.btn}
                onClick={() => {
                  setEditingForm(false)
                }}
              >
                Fechar
              </button>
            </div>
          </Form>
        </div>
      )}

      {displayForm && DisplayForm()}

      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <Form ref={formRef} className={styles.content}>
          <h1 className={styles.title_main}>Visualizar </h1>
          <div className={form.containerCad}>
            <h1 className={form.titleForm}>Selecione Estabelecimento</h1>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Marca</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="brand"
                    onChange={element => {
                      if (element.target.value !== 'clear') {
                        setStores([])
                        setSectors([])
                        updateStores(element.target.value)
                      } else {
                        setStores([])
                      }
                    }}
                    array={brands}
                    id="marca"
                    label="Selecione..."
                  ></List>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w100)}>
                <span className={form.title_input}>
                  Selecione <strong>Loja</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    name="store"
                    onChange={e => {
                      if (e.target.value !== 'clear') {
                        updateSectors(e.target.value)
                      } else {
                        setSectors([])
                      }
                    }}
                    id="loja"
                    array={stores}
                    label="Selecione"
                  ></List>
                </div>
              </div>
            </div>
            <div className={styles.flex_row_bet}>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>
                  Selecione <strong>Setor</strong>
                </span>
                <div className={form.select}>
                  <List
                    className={form.campo_input}
                    onChange={e => {
                      listForms(e.target.value)
                      filterSector(e.target.value)
                    }}
                    name="sector"
                    id="setor"
                    array={sectors}
                    label={'Selecione...'}
                  ></List>
                </div>
              </div>
              <div className={(styles.flex_column_bet, form.input_w50)}>
                <span className={form.title_input}>Selecione o Ano</span>
                <div className={form.select}>
                  <Select
                    className={form.campo_input}
                    name="year"
                    onChange={() => {
                      listForms(formRef.current.getFieldValue('sector'))
                    }}
                  >
                    <option>{atualyear}</option>
                    <option>{atualyear - 1}</option>
                    <option>{atualyear - 2}</option>
                    <option>{atualyear - 3}</option>
                  </Select>
                </div>
              </div>
            </div>
            <table className="search-table">
              <thead>
                <tr className="title-table">
                  <th className="grow1">Nº</th>
                  <th className="grow2">Data de Criação</th>
                  <th className="grow1">Opções</th>
                </tr>
              </thead>
              <tbody>
                <TableCompanies></TableCompanies>
              </tbody>
            </table>
          </div>
        </Form>
      </div>
      <Loading open={loading} />
    </div>
  )
}

export default viewAudit
