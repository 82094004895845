import React, { useEffect, useState } from 'react'
import History from '../../services/history'
import Loading from '../../components/alertLoading'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import { sortIdentifiers } from '../../services/auditoria'
import api from '../../services/api'
import { capitalizeFirstLetter } from '../../services/functions'
import { toast, ToastContainer } from 'react-toastify'

import PreviewBox from '../../components/preview-box'

import { HiArrowNarrowLeft } from 'react-icons/hi'

import LogoCometa from '../../assets/gp-cometa.svg'
import PropTypes from 'prop-types'

function EndedAudits(props) {
  const [loading, setLoading] = useState(false)

  let result
  useEffect(() => {
    result = props?.location?.state
  }, [])

  const dados = props?.location?.state

  const [questions, setQuestions] = useState([])
  const [sector_name, setSector_name] = useState('')

  const [idAuditoria, setIdAuditoria] = useState('')
  const [codeAuditoria, setCodeAuditoria] = useState('')

  const [questionsanswered, setQuestionsAnswered] = useState([])

  async function init() {
    if (result?.storeId) {
      const store_id = dados.storeId
      const sector_id = dados.sectorId
      setSector_name(dados.sectorName)

      if (localStorage.getItem('auditoria@id_auditoria') === null) {
        localStorage.setItem('store_id', dados.storeId)
        localStorage.setItem('sector_id', dados.sectorId)

        const bodyParameters = { store_id: store_id, sector_id: sector_id }
        setLoading(true)
        const response = await api.post('/v1/audits/', bodyParameters)

        localStorage.setItem('auditoria@code_auditoria', response.data.code)
        localStorage.setItem('auditoria@id_auditoria', response.data.id)
        localStorage.setItem('auditoria@form_id', response.data.form_id)
        setIdAuditoria(response.data.id)
        setCodeAuditoria(response.data.code)

        const resp = await api.get(
          `/v1/forms/form-question/${response.data.form_id}`
        )
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setLoading(false)
        setQuestions(aux)
        getQuestionsAnswered()
      } else {
        setIdAuditoria(localStorage.getItem('auditoria@id_auditoria'))
        setCodeAuditoria(localStorage.getItem('auditoria@code_auditoria'))
        setLoading(true)
        const resp = await api.get(
          `/v1/forms/form-question/${localStorage.getItem('auditoria@form_id')}`
        )
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setLoading(false)
        setQuestions(aux)
        getQuestionsAnswered()
      }
    } else {
      if (localStorage.getItem('auditoria@id_auditoria') !== null) {
        setIdAuditoria(localStorage.getItem('auditoria@id_auditoria'))
        setCodeAuditoria(localStorage.getItem('auditoria@code_auditoria'))
        setLoading(true)
        const resp = await api.get(
          `/v1/forms/form-question/${localStorage.getItem('auditoria@form_id')}`
        )
        if (result?.sectorName) {
          setSector_name(result.sectorName)
        } else if (localStorage.getItem('auditoria@sector_name') !== null) {
          setSector_name(localStorage.getItem('auditoria@sector_name'))
        } else {
          setSector_name('Erro!!')
        }
        const aux = []
        resp.data.forEach((item, index) => {
          aux.push({ ...item, number: index + 1 })
        })
        setLoading(false)
        setQuestions(aux)
        getQuestionsAnswered()
      } else {
        toast.info('Erro interno, retornando para a tela de inicio')
      }
    }
  }

  async function getQuestionsAnswered() {
    try {
      const audit =
        idAuditoria || localStorage.getItem('auditoria@id_auditoria')
      if (audit) {
        const resp = await api.get(`/v1/audits/answer/${audit}`)
        setQuestionsAnswered(resp.data)
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== 404) {
          showErrors(error.response.data.detail)
        }
      }
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const porcentagem = document.getElementById('progressAtual')
    const valor = (questionsanswered.length / questions.length) * 100
    porcentagem.style.width = valor + '%'
  }, [questionsanswered])

  function showErrors(errors) {
    if (typeof errors === 'object') {
      errors?.forEach(err => {
        toast.error(err.msg)
      })
    } else {
      toast.error(errors)
    }
  }
  useEffect(() => {
    endForm()
  }, [questionsanswered, questions])
  const [abstract, setAbstract] = useState([])
  function endForm() {
    // if (questions.length === questionsanswered.length) {
    const copy = []
    questionsanswered.forEach(item => {
      questions.forEach(question => {
        if (question.id === item.question.id) {
          copy.push({ ...question, answer: item.answer })
        }
      })
    })
    sortIdentifiers(copy)
    setAbstract(copy)
  }
  // useEffect(() => {}, [abstract])

  return (
    <div className="container">
      <Navbar />
      <ToastContainer />
      <div className="page-title">
        <a
          onClick={() => {
            History.push(`${process.env.PUBLIC_URL}/auditoria`)
          }}
        >
          <HiArrowNarrowLeft />
        </a>
        <h2>Finalizadas</h2>
      </div>

      <div className="content mg-ledo">
        <div className="aud-group align-center">
          <img src={LogoCometa} className="logoCometa"></img>
        </div>
        <div className="row setor-row">
          <div className="col">
            <div className="row">
              <label className="setor">Setor - </label>
              <p className="setor-nome"> {sector_name}</p>
            </div>
            <div className="row">
              <label className="question-atual">
                {questionsanswered.length}/
              </label>
              <p className="question-total">{questions.length}</p>
            </div>
          </div>
          <div className="col">
            <div className="progressAll">
              <div className="progressAtual" id="progressAtual"></div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <label className="font-bold">Código: </label>
              <p className="font-regular"> {codeAuditoria}/2021</p>
            </div>
            <div className="row">
              <label className="font-bold">Data de Início: </label>
              <p className="font-regular"> 01/04/2021</p>
            </div>
          </div>
        </div>
        <div className="linha"></div>
        <br />
        {abstract.map((item, index) => {
          return (
            <div key={index}>
              <PreviewBox
                number={item.number}
                note={item.answer}
                excerpt={capitalizeFirstLetter(item.enunciation.toLowerCase())}
                weight={questions[index].value}
              ></PreviewBox>
            </div>
          )
        })}
      </div>
      <div className="button-row-center"></div>
      <Footer></Footer>
      <Loading open={loading} />
    </div>
  )
}
EndedAudits.propTypes = {
  location: PropTypes.object,
  state: PropTypes.object
}
export default EndedAudits
