const getNameById = (id, array) => {
  let name = ''
  array.forEach(item => {
    if (item.id === id) {
      name = item.name
    }
  })

  return name
}

export { getNameById }
