import React from 'react'

// import { useRouter } from 'next/router'
import History from '../../../../services/history'
import NavbarPainel from '../../../../components/navbarPainel'
import Sidebar from '../../../../components/sidebar'
import styles from '../../../../styles/painel.module.css'

import { ToastContainer } from 'react-toastify'
import { RiHomeGearLine, RiBuilding2Line } from 'react-icons/ri'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { AiFillShop } from 'react-icons/ai'

export default function VisualizarEstab() {
  return (
    <div className={styles.containerRow}>
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Visualizar</h1>
          <div className={styles.flex_row_bet}>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiBuilding2Line></RiBuilding2Line>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Marcas</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/marcas`
                      )
                    }}
                  >
                    Visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <AiFillShop></AiFillShop>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Loja</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/lojas`
                      )
                    }}
                  >
                    Visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
            <div className={(styles.flex_column_bet, styles.opc_cadastro)}>
              <div className={(styles.flex_column_bet, styles.item_cadastro)}>
                <div className={styles.img_cadastro}>
                  <RiHomeGearLine></RiHomeGearLine>
                </div>
                <div className={styles.name_cadastro}>
                  <p>Setor</p>
                  <div className={styles.barra}>_ _ _</div>
                </div>
                <div className={styles.btn_cadastro}>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      History.push(
                        `${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/setores`
                      )
                    }}
                  >
                    Visualizar
                    <HiArrowNarrowRight></HiArrowNarrowRight>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
