import React, { useState, useEffect, useContext } from 'react'
import NavbarPainel from '../../../../../components/navbarPainel'
import Sidebar from '../../../../../components/sidebar'
import styles from '../../../../../styles/painel.module.css'
import api from '../../../../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { ModalsContext } from '../../../../../providers/modals'
import Loading from '../../../../../components/alertLoading'
import EditBrand from '../../../../../components/EditarForms/Marca'
import form from '../../../../../styles/Formulario.module.css'
import Swal from 'sweetalert2'
export default function tabelaMarca() {
  const [loading, setLoading] = useState(false)

  const { editingMarca, setEditingMarca } = useContext(ModalsContext)
  const [brands, setBrands] = useState([])
  async function init() {
    try {
      setLoading(true)
      const resp = await api.get('/v1/stores/brand')
      setLoading(false)
      setBrands(resp.data)
    } catch (err) {
      setLoading(false)
    }
  }
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    init()
  }, [editingMarca])
  const [brandId, setBrandId] = useState([])
  const [brandName, setBrandName] = useState([])
  function EditaMarca() {
    return (
      <>
        {editingMarca && (
          <div className={form.modal}>
            <div
              className={form.bg_modal}
              onClick={() => {
                setEditingMarca(false)
              }}
            ></div>
            <EditBrand brandId={brandId} brandName={brandName}></EditBrand>
          </div>
        )}
      </>
    )
  }

  function TableCompanies() {
    return brands.map((item, index) => {
      const n = index + 1
      return (
        <tr key={index}>
          <td className="grow1">{n}</td>
          <td className="grow2">{item.name}</td>
          <td className="grow1 td-opc">
            <div
              className="btn-edit"
              onClick={() => {
                setEditingMarca(true)
                setBrandId(item.id)
                setBrandName(item.name)
              }}
            >
              <FiEdit2 />
              <p>Editar</p>
            </div>
            <div
              className="btn-delete"
              onClick={() => {
                Swal.fire({
                  title: 'Deseja realmente excluir esta marca?',
                  text: 'Todos os dados ligados a esta marca serão perdidos e a operação não poderá ser desfeita.',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim, Excluir'
                }).then(result => {
                  if (result.isConfirmed) {
                    removeBrand(item.id)
                  }
                })
              }}
            >
              <FiTrash2 />
              <p>Excluir</p>
            </div>
          </td>
        </tr>
      )
    })
  }

  async function removeBrand(brandId) {
    try {
      setLoading(true)
      const response = await api.delete(`/v1/stores/brand/${brandId}`)
      console.info(response)
      setLoading(false)
      Swal.fire('A marca foi excluída com sucesso!!!', '', 'success')
      init()
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined) {
        const result = error.response
        const errs = result.data.detail

        errs.forEach(err => {
          toast.error(err.msg)
        })
      }
    }
  }

  return (
    <div className={styles.containerRow}>
      <EditaMarca />
      <Sidebar opc={2}></Sidebar>
      <div className={styles.main}>
        <NavbarPainel></NavbarPainel>
        <ToastContainer></ToastContainer>
        <div className={styles.content}>
          <h1 className={styles.title_main}>Visualizar</h1>
          <div className={styles.containerTable}>
            <div className={styles.containerGrafico}>
              <h1 className="title-info-table">Visualizar Todas as Marcas</h1>
              <div className="search-body">
                <table className="search-table">
                  <thead>
                    <tr className="title-table">
                      <th className="grow1">Nº</th>
                      <th className="grow2">Marca</th>
                      <th className="grow1">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableCompanies></TableCompanies>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading open={loading} />
    </div>
  )
}
