import React from 'react'
import PropTypes from 'prop-types'
import Select from '../../components/form/Select'

function List(props) {
  return (
    <Select
      disabled={props.disabled}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      className={props.className}
    >
      <option value="clear">{props.label}</option>
      {props.array.map((brand, index) => {
        return (
          <option key={index} value={brand.id}>
            {brand.name}
          </option>
        )
      })}
    </Select>
  )
}

List.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  array: PropTypes.array,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}
export default List
