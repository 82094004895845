import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'

const Alert = props => {
  return (
    <Dialog
      open={props.open}
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '60px',
          width: '60px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress sx={{ color: '#c73333c0' }} />
      </Box>
    </Dialog>
  )
}

Alert.propTypes = {
  open: PropTypes.bool.isRequired
}

export default Alert
