import React, { useState } from 'react'
import { array, bool, func, string } from 'prop-types'
import Loading from '../alertLoading'
import BasicTextArea from '../form/BasicTextArea'
import { Form } from '@unform/web'
import { MdSend, MdKeyboardArrowDown } from 'react-icons/md'
import { commentStyle } from './styles'
import {
  commentBlock,
  containerCommentStyle,
  styleBlock,
  textFieldStyle,
  buttonStyle,
  openAnswerButton,
  commentOptions,
  commentPanel
} from './painelComment.module.css'
import { formatDate } from '../../services/auditoria'
import { toast } from 'react-toastify'
import api from '../../services/api'

const PainelComment = ({
  open,
  close,
  comments = [],
  handleComment = () => {},
  onSetComment = () => {},
  addComment = false,
  removeComment = false
}) => {
  const sendComment = data => {
    if (data.comment) {
      const body = {
        message: data.comment
        // author: localStorage.getItem('auditoria@userName'),
        // date: formatDate(getAtualDate().toDateString())
      }

      handleComment(body)
    }
  }
  const user = localStorage.getItem('auditoria@userName')
  const [loading, setLoading] = useState()

  const AddComment = () => {
    return (
      <div>
        <Form onSubmit={sendComment} className={styleBlock}>
          <BasicTextArea className={textFieldStyle} name="comment" />
          <button type="submit" className={buttonStyle}>
            <MdSend style={{ fill: '#ba0000', width: 35, height: 35 }} />
          </button>
        </Form>
      </div>
    )
  }

  const deleteComment = async id => {
    try {
      setLoading(true)
      await api.delete(`/v1/audits/comment/${id}`)
      setLoading(false)
      onSetComment()
    } catch (e) {
      setLoading(false)
      toast.error('Não foi possivel deletar o comentário!')
    }
  }

  const Comment = ({ text, author, date, id }) => {
    const [options, setOptions] = useState(false)
    const data = formatDate(date) || ''
    return (
      <div
        className={commentBlock}
        style={{ backgroundColor: author === user ? '#c733331c' : 'white' }}
      >
        {removeComment === true && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <button
                className={openAnswerButton}
                onClick={() => setOptions(!options)}
              >
                <MdKeyboardArrowDown
                  style={{
                    width: 24,
                    height: 24,
                    transform: options ? 'rotate(180deg)' : 'rotate(0)'
                  }}
                />
              </button>
              {options && (
                <ul className={commentOptions}>
                  <li
                    style={{
                      fontSize: '0.8rem',
                      borderRadius: '0.4rem',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setOptions(false)
                      deleteComment(id)
                    }}
                  >
                    Remover
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
        <Loading open={loading} />

        <p style={{ color: '#ab1414', fontSize: '1rem' }}>{text}</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '0.7rem'
          }}
        >
          <p style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>
            de: {author}
          </p>
          <p style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>{data}</p>
        </div>
      </div>
    )
  }

  Comment.propTypes = {
    text: string,
    author: string,
    date: string,
    id: string
  }

  return (
    <div className={containerCommentStyle}>
      <div className={commentPanel} style={commentStyle(open)}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2
            style={{
              textAlign: 'center',
              color: 'gray',
              fontSize: '1.3rem',
              marginBottom: '1rem'
            }}
          >
            Comentários
          </h2>
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.3rem',
              lineHeight: '1.3rem',
              color: '#ba0000',
              width: '2rem',
              height: '2rem',
              borderRadius: '1.5rem',
              boxShadow: '1px 1px 3px gray'
            }}
            onClick={close}
          >
            x
          </div>
        </div>

        <div
          style={{
            height: addComment ? '76vh' : '81vh',
            overflowY: 'scroll',
            padding: '0.15rem'
          }}
        >
          {comments.map(({ message, owner_name, updated_at, id }, index) => {
            return (
              <Comment
                id={id}
                key={index}
                text={message}
                author={owner_name}
                date={updated_at}
              />
            )
          })}
        </div>
        {addComment === true && <AddComment />}
      </div>
    </div>
  )
}

PainelComment.propTypes = {
  open: bool,
  close: func,
  comments: array,
  handleComment: func,
  onSetComment: func,
  addComment: bool,
  removeComment: bool
}

export default PainelComment
