/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import { MdOutlineKeyboardBackspace, MdOutlinePrint } from 'react-icons/md'

import styles from '../../../styles/painel.module.css'
import History from '../../../services/history'
import GenerateReport from '../../../components/gerarRelatorio'

const Imprimir = () => {
  const [props, setProps] = useState({})
  const [exist, setExist] = useState(false)

  useEffect(() => {
    const localProps = History.location.state
    setProps(localProps)
    setExist(true)
  }, [])

  return (
    <div>
      <div className="hide-on-print">
        <button
          type="button"
          className={styles.btn_voltar}
          onClick={() => {
            History.goBack()
          }}
        >
          <MdOutlineKeyboardBackspace />
          <span>Voltar</span>
        </button>

        <button
          type="button"
          className={styles.btn_voltar}
          style={{
            position: 'absolute',
            right: '5%',
            top: '30vh',
            background: 'none',
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            color: '#ba0000',
            cursor: 'pointer'
          }}
          onClick={() => {
            window.print()
          }}
        >
          <MdOutlinePrint />
          <span>Imprimir</span>
        </button>
      </div>
      {exist === true && (
        <GenerateReport
          brand={props.brand}
          dealership={props.dealership}
          department={props.department}
          className={props.className}
          value={props.value}
          form={props.form}
          configs={props.configs}
          nota={props.nota}
          identifier={props.identifier}
          audit={props.audit}
          chief_auditor={props.chief_auditor}
          representatives={props.representatives}
        />
      )}
    </div>
  )
}

export default Imprimir
