import { Switch, Route } from 'react-router-dom'
import Auditoria from '../pages/auditoria'
import Questionario from '../pages/questionario'
import Painel from '../pages/painel'
import PainelCad from '../pages/painel/painelCadastro'
import CadastroEstab from '../pages/painel/painelCadastro/cadastroEstab'
import CadastroForm from '../pages/painel/painelCadastro/cadastroForm'
import PainelEdit from '../pages/painel/painelEditar'
import EditEstab from '../pages/painel/painelEditar/editarEstab'
import EditForm from '../pages/painel/painelEditar/editarForm'
import PainelVisualizar from '../pages/painel/painelVisualizar'
import VisualizarEstab from '../pages/painel/painelVisualizar/visualizarEstab'
import TabelaLoja from '../pages/painel/painelVisualizar/visualizarEstab/tabelaLoja'
import TabelaMarca from '../pages/painel/painelVisualizar/visualizarEstab/tabelaMarca'
import TabelaSetor from '../pages/painel/painelVisualizar/visualizarEstab/tabelaSetor'
import VisualizarForm from '../pages/painel/painelVisualizar/visualizarForm'
import PainelDash from '../pages/painel/painelDashboard'
// import LoginPanel from '../pages/loginPanel'
import React from 'react'
import EndedAudits from '../pages/auditoriasfinalizadas'
import viewAudit from '../pages/painel/painelVisualizar/visualizarAuditoria'
import viewAuditV2 from '../pages/painel/painelVisualizar/visualizarAuditoria2'
import PainelReport from '../pages/painel/painelRelatorio'
import PainelReport2 from '../pages/painel/painelRelatorio/versao2'
import { PainelLoading, Loading } from '../pages/Loading'
import { Redirect, PainelRedirect } from '../pages/Redirect/Index'
import pendingAudits from '../pages/auditoriaspendentes'

import Imprimir from '../pages/painel/painelRelatorio/imprimir'
import ImprimirForm from '../pages/painel/painelVisualizar/visualizarForm/imprimir'

function Routes() {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/`}
        component={Redirect}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/loading`}
        component={Loading}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/auditorias-pendentes`}
        component={pendingAudits}
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel-loading`}
        component={PainelLoading}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/login-painel`}
        component={PainelRedirect}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/auditoriasfinalizadas`}
        component={EndedAudits}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/auditoria`}
        component={Auditoria}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/questionario`}
        component={Questionario}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel`}
        component={Painel}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/cadastro`}
        component={PainelCad}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/cadastro/estabelecimento`}
        component={CadastroEstab}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/cadastro/formulario`}
        component={CadastroForm}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/editar`}
        component={PainelEdit}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/editar/estabelecimento`}
        component={EditEstab}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/editar/formulario`}
        component={EditForm}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar`}
        component={PainelVisualizar}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento`}
        component={VisualizarEstab}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/auditoria`}
        component={viewAudit}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/auditoria/v2`}
        component={viewAuditV2}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/lojas`}
        component={TabelaLoja}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/marcas`}
        component={TabelaMarca}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/estabelecimento/setores`}
        component={TabelaSetor}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/visualizar/formulario`}
        component={VisualizarForm}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/dashboard`}
        component={PainelDash}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/relatorio`}
        component={PainelReport}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/relatorio/v2`}
        component={PainelReport2}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/imprimir`}
        component={Imprimir}
        exact
      ></Route>
      <Route
        path={`${process.env.PUBLIC_URL}/painel/form/imprimir`}
        component={ImprimirForm}
        exact
      ></Route>
    </Switch>
  )
}

export default Routes
