import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
export const AuthContext = React.createContext({})

export const AuthProvider = props => {
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (username !== '') {
      localStorage.setItem('auditoria@userName', username)
    } else {
      setTimeout(() => {
        const user = localStorage.getItem('auditoria@userName')

        if (user) {
          setUsername(user)
        } else {
          toast.error('Falha ao obter nome do usuário')
        }
      }, 6000)
    }
  }, [username])

  return (
    <AuthContext.Provider value={{ username, setUsername }}>
      {props.children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}
