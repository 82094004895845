/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import { MdOutlineKeyboardBackspace, MdOutlinePrint } from 'react-icons/md'

import LogoRisc from '../../../../assets/risc.svg'
import LogoAudi from '../../../../assets/Imagem1.png'
import styles from '../../../../styles/painel.module.css'
import History from '../../../../services/history'

const Imprimir = () => {
  const [props, setProps] = useState({})
  const [exist, setExist] = useState(false)

  useEffect(() => {
    const localProps = History.location.state
    setProps(localProps)
    setExist(true)
  }, [])

  const divContainer = {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '800px',
    height: 'auto',
    margin: '0px auto',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 3
  }

  const container = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const boxLogo = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }

  const img = {
    width: '5.5vw',
    height: '5.5vw'
  }

  function DisplayForm() {
    return (
      <div className={styles.imprimir} style={divContainer}>
        <div className={styles.ContainerImprimir} style={container}>
          <img style={{ width: '180px' }} src={LogoAudi} />
          <div className="area_logos">
            <h2>{props.sector}</h2>
          </div>
          {props.questions.map((item, index) => {
            return (
              <div className="resumo" key={index}>
                <div className="row-quest">
                  <div className="part1">
                    <p className="font-2"> Indicador {index + 1} </p>
                    <p className="font-1">
                      {item.enunciation
                        ? item.enunciation
                        : 'Indicador não foi Cadastrado'}
                    </p>
                  </div>
                  <div className="part2">
                    <p className="font-2">peso</p>
                    <p className="font-1">{item.value}</p>
                  </div>
                </div>
              </div>
            )
          })}
          <div style={boxLogo}>
            <img style={img} src={LogoRisc} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="hide-on-print">
        <button
          type="button"
          className={styles.btn_voltar}
          style={{ zIndex: 3 }}
          onClick={() => {
            History.goBack()
          }}
        >
          <MdOutlineKeyboardBackspace />
          <span>Voltar</span>
        </button>

        <button
          type="button"
          className={styles.btn_voltar}
          style={{
            position: 'absolute',
            right: '5%',
            top: '30vh',
            background: 'none',
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            color: '#ba0000',
            cursor: 'pointer'
          }}
          onClick={() => {
            window.print()
          }}
        >
          <MdOutlinePrint />
          <span>Imprimir</span>
        </button>
      </div>
      {exist === true && <DisplayForm props={props} />}
    </div>
  )
}

export default Imprimir
