import React, { useEffect, useState, useRef } from 'react'
import { string, number, func } from 'prop-types'
import styles from '../../styles/questionario.module.css'
import form from '../../styles/Formulario.module.css'
import Loading from '../alertLoading'
import Textarea from '../../components/form/textarea'
import { Form } from '@unform/web'
import api from '../../services/api'
import { toast } from 'react-toastify'

function PainelEditAudit({
  note,
  excerpt,
  weight,
  comment,
  identifier,
  parentCallback,
  questionId,
  auditId
}) {
  const [questionNote, setQuestionNote] = useState(note)
  const questionExcerpt = excerpt
  const questionWeight = weight
  const idAuditoria = auditId
  const idQuestao = questionId
  const [loading, setLoading] = useState(false)
  const [questionComment, setQuestionComment] = useState('')

  const emitEvent = () => {
    parentCallback()
  }

  const formRef = useRef(null)

  useEffect(() => {
    setQuestionComment(comment)
  }, [])

  const formatE = text => {
    let aux = text.replace(/\n/g, '<br/>')
    aux = aux.replace(/ /g, ' ')
    return aux
  }

  const editAnswer = async (data, auditoria, questao) => {
    try {
      setLoading(true)
      const resp = await api.put(`v1/audits/answer/${auditoria}/${questao}`, {
        answer: parseFloat(data.answer),
        note: data.note
      })
      setLoading(false)
      toast.success('A evidência foi alterada com sucesso!')
      return resp
    } catch (e) {
      setLoading(false)
      return {}
    }
  }

  const submit = async data => {
    await editAnswer(
      { answer: questionNote, note: data.evidencia },
      idAuditoria,
      idQuestao
    )
    emitEvent()
  }

  return (
    <div style={{ width: '97%' }} className="question-item box-light">
      <div className={styles.question_tittle}>
        {/* <div className="question-number">Indicador {questionNumber}</div> */}
      </div>
      <div className="question-excerpt">
        <strong>Indicador: {parseInt(identifier) + 1}</strong>
        <p
          dangerouslySetInnerHTML={{
            __html: formatE(
              questionExcerpt || 'O Indicador da Auditoria não foi Informado'
            )
          }}
        ></p>
      </div>
      <Form ref={formRef} onSubmit={submit}>
        <div className="question-excerpt">
          <strong style={{ textAlign: 'left' }}>
            <p> Evidência da Auditoria: </p>
          </strong>
          <Textarea
            onChange={e => {
              setQuestionComment(e.target.value)
            }}
            name="evidencia"
            className={styles.painel_textarea}
            value={questionComment || ''}
          ></Textarea>
        </div>
        <div className={styles.preview_footer}>
          <div>
            <label>Peso: </label>
            <p>{questionWeight}</p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <label>Nota atribuída: </label>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <input
                type="range"
                min="0"
                max="10"
                step="5"
                className="slider"
                value={questionNote}
                onChange={e => setQuestionNote(e.target.value)}
                style={{
                  width: '150px',
                  height: '10px',
                  outline: 'none',
                  opacity: '0.7',
                  transition: 'opacity 0.2s'
                }}
              />
              <span
                style={{
                  textAlign: 'left',
                  color: '#000',
                  fontSize: '16px'
                }}
              >
                {questionNote}
              </span>
            </div>
            {/* <p>{questionNote}</p> */}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button style={buttonStyle} className={form.btn}>
            Salvar
          </button>
          <button
            style={buttonStyle}
            onClick={() => {
              emitEvent()
            }}
            type="button"
            className={form.btn}
          >
            Cancelar
          </button>
        </div>
        <Loading open={loading} />
      </Form>
    </div>
  )
}
const buttonStyle = {
  width: '45%',
  margin: '0.5rem auto'
}
PainelEditAudit.propTypes = {
  number: number,
  note: number,
  excerpt: string,
  weight: number,
  comment: string,
  identifier: number,
  parentCallback: func,
  questionId: string,
  auditId: string
}
export default PainelEditAudit
